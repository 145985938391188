import { ResponseErrorInterceptor } from '../types/'

/**
 * Error interceptor: Refresh access token when expired
 * @param error
 */
export const unauthorizedInterceptor: ResponseErrorInterceptor = options => (error) => {
  const response = error.response

  if (response && response.status === 401) {
    return options.unauthorizedCallback()
  } else {
    return Promise.reject(error)
  }
}
