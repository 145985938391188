





























import { defineComponent, ref } from '@vue/composition-api'
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'
import Cleave from 'vue-cleave-component'

export default defineComponent({
  name: 'FormulateMask',
  mixins: [FormulateInputMixin],
  components: {
    Cleave,
  },
  setup (props, ctx) {
    const cleaveInput = ref(null)
    const localModel = ref()

    if (ctx.parent?.context.attributes.maskOptions.numeral) {
      let value
      if (['REAL', 'AMOUNT'].includes(ctx.parent?.context.attributes.col.type)) {
        value = parseFloat(ctx.parent?.context.model).toFixed(2)
      } else {
        value = ctx.parent?.context.model.toString()
      }

      localModel.value = value.replace('.', ',')
    } else {
      localModel.value = ctx.parent?.context.model
    }
    const onInput = (newValue: string) => {
      if (!ctx.parent) return

      if (ctx.parent?.context.attributes.maskOptions.numeral) {
        ctx.parent.context.model = newValue ? Number(newValue.replace(',', '.')) : null
      } else {
        ctx.parent.context.model = newValue
      }
    }

    return {
      cleaveInput,
      localModel,
      onInput,
    }
  },
})
