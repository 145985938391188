import { Filestore } from '@/plugins/abbd-api/sdk/endpoints/v1/wexaus/projects/_projectId/filestore'

export interface FilesState {
  s3Credentials: Filestore | undefined
}

export const fileState = (): FilesState => ({
  s3Credentials: undefined,
})

export default fileState
