import Vue from 'vue'
import VueAnalytics from 'vue-analytics'

export default async (ctx, inject) => {
  const runtimeConfig = ctx.$config && ctx.$config.googleAnalytics || {}
  const moduleOptions = {"dev":true,"debug":{"sendHitTask":true},"id":function() {
    const hostname = document.location.hostname;

    if (hostname.includes('test.exaus.runview.fr')) {
      return 'UA-146373508-1'; // TEST
    } else if (hostname.includes('exaus.runview.fr')) {
      return 'UA-146275241-1'; // PROD
    } else {
      return 'UA-145838522-1';
    }
  },"commands":{"navbar":function(action = '', label = null, value = null) {
      this.$ga.event('navbar', action, label, value);
    },"sidebar":function(action = '', label = null, value = null) {
      this.$ga.event('sidebar', action, label, value);
    },"fileBrowser":function(action = '', label = 'unknown', value = null) {
      this.$ga.event('file-browser', action, label, value);
    }}}
  const options = {...moduleOptions, ...runtimeConfig}

  if (typeof options.asyncID === 'function') {
    options.id = await options.asyncID(ctx)
  }

  Vue.use(VueAnalytics, {...{ router: ctx.app.router }, ...options})

  ctx.$ga = Vue.$ga
  inject('ga', Vue.$ga)
}
