import Vue from 'vue'
import { Context } from '@nuxt/types'
import abbdSDK from './sdk'

export default ({ store, app, redirect }: Context, inject: any) => {
  Vue.use((_Vue) => {
    const sdk = abbdSDK({
      getAuthToken (config) {
        if (config.url?.includes('auth/refresh')) {
          return store.getters['user/user'].refreshToken
        } else if (store.getters['user/user'].accessToken) {
          return store.getters['user/user'].accessToken
        } else {
          return ''
        }
      },
      updateAuthCallback: user => store.dispatch('user/updateAuth', user),
      unauthorizedCallback: () => store.dispatch('user/logout')
        .then(() => {
          redirect(app.localePath({ name: 'login' }))
        }),
      errorResponseCallback: (err) => {
      },
    })

    _Vue.$abbd = sdk
    _Vue.prototype.$abbd = sdk
    inject('abbd', sdk)
  })
}
