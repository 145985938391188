import { getterTree } from 'typed-vuex'
import state from './state'

const getters = getterTree(state, {
  projects: state => [...state.projects]
    .sort((a, b) => {
      if (a.label < b.label) {
        return 1
      } else if (a.label > b.label) {
        return -1
      } else {
        return 0
      }
    }),
  selectedProject: state => state.selectedProject,
})

export default getters
