import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'

export interface UriParams {
  projectId: string
  columnName: string
}
export interface MultiFilesData {
  values: { [key: string]: any }[]
}

type Args = [
  EndpointsOptions<UriParams>?
]

type PutArgs = [
  FormData,
  Args[0]?
]

export default (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>(({ projectId, columnName }) => {
    return `v1/wexaus/projects/${projectId}/freeanomaly/data/files/${columnName}`
  })

  return {
    put: (...args: PutArgs) => endpoint.put<MultiFilesData>(...args),
  }
}
