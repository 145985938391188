

export default () => {
    if (process.server) {
        return;
    }

    const detect = () => 'entries' in window.Object;

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "core-js/es/object/entries"
        ], function (require) {
            const polyfill = require("core-js/es/object/entries");

            resolve();
        });
    });
}