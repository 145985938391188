import { Context, Middleware } from '@nuxt/types'

const requireAuth: Middleware = function ({ store, error }: Context) {
  if (!store.getters['user/isAdmin']) {
    return error({
      statusCode: 401,
      message: 'You must be an admin to see this page.',
    })
  }
}

export default requireAuth
