import { RequestInterceptor } from '../types/'

/**
 * Success interceptor: Add Bearer token on each query
 * @param config
 */
export const authInterceptor: RequestInterceptor = ({ getAuthToken }, axiosInstance, endpoints) => (config) => {
  const token = getAuthToken(config, endpoints)

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}
