import { Context, Middleware } from '@nuxt/types'

const requireDuplicateSuppliers: Middleware = ({ store, redirect, app }: Context) => {
  const selectedProject = store.getters['projects/selectedProject']

  if (!selectedProject || !selectedProject.optionalFeatures.includes('supplier_duplicates_dashboard')) {
    return redirect(app.localePath({ name: 'transfert-envoyer-des-fichiers' }))
  }
}

export default requireDuplicateSuppliers
