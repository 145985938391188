import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'
import { ColumnMeta } from '@/plugins/abbd-api/sdk/types/api/ColumnMeta'
import { SubLevelKeyMeta } from '@/plugins/abbd-api/sdk/types/api/SubLevelKeyMeta'

export namespace DataTableApiEndpoint {
  export interface DataTableViewMeta {
    columns: ColumnMeta[]
    keyColumns: string[]
    label: string
    name: string
    subLevelKey: SubLevelKeyMeta[]
    timestamp: number
  }

  export interface DataTableMetadata {
    anomalyColumns: string[]
    anomalyIdColumn: string
    entryColumns: string[]
    linkMovementColumn: string
    validatedColumn: string
    validationAmountColumn: string
    validationColumn: string
    customerComment: string
    validatedBy: string
    validatedDate: string
    viewMetaData: DataTableViewMeta
    viewName: string
  }

  export interface UriParams {
    projectId: string
    tableName: string
  }

  export type Args = [
    EndpointsOptions<UriParams>?
  ]
}

export const dataTableMetadata = (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<DataTableApiEndpoint.UriParams>(({ projectId, tableName }) => `v1/wexaus/projects/${projectId}/${tableName}/metadata`)

  return (...args: DataTableApiEndpoint.Args) => endpoint.get<DataTableApiEndpoint.DataTableMetadata>(...args)
}
