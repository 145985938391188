import { randomBytes } from 'crypto'
import { ColDef, ColGroupDef } from '@ag-grid-enterprise/all-modules'
// import { ValidationClient } from '@/components/common/ag-grid/cell-renderers/ValidationClient'
import { ComponentInstance } from '@vue/composition-api'
import CellEditorSelect from '@/components/common/ag-grid/cell-editors/Select.vue'
import { getTextColorFromBgColor } from '@/helpers/getTextColorFromBgColor'
import { GroupHeaderComp } from '@/components/pages/resultats/common/GroupHeaderComp'
import { MovementsLink } from '@/components/common/ag-grid/cell-renderers/MovementsLink'
import { ValideIcon } from '@/components/common/ag-grid/cell-renderers/ValideIcon'
import { ascNumberFilterComparator } from '@/components/common/ag-grid/column-types'
import { DashboardMetadata } from '@/plugins/abbd-api/sdk/endpoints/v1/wexaus/projects/_projectId/dashboard/metadata'
import { getCellClassFromCol } from '~/helpers/getCellClassFromCol'

/**
 * Convert columns from `dashboardMeta` ABBD API Endpoint to ag-grid ColDef format.
 */
export const convertApiColsToColDefs = (headerName: string, apiDashboardMeta: DashboardMetadata): ColDef[] => {
  return apiDashboardMeta.viewMetaData.columns
    .filter(col => headerName === (col.groupLabel && col.groupLabel !== null ? col.groupLabel : col.group))
    .filter(col => !col.hidden)
    .map((col) => {
      const colDef: ColDef = {
        field: col.name,
        colId: col.name,
        headerTooltip: col.label,
        headerName: col.label,
        type: col.type,
        hide: !col.displayByDefault,
        cellClass: getCellClassFromCol(col),
        cellStyle: (params) => {
          if (!col.color || params.node.rowPinned === 'bottom') return {}

          return {
            background: col.color,
            color: getTextColorFromBgColor(col.color, 'white', 'black'),
          }
        },
        editable: (params) => {
          let cantEdit = col.editable

          const isEntry = params.data.path.length > 1
          const isAnomalyCol = (params.context.app as ComponentInstance).$accessor.dashboard.meta?.anomalyColumns.includes(params.colDef.colId || '')
          const isLocked = params.colDef.field !== 'Row Locked' && params.data['Row Locked']
          const isFooterCell = params.node.rowPinned === 'bottom'

          if ((isEntry && isAnomalyCol) || isLocked || isFooterCell) {
            cantEdit = false
          }

          return cantEdit
        },
        headerClass: col.editable ? 'editable-header-icon' : '',
        toolPanelClass: col.editable ? 'toolpanel-col-editable-icon' : '',
      }

      if (col.frozen) {
        colDef.pinned = 'left'
      }

      if (col.name === apiDashboardMeta.validatedColumn) {
        colDef.cellRenderer = ValideIcon
      }

      if (col.name === apiDashboardMeta.linkMovementColumn) {
        colDef.cellRenderer = MovementsLink
        colDef.cellRendererParams = {
          apiDashboardMeta,
        }
        colDef.width = 100
        colDef.filterParams = {
          comparator: ascNumberFilterComparator,
        }
      }

      if (col.isListOfChoices) {
        colDef.cellEditorFramework = CellEditorSelect
        colDef.cellEditorParams = {
          isAsyncOptions: true,
        }
        colDef.width = 150
      }
      return colDef
    })
}

/**
 * Get ColGroupDefs from a list of ColumnDesc
 * @param apiDashboardMeta
 */
export const getColGroupDefs = (apiDashboardMeta: DashboardMetadata): ColGroupDef[] => {
  // Get group header names and dedup
  const groupHeaderNames = apiDashboardMeta.viewMetaData.columns
    .map(col => (col.groupLabel && col.groupLabel !== null ? col.groupLabel : col.group))
    .reduce((groupNameList: string[], groupName) => {
      if (!groupNameList.includes(groupName)) {
        groupNameList.push(groupName)
      }
      return groupNameList
    }, [])
  // Gen groups from group header names
  const groupDef: ColGroupDef[] = groupHeaderNames
    .map(headerName => ({
      headerName,
      // headerGroupComponentFramework: GroupHeader,
      headerGroupComponent: GroupHeaderComp,
      children: convertApiColsToColDefs(headerName, apiDashboardMeta),
    }))

  return groupDef
}

export class Rows {
  entries: any[] = []

  constructor (
    entries: any[],
    private readonly dashboardMetadata: DashboardMetadata,
  ) {
    entries.forEach((entry) => {
      this.entries.push({
        ...entry,
        entryId: this.addUniqueIdToEntries(entry),
        path: this.addGroupPath(entry),
      })
    })
  }

  addUniqueIdToEntries (entry: any) {
    return this.dashboardMetadata.viewMetaData.keyColumns
      .map(key => entry[key]).join('$$')
  }

  // Function to generate a secure random string
  generateSecureRandomString (length: number): string {
    const randomBytesBuffer = randomBytes(length)
    const randomString = randomBytesBuffer.toString('base64')
      .replace(/[^a-zA-Z0-9]/g, '')
      .substr(0, length)
    return randomString
  }

  addGroupPath (entry: any) {
    const enomalyId = entry[this.dashboardMetadata.anomalyIdColumn]
    const path = [enomalyId]
    const isNotFirstAno = this.entries.find(e => e.path.includes(enomalyId))
    if (isNotFirstAno) {
      const secureRandomString = this.generateSecureRandomString(9)
      path.push(`_${secureRandomString}`)
    }
    return path
  }
}
