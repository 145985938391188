import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'

export interface CellUpdate {
  updatedRows: {
    values: Record<string, any>[]
  },
  keyColumns: string[]
}

export interface Payload {
  [key: string]: any
}

export interface UriParams {
  projectId: string
  tableName: string
  columnName: string
}

type Args = [
  Payload,
  EndpointsOptions<UriParams>?
]

export const dataTableUpdateCell = (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>(({ projectId, tableName }) => {
    return `v1/wexaus/projects/${projectId}/${tableName}/data/update`
  })

  return (...args: Args) => endpoint.put<CellUpdate>(...args)
}
