






































import { defineComponent, ref, reactive, PropType } from '@vue/composition-api'
import { GridApi, RowNode, GridOptions } from '@ag-grid-enterprise/all-modules'
import { FileBrowserObj } from '@@/types'
import useModal from '@/composables/pages/transferts/gerer-les-resultats/use-modal'
import useModalsLoadingStatus from '@/composables/pages/transferts/gerer-les-resultats/use-modals-loading-status'
import useMoveObjects from '@/composables/pages/transferts/gerer-les-resultats/use-move-objects'
import SelectedObjectsList from '@/components/pages/transferts/gerer-les-fichiers/SelectedObjectsList.vue'

import {
  headerValueGetter,
  objectGroupInnerRenderer,
  getHighestParentsFromList,
} from '@/components/pages/transferts/gerer-les-fichiers/helpers'

export default defineComponent({
  name: 'ModalMoveObjects',
  components: {
    SelectedObjectsList,
  },
  props: {
    gridApi: {
      type: Object as PropType<GridApi>,
      required: true,
    },
  },
  setup (props, ctx) {
    const { modalConfig, setModal, modalDefaultConfig, isModalOpen } = useModal({ ctx, modalName: 'ModalMoveObject' })
    const { isLoading, submitButtonStatus, preventClosingWhileLoading } = useModalsLoadingStatus({
      ctx,
      submitBtnI18nKey: 'transferts.gerer.modals.moveItem.form.okBtn',
    })

    const { isMovable, renameAndMoveObjects } = useMoveObjects({ ctx, gridOptions: { api: props.gridApi } })

    const originObjectsNodes: RowNode[] = modalConfig.value.data.originObjects
      .map((data: FileBrowserObj) => props.gridApi.getRowNode(data.key))

    const parentsToMove = ref<RowNode[]>(getHighestParentsFromList(originObjectsNodes).map(node => node.data))

    const gridOptions = reactive<GridOptions>({
      getRowNodeId: (data: FileBrowserObj) => data.key,
      getDataPath: (data: FileBrowserObj) => {
        if (data.path.length === 1 && data.path[0] === '/') {
          return data.path
        } else {
          return ['/', ...data.path]
        }
      },
      defaultColDef: { headerValueGetter: headerValueGetter.bind(ctx.root) },
      columnDefs: [],
      autoGroupColumnDef: {
        headerName: 'transferts.gerer.columns.headerNames.name',
        cellRendererParams: { innerRenderer: objectGroupInnerRenderer },
      },
      onGridReady: (params) => {
        params.api.sizeColumnsToFit()
      },
    })

    const selectedObjects = ref<RowNode[]>([])

    const folders = ref<FileBrowserObj[]>([
      ...ctx.root.$store.getters['files/browser/folderList'],
      {
        key: `${ctx.root.$store.getters['files/s3Credentials'].prefix}/`,
        path: ['/'],
        type: 'folder',
        lastModified: new Date(),
        size: 0,
      },
    ])

    /**
     * remove selected folder from proposed list of folder
    */
    const filteredFolders = folders.value.filter((folder: any) => !parentsToMove.value.includes(folder))

    /**
     * Check if a Node is selectable based on selected Nodes
     * @param node
     */
    const isRowSelectable = (node: RowNode): boolean => {
      const sourceNodes = getHighestParentsFromList(originObjectsNodes)
      const destinationNode = node.key === '/' ? undefined : props.gridApi.getRowNode(node.id as string) as RowNode
      return isMovable(sourceNodes, destinationNode)
    }

    /**
     * Expand first node ('/') to unsure visibility of children tree
     */
    const onRowDataChanged = () => {
      if (gridOptions.api) {
        gridOptions.api.forEachNode((node) => {
          if (node.level === 0) {
            node.setExpanded(true)
          }
        })
      }
    }

    /**
     * On form submit, move/rename nodes if movable
     * @param e
     */
    const onSubmit = (e: Event) => {
      e.preventDefault()
      if (!selectedObjects.value[0]) return

      const sourceNodes: RowNode[] = getHighestParentsFromList(originObjectsNodes)
      const destinationNode: RowNode = selectedObjects.value[0] as RowNode

      if (isMovable(sourceNodes, destinationNode)) {
        isLoading.value = true
        renameAndMoveObjects(sourceNodes, destinationNode)
        isLoading.value = false

        if (destinationNode && destinationNode.setExpanded) {
          destinationNode.setExpanded(true)
        }
        setModal({})
      } else {
        ctx.root.$toast.error(ctx.root.$tc('transferts.gerer.cantMove', sourceNodes.length) as string, { icon: 'xmark' })
      }
    }

    return {
      modalConfig,
      setModal,
      modalDefaultConfig,
      isModalOpen,
      isLoading,
      gridOptions,
      folders,
      submitButtonStatus,
      selectedObjects,
      isRowSelectable,
      onRowDataChanged,
      preventClosingWhileLoading,
      onSubmit,
      parentsToMove,
      filteredFolders,
    }
  },
})
