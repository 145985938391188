import { getterTree } from 'typed-vuex'
import { UploadQueuedFile } from '@@/types'
import state from './state'

const getters = getterTree(state, {
  uploadQueue: state => Object.keys(state.uploadQueue)
    .map((key: string) => state.uploadQueue[key]),
  isUploading: (state, getters) => Boolean(getters.uploadQueue
    .filter((file: UploadQueuedFile) => file.status === 'uploading')
    .length),
  modalConfig: state => state.modalConfig,
  destinationFolder: state => state.destinationFolder,
})

export default getters
