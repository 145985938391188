import { ColDef } from '@ag-grid-enterprise/all-modules'
// Cell Editors
import CellEditorDate from '@/components/common/ag-grid/cell-editors/Date.vue'
import CellEditorMask from '@/components/common/ag-grid/cell-editors/Mask.vue'
// Cell renderers
import { File } from '@/components/common/ag-grid/cell-renderers/File'
import { CellRendererDefault } from '@/components/common/ag-grid/cell-renderers/DefaultCell'
import { CellRendererBoolean } from '@/components/common/ag-grid/cell-renderers/BooleanCell'
import CellEditorSelect from '@/components/common/ag-grid/cell-editors/Select.vue'
import { FileCellEditor } from '@/components/common/ag-grid/cell-editors/FileCellEditor'

import {
  valueFormatterDefault,
  valueFormatterPercent,
  valueFormatterDate,
  valueFormatterNumber,
  valueFormatterAmount,
  valueFormatterDateTime,
  valueFormatterBoolean,
} from '@/components/common/ag-grid/value-formatters'

interface Columntypes {
  [key: string]: ColDef;
}

export const ascNumberFilterComparator = (a: string, b: string) => {
  const valA = Number(a)
  const valB = Number(b)
  if (!a) return -1
  if (!b) return 1
  if (valA === valB) return 0
  return valA > valB ? 1 : -1
}

export const STRING: ColDef = {
  valueFormatter: valueFormatterDefault,
  cellRenderer: CellRendererDefault,
  width: 160,
  filterParams: {
    buttons: ['reset', 'cancel', 'apply'],
    valueFormatter: valueFormatterDefault,
  },
}

export const LINK: ColDef = {
  ...STRING,
  valueSetter: (params) => {
    if (/^https?:\/\//.test(params.newValue) || !params.newValue) {
      params.data[params.colDef.colId as keyof typeof params.data] = params.newValue
    } else {
      params.context.app.$toast.error(params.context.app.$t('resultats.global.invalidFormat'))
    }
    return true
  },
  filterParams: {
    buttons: ['reset', 'cancel', 'apply'],
    valueFormatter: valueFormatterDefault,
  },
}

export const INTEGER: ColDef = {
  valueFormatter: valueFormatterDefault,
  cellRenderer: CellRendererDefault,
  cellEditorFramework: CellEditorMask,
  cellEditorParams: {
    options: {
      numeral: true,
      numeralDecimalMark: ',',
      delimiter: ' ',
      focus: true,
    },
  },
  width: 120,
  cellClass: 'text-right',
  filter: 'agMultiColumnFilter',
  filterParams: {
    filters: [
      {
        filter: 'agNumberColumnFilter',
        filterParams: { buttons: ['reset', 'cancel', 'apply'] },
      },
      {
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: valueFormatterDefault,
          comparator: ascNumberFilterComparator,
          buttons: ['reset', 'cancel', 'apply'],
        },
      },
    ],
  },
}

export const REAL: ColDef = {
  valueFormatter: valueFormatterNumber,
  cellRenderer: CellRendererDefault,
  cellEditorFramework: CellEditorMask,
  cellEditorParams: {
    options: {
      numeral: true,
      numeralDecimalMark: ',',
      delimiter: ' ',
      focus: true,
    },
  },
  width: 120,
  cellClass: 'text-right',
  filter: 'agMultiColumnFilter',
  filterParams: {
    filters: [
      {
        filter: 'agNumberColumnFilter',
        filterParams: { buttons: ['reset', 'cancel', 'apply'] },
      },
      {
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: valueFormatterNumber,
          comparator: ascNumberFilterComparator,
          buttons: ['reset', 'cancel', 'apply'],
        },
      },
    ],
  },
}

export const BOOLEAN: ColDef = {
  valueFormatter: valueFormatterDefault,
  cellRenderer: CellRendererBoolean,
  cellEditorFramework: CellEditorSelect,
  cellEditorParams: {
    listOfChoices: [
      { value: true, text: 'common.agGridCellEditors.boolean.true' },
      { value: false, text: 'common.agGridCellEditors.boolean.false' },
      { value: null, text: 'common.agGridCellEditors.boolean.null' },
    ],
  },
  width: 100,
  filterParams: {
    buttons: ['reset', 'cancel', 'apply'],
    valueFormatter: valueFormatterBoolean,
  },
}

export const FILE: ColDef = {
  valueFormatter: valueFormatterDefault,
  cellRenderer: File,
  cellEditor: FileCellEditor,
  filterParams: {
    buttons: ['reset', 'cancel', 'apply'],
    valueFormatter: valueFormatterDefault,
  },
}

export const AMOUNT: ColDef = {
  valueFormatter: valueFormatterAmount,
  cellRenderer: CellRendererDefault,
  cellEditorFramework: CellEditorMask,
  cellEditorParams: {
    options: {
      numeral: true,
      numeralDecimalMark: ',',
      delimiter: ' ',
      suffix: '€',
    },
  },
  width: 150,
  cellClass: 'text-right',
  filter: 'agMultiColumnFilter',
  filterParams: {
    filters: [
      {
        filter: 'agNumberColumnFilter',
        filterParams: { buttons: ['reset', 'cancel', 'apply'] },
      },
      {
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: valueFormatterAmount,
          comparator: ascNumberFilterComparator,
          buttons: ['reset', 'cancel', 'apply'],
        },
      },
    ],
  },
}

export const PERCENT: ColDef = {
  valueFormatter: valueFormatterPercent,
  cellRenderer: CellRendererDefault,
  cellEditorFramework: CellEditorMask,
  cellEditorParams: {
    options: {
      numeral: true,
      numeralDecimalMark: ',',
      delimiter: ' ',
      suffix: '%',
    },
  },
  width: 120,
  cellClass: 'text-right',
  filter: 'agMultiColumnFilter',
  filterParams: {
    filters: [
      {
        filter: 'agNumberColumnFilter',
        filterParams: { buttons: ['reset', 'cancel', 'apply'] },
      },
      {
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: valueFormatterPercent,
          comparator: ascNumberFilterComparator,
          buttons: ['reset', 'cancel', 'apply'],
        },
      },
    ],
  },
}

export const DATE: ColDef = {
  valueFormatter: valueFormatterDate,
  cellEditorFramework: CellEditorDate,
  cellRenderer: CellRendererDefault,
  width: 100,
  filter: 'agMultiColumnFilter',
  filterValueGetter: (params) => {
    const value = params.getValue(params.colDef.colId as string)
    return value ? new Date(value) : ''
  },
  filterParams: {
    filters: [
      {
        filter: 'agDateColumnFilter',
        filterParams: {
          valueFormatter: valueFormatterDate,
          buttons: ['reset', 'cancel', 'apply'],
        },
      },
      {
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: valueFormatterDate,
          buttons: ['reset', 'cancel', 'apply'],
          comparator: (a: string, b: string) => {
            const valA = new Date(a).getTime()
            const valB = new Date(b).getTime()
            if (valA === valB) return 0
            return valA > valB ? 1 : -1
          },
        },
      },
    ],
  },
}

export const DATE_TIME: ColDef = {
  valueFormatter: valueFormatterDateTime,
  cellEditorFramework: CellEditorDate,
  cellEditorParams: {
    hasTime: true,
  },
  cellRenderer: CellRendererDefault,
  width: 130,
  filter: 'agMultiColumnFilter',
  filterValueGetter: (params) => {
    const value = params.getValue(params.colDef.colId as string)
    return value ? new Date(value) : ''
  },
  filterParams: {
    filters: [
      {
        filter: 'agDateColumnFilter',
        filterParams: {
          valueFormatter: valueFormatterDate,
          buttons: ['reset', 'cancel', 'apply'],
        },
      },
      {
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: valueFormatterDateTime,
          buttons: ['reset', 'cancel', 'apply'],
          comparator: (a: string, b: string) => {
            const valA = new Date(a).getTime()
            const valB = new Date(b).getTime()
            if (valA === valB) return 0
            return valA > valB ? 1 : -1
          },
        },
      },
    ],
  },
}

export const columnTypes: Columntypes = {
  STRING,
  LINK,
  INTEGER,
  REAL,
  BOOLEAN,
  FILE,
  AMOUNT,
  PERCENT,
  DATE,
  DATE_TIME,
}
