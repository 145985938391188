

























































import { defineComponent, reactive, computed, toRefs, watch } from '@vue/composition-api'
// import { RawLocation } from 'vue-router'

import truncateString from '@/helpers/truncate-string'
import { Project } from '@/plugins/abbd-api/sdk/endpoints/v1/wexaus/projects'
import useSidebarMenu from '~/composables/use-sidebar-menu'

interface State {
  isOpen: boolean
  searchTerms: string
  sortOrder: 'asc'|'desc'
}

export default defineComponent({
  name: 'ProjectSelectionModal',
  filters: {
    truncateString,
  },
  setup (props, ctx) {
    const state = reactive<State>({
      isOpen: false,
      searchTerms: '',
      sortOrder: 'desc',
    })

    const selectedProject = computed(() => ctx.root.$accessor.projects.selectedProject)
    const user = computed(() => ctx.root.$accessor.user.user)
    const { defaultMenuItemName } = useSidebarMenu({ ctx })
    const filteredProjects = computed<Project[]>(() => {
      const sorting: any = {
        asc: (a:Project, b:Project) => a.label.localeCompare(b.label),
        desc: (a:Project, b:Project) => b.label.localeCompare(a.label),
      }

      return (ctx.root.$accessor.projects.projects)
        .filter(project => project.label.toLowerCase().includes(state.searchTerms.toLowerCase()))
        .sort(sorting[state.sortOrder])
    })

    const updateSelectedProject = async (selectedProject: Project) => {
      ctx.root.$bvModal.hide('modal-project-selection')
      await ctx.root.$accessor.projects.selectProject(selectedProject.id)
      const redirectRoute: any = { ...ctx.root.$route }
      redirectRoute.params.projectId = selectedProject.id
      ctx.root.$router.push(ctx.root.localePath({
        name: defaultMenuItemName,
      }))
    }

    watch(() => state.isOpen, async () => {
      if (state.isOpen) {
        await ctx.root.$accessor.projects.getProjects()
      }
    })

    return {
      ...toRefs(state),
      selectedProject,
      user,
      filteredProjects,
      updateSelectedProject,
    }
  },
})
