import { ICellRendererComp } from '@ag-grid-enterprise/all-modules'

export class FileCellEditor implements ICellRendererComp {
  getGui () {
    return document.createElement('div')
  }

  getValue () { 
    // this function is intentionnaly empty
  }

  isCancelBeforeStart () {
    return true
  }

  refresh () {
    return true
  }
}
