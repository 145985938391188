import Vue from 'vue'
import { actionTree } from 'typed-vuex'
import { AxiosError } from 'axios'
import { UploadQueuedFile } from '@@/types'
import { S3 } from 'aws-sdk'
import state from './state'
import mutations from './mutations'
import getters from './getters'

const actions = actionTree({ state, mutations, getters }, {
  addToQueue ({ commit }, files: UploadQueuedFile[]) {
    commit('ADD_TO_QUEUE', files)
  },

  removeFromQueue ({ commit }, file: UploadQueuedFile) {
    commit('REMOVE_FROM_QUEUE', file)
  },

  clearQueue ({ commit }) {
    commit('CLEAR_QUEUE')
  },

  async uploadObject ({ rootGetters, commit, dispatch, getters }, file: UploadQueuedFile): Promise<any> {
    const params = {
      Bucket: rootGetters['files/s3Credentials'].name,
      Key: `${rootGetters['files/s3Credentials'].prefix}/${file.Key}`,
      Body: file.Body,
    }

    const options = { partSize: 10 * 1024 * 1024, queueSize: 1 }
    const fileAlreadyExists = await dispatch('files/checkObjectExists', file.Key, { root: true })

    const handleProgress = (progress: S3.ManagedUpload.Progress) => {
      const newFile = {
        ...file,
        status: 'uploading',
        progress: Math.floor((progress.loaded / progress.total) * 100),
      }
      commit('UPDATE_QUEUE', newFile)
    }

    const removeQuery = (file: UploadQueuedFile) => {
      const keyArrayName = file.Key.split('/')
      const filename = keyArrayName[keyArrayName.length - 1]

      Vue.$aws.currentUploadQueries = Vue.$aws.currentUploadQueries
        .filter((query: any) => query.body.name !== filename)
    }

    const handleSuccess = () => {
      const newFile = {
        ...file,
        status: 'success',
        progress: 100,
      }
      commit('UPDATE_QUEUE', newFile)
      removeQuery(file)

      return newFile
    }

    const handleError = (err: AxiosError) => {
      const newFile = {
        ...file,
        status: err.message === 'Request aborted by user' ? 'canceled' : 'error',
        msg: err.message,
        progress: 0,
      }

      commit('UPDATE_QUEUE', newFile)
      removeQuery(file)

      return newFile
    }

    const currentFileInQueue = getters.uploadQueue.find((fileInQueue: UploadQueuedFile) => fileInQueue.Key === file.Key)

    if (!fileAlreadyExists && (currentFileInQueue && currentFileInQueue.status !== 'canceled')) {
      const query = await Vue.$aws.s3.upload(params, options)

      // @ts-ignore
      if (query.body) {
        Vue.$aws.currentUploadQueries.push(query)
      }

      return query
        .on('httpUploadProgress', handleProgress)
        .promise()
        .then(handleSuccess)
        .catch(handleError)
    } else if (currentFileInQueue && currentFileInQueue.status === 'canceled') {
      return file
    } else {
      const newFile = {
        ...file,
        status: 'alreadyExists',
        msg: 'The file already exists',
      }

      commit('UPDATE_QUEUE', newFile)
      return newFile
    }
  },

  cancelUpload ({ rootGetters, commit }, file: UploadQueuedFile) {
    if (Vue.$aws.currentUploadQueries.length) {
      Vue.$aws.currentUploadQueries.forEach((query: any) => {
        if (!file.Key || (file.Key && `${rootGetters['files/s3Credentials'].prefix}/${file.Key}` === query.service.config.params.Key)) {
          query.abort()
        }
      })
    }

    const newFile = {
      ...file,
      status: 'canceled',
      msg: 'Request aborted by user',
      progress: 0,
    }

    commit('UPDATE_QUEUE', newFile)
  },

  async notify ({ rootState }, message): Promise<any> {
    await Vue.$abbd.projects.notify({ action: message }, {
      uriParams: {
        projectId: rootState.route.params.projectId,
      },
    })
  },
  setModal ({ commit }, modalConfig) {
    commit('SET_MODAL_CONFIG', modalConfig)
  },
  setDestinationFolder ({ commit }, destinationFolder) {
    commit('SET_DESTINATION_FOLDER', destinationFolder)
  },
})
export default actions
