










































































import { Component, Prop, Vue, Action, Getter } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { GridApi, RowNode } from '@ag-grid-enterprise/all-modules'
import { Formfield, FileBrowserObj, UpdateObjectPayload } from '@@/types'
import SelectedObjectsList from '@/components/pages/transferts/gerer-les-fichiers/SelectedObjectsList.vue'

// NOTE: Component Decorator
@Component({
  name: 'ModalAddFolder',
  components: {
    SelectedObjectsList,
  },
})

// NOTE: Component Class
export default class ModalAddFolder extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationProvider>
  }

  // NOTE: Store
  @Getter('files/browser/modalConfig') modalConfig!: any
  @Action('files/browser/setModal') setModal!: (modalConfig: any) => void
  @Action('files/browser/addFolder') addFolder!: (folderName: string) => Promise<any>
  @Action('files/browser/moveObjects') moveObjects!: (rows: any[]) => Promise<any>
  @Action('files/browser/renameObjects') renameObjects!: (node: UpdateObjectPayload) => Promise<any>
  @Action('files/browser/deleteObjects') deleteObjects!: (objects: FileBrowserObj[]) => Promise<any>

  // NOTE: Props
  @Prop() readonly gridApi!: GridApi

  // NOTE: Data
  isLoading: boolean = false
  moveObjsAfterCreating: boolean = true
  allowMoveObjectAfterCreating: boolean = true
  form = {
    folderName: '',
  }

  selectedNodes: RowNode[] = []
  formFields: Formfield[] = [
    {
      component: 'FormInput',
      model: 'folderName',
      labelI18nKey: 'transferts.gerer.modals.addFolder.form.folderName',
      validation: {
        required: true,
        regex: /^[^/\\:*?"<>|]+$/,
      },
      group: {
        id: 'folder-name-group',
        labelFor: 'folder-name',
      },
      input: {
        id: 'folder-name',
        autofocus: true,
      },
    },
  ]

  // NOTE: Computed
  get selectedRows () {
    return this.selectedNodes.map(node => node.data)
  }

  // NOTE: Methods
  getNewKey () {
    if (this.modalConfig.data && this.modalConfig.data.target) {
      const target = this.modalConfig.data.target
      const parentFolder = target.path.join('/')
      return `${parentFolder}/${this.form.folderName}/`
    } else {
      return `${this.form.folderName}/`
    }
  }

  getNewKeyName (tempNode: RowNode, newFolder: string, originNode: RowNode): string {
    if (!tempNode.parent || (tempNode.parent && !tempNode.parent.data)) {
      return `${newFolder}${originNode.data.path.join('/')}${originNode.data.type === 'folder' ? '/' : ''}`
    } else if (tempNode.parent.isSelected()) {
      return this.getNewKeyName(tempNode.parent, newFolder, originNode)
    } else {
      return originNode.data.key.replace(tempNode.parent.data.key, newFolder)
    }
  }

  async onSubmit (e: Event) {
    e.preventDefault()

    const isValid = await this.$refs.form.validate()

    if (isValid) {
      this.isLoading = true

      const newFolder = await this.addFolder(this.getNewKey())
        .catch(() => {
          this.isLoading = false
          this.$toast.error(this.$t('transferts.gerer.modals.addFolder.error.folderAlreadyExists', { folder: this.form.folderName }) as string, { icon: 'xmark' })
        })

      // Moving files selected into new folder
      if (this.selectedNodes.length && this.moveObjsAfterCreating && this.allowMoveObjectAfterCreating) {
        const toUpdate = this.selectedNodes
          .map(node => ({
            oldKey: node.data.key,
            newKey: this.getNewKeyName(node, newFolder, node),
          }))
          .filter(node => node.oldKey !== node.newKey)

        await Promise.all(toUpdate.map(node => this.renameObjects(node)))

        const toDelete: FileBrowserObj[] = this.selectedNodes
          .filter(node => toUpdate.find(toUpdateNode => node.data.key === toUpdateNode.oldKey))
          .map(node => node.data.key)

        await this.deleteObjects(toDelete)
        await Promise.all(toDelete)
      }

      this.isLoading = false

      // Getting the node of new folder
      const targetNode = this.gridApi.getRowNode(this.modalConfig.data.target.key)
      const newFolderNode = this.gridApi.getRowNode(`${this.modalConfig.data.target.key}${this.form.folderName}/`)

      if (targetNode && targetNode.setExpanded && newFolderNode && newFolderNode.setExpanded) {
        targetNode.setExpanded(true)
        newFolderNode.setExpanded(true)
      }
      this.gridApi.redrawRows()
      this.setModal({})
    }
  }

  // NOTE: Created hook
  created () {
    // get the nodes that only contains data
    this.selectedNodes = this.gridApi.getSelectedNodes()
      .filter(node => node.data)

    if (this.modalConfig.data.target) {
      // If the target folder is itself -> dont allow moving objects
      const targetDirInSelectedObjs = this.selectedNodes
        .find(node => node.id === this.modalConfig.data.target.key)

      this.allowMoveObjectAfterCreating = !targetDirInSelectedObjs

      // remove itself and parents from selected nodes
      this.selectedNodes = this.selectedNodes
        .filter((node) => {
          const targetNode = this.gridApi.getRowNode(this.modalConfig.data.target.key)
          return !targetNode?.isParentOfNode(node)
        })
        .filter(node => node.data.key !== this.modalConfig.data.target.key)
    }
  }
}
