import { json2xml } from 'xml-js'

const req = require.context('../', true, /getObjects\.json$/)

export default req.keys()
  .map((key) => {
    const projectId = key.replace(/^\.\//, '').split('/')[0]

    const res = req(key)

    return {
      projectId,
      content: json2xml(JSON.stringify(res), { compact: true }),
    }
  })
