import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'
import { DashboardData } from '../dashboard/data'

export interface UriParams {
  projectId: string
  columnName: string
  viewName: string
}

type Args = [
  EndpointsOptions<UriParams>?
]

type PutArgs = [
  FormData,
  Args[0]?
]

export default (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>(({ projectId, columnName, viewName }) => {
    return `v1/wexaus/projects/${projectId}/dashboard/data/files/${columnName}?prefilteredView=${viewName}`
  })

  return {
    put: (...args: PutArgs) => endpoint.put<DashboardData>(...args),
  }
}
