import Vue from 'vue'
import FormInput from '@/components/common/form-generator/fields/FormInput.vue'
import FormSelect from '@/components/common/form-generator/fields/FormSelect.vue'
import FormFlags from '@/components/common/form-generator/fields/FormFlags.vue'
import FormTextArea from '@/components/common/form-generator/fields/FormTextArea.vue'
import FormCheckbox from '@/components/common/form-generator/fields/FormCheckbox.vue'
import ModalRenameObject from '@/components/pages/transferts/gerer-les-fichiers/modals/ModalRenameObject.vue'
import ModalMoveObject from '@/components/pages/transferts/gerer-les-fichiers/modals/ModalMoveObject.vue'
import ModalSelectFolder from '@/components/pages/transferts/envoyer-des-fichiers/modals/ModalSelectFolder.vue'
import ModalDeleteObjects from '@/components/pages/transferts/gerer-les-fichiers/modals/ModalDeleteObjects.vue'
import ModalAddFiles from '@/components/pages/transferts/gerer-les-fichiers/modals/ModalAddFile.vue'
import ModalAddFolder from '@/components/pages/transferts/gerer-les-fichiers/modals/ModalAddFolder.vue'
import ModalMultipleEdit from '@/components/common/ModalMultipleEdit.vue'

Vue.component('FormInput', FormInput)
Vue.component('FormSelect', FormSelect)
Vue.component('FormFlags', FormFlags)
Vue.component('FormTextArea', FormTextArea)
Vue.component('FormCheckbox', FormCheckbox)

Vue.component('ModalRenameObject', ModalRenameObject)
Vue.component('ModalMoveObject', ModalMoveObject)
Vue.component('ModalSelectFolder', ModalSelectFolder)
Vue.component('ModalDeleteObjects', ModalDeleteObjects)
Vue.component('ModalAddFiles', ModalAddFiles)
Vue.component('ModalAddFolder', ModalAddFolder)
Vue.component('ModalMultipleEdit', ModalMultipleEdit)
