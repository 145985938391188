import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'
import { SubLevelKeyMeta } from '@/plugins/abbd-api/sdk/types/api/SubLevelKeyMeta'
import { ColumnMeta } from '@/plugins/abbd-api/sdk/types/api/ColumnMeta'

export interface EntryMouvementsMeta {
  timestamp: number
  name: string
  label?: any
  columns: ColumnMeta[]
  subLevelKey: SubLevelKeyMeta[]
}

export interface EntryMouvements {
  metaData: EntryMouvementsMeta
  data: {
    values: { [key: string]: any }[]
  }
}

export interface Payload {
  [key: string]: any
}

export interface UriParams {
  projectId: string
}

type Args = [
  Payload,
  EndpointsOptions<UriParams>?
]

export default (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>(({ projectId }) => `/v1/wexaus/projects/${projectId}/dashboard/mouvements/data`)

  return (...args: Args) => endpoint.post<EntryMouvements>(...args)
}
