import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'

export interface Payload {
  password: string
}

export interface UriParams {
  token: string
}

type Args = [
  Payload,
  EndpointsOptions<UriParams>?
]

export default (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>(({ token }) => {
    return `v2/exaus/users/changepassword?token=${token}`
  })
  const headers = { 'Content-Type': 'text/plain' }
  return (...args: Args) => {
    return endpoint.post(args[0].password, {
      ...args[1],
      headers,
    })
  }
}
