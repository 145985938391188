// An id (not provided by the API) is added in each file to match the right project (then deleted before sending to the frontend)
const req = require.context('../../../', true, /\.json$/)

export default req.keys()
  .filter(key => key.includes('/synthesis/data/'))
  .map((key) => {
    const projectId = key.replace(/^\.\//, '').split('/')[0]
    const syntheseId = key.split('/').pop().replace(/\.json$/, '')

    const res = req(key)
    res.projectId = projectId
    res.syntheseId = syntheseId

    return res
  })
