import { FileBrowserObj } from '@@/types/store/files'

export interface FileBrowserState {
  fileBrowserList: FileBrowserObj[]
  selectedObjects: FileBrowserObj[]
  modalConfig: any
}

const fileState = (): FileBrowserState => ({
  fileBrowserList: [],
  selectedObjects: [],
  modalConfig: {
    name: '',
    data: {},
  },
})

export default fileState
