import { reactive, toRefs, computed, SetupContext } from '@vue/composition-api'
import { MenuCategory } from '@@/types'
import { useNuxtApp } from '~/plugins/vue-composition-api'
// import useAuth from '@/composables/use-auth'

interface State {
  isSidebarOpened: boolean
  appMenu: MenuCategory[]
  adminMenu: MenuCategory[]
}

interface Options {
  ctx: SetupContext
}

const state = reactive<State>({
  isSidebarOpened: true,
  appMenu: [
    {
      opened: true,
      name: 'common.sidebar.menuCategories.transferts',
      featureKey: 'file_transfer',
      items: [
        {
          label: 'common.sidebar.menuItems.envoyerFichiers',
          to: { name: 'projectId-transferts-envoyer-des-fichiers' },
          isVisible: () => true,
        },
        {
          label: 'common.sidebar.menuItems.gererFichiers',
          to: { name: 'projectId-transferts-gerer-les-fichiers' },
          isVisible: () => true,
        },
      ],
    },
    {
      opened: true,
      name: 'common.sidebar.menuCategories.resultats',
      featureKey: 'dashboard',
      items: [
        {
          label: 'common.sidebar.menuItems.vueGlobale',
          to: { name: 'projectId-resultats-dashboards-vueName' },
          hasActionMenu: true,
          isVisible: ({ selectedProject }) => selectedProject?.optionalFeatures.includes('dashboard'),
        },
        {
          label: 'common.sidebar.menuCategories.synthese',
          to: { name: 'projectId-resultats-synthese-syntheseName' },
          hasActionMenu: true,
          isVisible: ({ selectedProject }) => selectedProject?.optionalFeatures.includes('dashboard'),
        },
        {
          label: 'common.sidebar.menuItems.suppliers',
          to: { name: 'projectId-resultats-suppliers' },
          hasActionMenu: true,
          isVisible: ({ selectedProject, user }) => selectedProject?.optionalFeatures.includes('mainsuppliers_dashboard') && user.optionalFeatures.includes('mainsuppliers_dashboard'),
        },
      ],
    },
    {
      opened: true,
      name: 'common.sidebar.menuItems.duplicateSuppliers',
      featureKey: 'supplier_duplicates_dashboard',
      items: [
        {
          label: 'common.sidebar.menuItems.duplicateSuppliers',
          to: { name: 'projectId-resultats-duplicate-suppliers' },
          hasActionMenu: true,
          isVisible: ({ selectedProject, user }) => selectedProject?.optionalFeatures.includes('supplier_duplicates_dashboard') && user.optionalFeatures.includes('supplier_duplicates_dashboard'),
        },
      ],
    },
    {
      opened: true,
      name: 'common.sidebar.menuCategories.analysis',
      featureKey: 'free_anomaly',
      items: [
        {
          label: 'common.sidebar.menuItems.freeAnomaly',
          to: { name: 'projectId-analysis-free-anomaly' },
          hasActionMenu: true,
          isVisible: ({ selectedProject, user }) => selectedProject?.optionalFeatures.includes('free_anomaly') && user.optionalFeatures.includes('free_anomaly'),
        },
      ],
    },
  ],
  adminMenu: [{
    opened: true,
    name: 'common.sidebar.adminMenuItems.home',
    featureKey: '',
    items: [],
    to: { name: 'admin-home' },
  }],
})

export default function useSidebarMenu ({ ctx }: Options) {
  const { $accessor } = useNuxtApp()

  /** Is the active page an Admin page ? */
  const isAdminPage = computed(() => ctx.root.$route.path.includes('/admin'))

  /** Return the filtered sidebar menu based on user activated features and admin/app active route */
  const sidebarMenu = computed<MenuCategory[]>(() => state[isAdminPage.value ? 'adminMenu' : 'appMenu']
    .filter((cat: MenuCategory) => {
      const isFeatureActive = $accessor.user.user.optionalFeatures.includes(cat.featureKey)
      return cat.featureKey ? isFeatureActive : true
    })
    .filter((cat: MenuCategory) => {
      const isDashboard = cat.featureKey === 'dashboard'
      const isDuplicateSupplier = cat.featureKey === 'supplier_duplicates_dashboard'
      const isFreeAnomaly = cat.featureKey === 'free_anomaly'
      const isProjectDashAvailable = ctx.root.$store.getters['projects/selectedProject'].optionalFeatures.includes('dashboard')
      const isDuplicateSupplierAvailable = ctx.root.$store.getters['projects/selectedProject'].optionalFeatures.includes('supplier_duplicates_dashboard')
      const isFreeAnomalyAvailable = ctx.root.$store.getters['projects/selectedProject'].optionalFeatures.includes('free_anomaly')
      return (!isDashboard && !isDuplicateSupplier && !isFreeAnomaly) || (isDashboard && isProjectDashAvailable) || (isDuplicateSupplier && isDuplicateSupplierAvailable) || (isFreeAnomaly && isFreeAnomalyAvailable)
    }))

  /** Returns the first available menu item name */
  const defaultMenuItemName = computed(() => {
    const isDashboard = $accessor.user.user.optionalFeatures.includes('dashboard')
    const isProjectDashAvailable = ctx.root.$store.getters['projects/selectedProject'].optionalFeatures.includes('dashboard')
    if (isDashboard && isProjectDashAvailable) {
      return 'projectId-resultats-dashboards-vueName'
    }
    const isGroupNav = sidebarMenu.value[0] && sidebarMenu.value[0].to
    const isChildNav = sidebarMenu.value[0] && sidebarMenu.value[0].items[0]

    if (isGroupNav || isChildNav) {
      return sidebarMenu.value[0].items[0].to.name
    } else {
      return undefined
    }
  })

  /** * Does the sidebar has any menu items ? */
  const hasSidebar = computed(() => Boolean(defaultMenuItemName))

  /** Toggle the sidebar visibility status */
  const toggleSidebarStatus = () => {
    state.isSidebarOpened = !state.isSidebarOpened
  }

  /**
   * Toggle a menu group collapse status
   * @param group
   */
  const toggleMenuGroup = (group: MenuCategory) => {
    state.appMenu = state.appMenu
      .map((category: MenuCategory) => {
        if (category.name === group.name) {
          category.opened = !category.opened
        }
        return category
      })
  }

  /**
   * Return true if one of the menu group's children is active
   * @param category
   */
  const isChildrenActive = (category: MenuCategory) => {
    return category.items.find(item => item.to.name && ctx.root.$route.name?.includes(item.to.name))
  }

  return {
    ...toRefs(state),
    isAdminPage,
    sidebarMenu,
    defaultMenuItemName,
    hasSidebar,
    toggleSidebarStatus,
    toggleMenuGroup,
    isChildrenActive,
  }
}
