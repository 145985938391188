import { ICellRendererComp, ICellRendererParams } from '@ag-grid-enterprise/all-modules'

interface AtLinkCellRendererParams extends ICellRendererParams {
  apiDashboardMeta: any
}

export class MovementsLink implements ICellRendererComp {
  eGui: HTMLElement = document.createElement('div')

  init (params: AtLinkCellRendererParams) {
    if (params.node.rowPinned) return

    this.eGui.className = 'd-flex align-items-center justify-content-start h-100 text-left'

    const linkDom = `<a><i class="fas fa-plus-square mr-1"></i>${params.valueFormatted}</a>`
    this.eGui.innerHTML = `<div class="flex-1 !text-green-600">${params.valueFormatted ? linkDom : ''}</div>`

    const link = this.eGui.querySelector('a')

    if (link) {
      link.onclick = () => {
        const name = params.context.app.$route.name.includes('projectId-resultats-dashboards-vueName-anomalie-anomalyId')
          ? 'projectId-resultats-dashboards-vueName-anomalie-anomalyId-mouvements-entryId'
          : 'projectId-resultats-dashboards-vueName-mouvements-entryId'

        const routeToNavigateTo = params.context.app.localePath({
          name,
          params: {
            ...params.context.app.$route.params,
            entryId: params.data.entryId,
          },
        })

        params.context.app.$router.push(routeToNavigateTo)
      }
    }
  }

  refresh () {
    return true
  }

  getGui () {
    return this.eGui
  }
}
