import { PluginObject, VueConstructor } from 'vue'
import S3 from 'aws-sdk/clients/s3'
import { Credentials } from 'aws-sdk/lib/core'

export interface AwsPlugin {
  instance: {
    S3: typeof S3
    Credentials: typeof Credentials
  },
  currentUploadQueries: any[]
  s3: S3
  initS3: (region: string) => void
}

const plugin: PluginObject<any> = {
  install (_Vue: VueConstructor, { apiVersion }) {
    const awsPlugin: AwsPlugin = {
      instance: {
        S3,
        Credentials,
      },
      currentUploadQueries: [],
      s3: {} as S3,
      initS3 (region: string) {
        this.s3 = new awsPlugin.instance.S3({
          apiVersion,
          region,
          correctClockSkew: true,
          httpOptions: {
            timeout: 0,
          },
        })
      },
    }

    _Vue.$aws = awsPlugin
    _Vue.prototype.$aws = awsPlugin
  },
}

export default plugin
