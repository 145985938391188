import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'
import { ColumnMeta } from '@/plugins/abbd-api/sdk/types/api/ColumnMeta'
import { SubLevelKeyMeta } from '@/plugins/abbd-api/sdk/types/api/SubLevelKeyMeta'

export interface DashboardViewMeta {
  columns: ColumnMeta[]
  keyColumns: string[]
  label: string
  name: string
  subLevelKey: SubLevelKeyMeta[]
  timestamp: number
  description: string
}

export interface DashboardMetadata {
  anomalyColumns: string[]
  anomalyIdColumn: string
  entryColumns: string[]
  linkMovementColumn: string
  validatedColumn: string
  validationAmountColumn: string
  validationColumn: string
  viewMetaData: DashboardViewMeta
  viewName: string
  anomalyCardDesign: any[]
  anomalyCardEntryDesign: any[]
  customerComment: string
  validatedBy: string
  validatedDate: string
}

export interface UriParams {
  projectId: string
}

type Args = [
  EndpointsOptions<UriParams>?
]

export default (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>(({ projectId }) => `v1/wexaus/projects/${projectId}/dashboard/metadata`)
  return (...args: Args) => endpoint.get<DashboardMetadata>(...args)
}
