

export default () => {
    if (process.server) {
        return;
    }

    const detect = () => 'startsWith' in window.String;

    if (detect()) {
        return;
    }

            const polyfill = require("core-js/es/string/starts-with");
}