











import { Vue, Component } from 'nuxt-property-decorator'
import { useNuxtApp } from '~/plugins/vue-composition-api'

// NOTE: Component Decorator
@Component({
  name: 'authenticated-no-project',
})

// NOTE: Component Class
export default class LayoutAuthenticated extends Vue {
  head () {
    const { i18n } = useNuxtApp()
    return {
      title: i18n.t('common.title'),
    }
  }
}
