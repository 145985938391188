import { AxiosInstance } from 'axios'
import EndpointFactory from 'axios-endpoints'

// Endpoints
// auth
import authLogin from './auth/login'
import refreshToken from './auth/refreshToken'
import passwordRecovery from './auth/passwordRecovery'
import resetPassword from './auth/resetPassword'

// Project
import projects from './v1/wexaus/projects'
import filestore from './v1/wexaus/projects/_projectId/filestore'
import notify from './v1/wexaus/projects/_projectId/notify'

// Dashboard
import dashboardMeta from './v1/wexaus/projects/_projectId/dashboard/metadata'
import dashboardData from './v1/wexaus/projects/_projectId/dashboard/data'
import dashboardCellUpdate from './v1/wexaus/projects/_projectId/dashboard/data/update'
import dashboardRowsUpdate from './v1/wexaus/projects/_projectId/dashboard/data/updaterows'
import dashboardColumnsUpdate from './v1/wexaus/projects/_projectId/dashboard/data/updatecolumns'
import dashboardListofchoices from './v1/wexaus/projects/_projectId/dashboard/data/listofchoices'
import dashboardFile from './v1/wexaus/projects/_projectId/dashboard/files'
import dashboardUpdateMultiFile from './v1/wexaus/projects/_projectId/filestore/multiFiles'
import dashboardExport from './v1/wexaus/projects/_projectId/dashboard/export'

// Synthesis
import synthesysMeta from './v1/wexaus/projects/_projectId/dashboard/synthesis/metadata'
import synthesysData from './v1/wexaus/projects/_projectId/dashboard/synthesis/data'
import prefilteredViews from './v1/wexaus/projects/_projectId/dashboard/data/prefilteredviews'

// Movements
import movementsData from './v1/wexaus/projects/_projectId/dashboard/mouvements/data'
import movementsExport from './v1/wexaus/projects/_projectId/dashboard/mouvements/export'

// Movements
import { dataTableMetadata } from './dataTable/metadata'
import { dataTableData } from './dataTable/data'
import { dataTableListofchoices } from './dataTable/listofchoices'
import { dataTableUpdateCell } from './dataTable/updateCell'
import { dataTableUpdateColumn } from './dataTable/updatecolumns'
import { dataTableUpdateRow } from './dataTable/updaterows'
// Duplicate Supplier
import duplicateSuppliersFile from './v1/wexaus/projects/_projectId/duplicate-suppliers/files'
import projectById from './v1/wexaus/projects/_projectId/projectById'
import notifyAction from './v1/wexaus/projects/_projectId/notifyAction'
import updateMultiple from './v1/wexaus/projects/_projectId/suppliers/data/updateMultiple'
import updateMultipleDuplicate from './v1/wexaus/projects/_projectId/duplicate-suppliers/data/updateMultiple'
// Free Anomaly
import updateMultipleFreeAnomaly from './v1/wexaus/projects/_projectId/free-anomaly/data/updateMultiple'
import freeAnomalyFile from './v1/wexaus/projects/_projectId/free-anomaly/files'
import freeAnomalyUpdateMultiFile from './v1/wexaus/projects/_projectId/free-anomaly/multiFiles'

export default function (axiosInstance: AxiosInstance) {
  const Endpoint = EndpointFactory(axiosInstance)

  return {
    auth: {
      login: authLogin(Endpoint),
      refreshToken: refreshToken(Endpoint),
      passwordRecovery: passwordRecovery(Endpoint),
      resetPassword: resetPassword(Endpoint),
    },
    projects: {
      all: projects(Endpoint),
      byId: projectById(Endpoint),
      notify: notify(Endpoint),
      notifyAction: notifyAction(Endpoint),
      s3Credentials: filestore(Endpoint),
      dashboard: {
        meta: dashboardMeta(Endpoint),
        data: dashboardData(Endpoint),
        file: dashboardFile(Endpoint),
        listofchoices: dashboardListofchoices(Endpoint),
        prefilteredViews: prefilteredViews(Endpoint),
        movements: {
          data: movementsData(Endpoint),
          export: movementsExport(Endpoint),
        },
        synthesys: {
          meta: synthesysMeta(Endpoint),
          data: synthesysData(Endpoint),
        },
        update: {
          cell: dashboardCellUpdate(Endpoint),
          rows: dashboardRowsUpdate(Endpoint),
          cells: dashboardColumnsUpdate(Endpoint),
          multiFile: dashboardUpdateMultiFile(Endpoint),
        },
        downloadAsXlsx: dashboardExport(Endpoint),
      },
      duplicateSuppliers: {
        file: duplicateSuppliersFile(Endpoint),
        updateMultiple: updateMultipleDuplicate(Endpoint),
      },
      suppliers: {
        updateMultiple: updateMultiple(Endpoint),
      },
      freeAnomaly: {
        updateMultiple: updateMultipleFreeAnomaly(Endpoint),
        file: freeAnomalyFile(Endpoint),
        multiFile: freeAnomalyUpdateMultiFile(Endpoint),
      },
      // abstractTables: {
      //   meta: typedEndpoint<ApiParams, AbbdApi.projects.abstractTables.Metadata>(({ projectId, atName }) => `v1/wexaus/projects/${projectId}/metadata/${atName}`).get,
      //   data: typedEndpoint<ApiParams, AbbdApi.projects.abstractTables.Data>(({ projectId, atName }) => `v1/wexaus/projects/${projectId}/data/${atName}`).post,
      // },
      // data: {
      //   listofchoices: typedEndpoint<ApiParams>(({ projectId, atName, columnName }) => `v1/wexaus/projects/${projectId}/data/${atName}/listofchoices/${columnName}`),
      //   update: {
      //     cell: typedEndpoint<ApiParams>(({ projectId, atName, columnName }) => `v1/wexaus/projects/${projectId}/data/${atName}/update/${columnName}`),
      //     rows: typedEndpoint<ApiParams>(({ projectId, atName, columnName }) => `v1/wexaus/projects/${projectId}/data/${atName}/updaterows/${columnName}`),
      //     cells: typedEndpoint<ApiParams>(({ projectId, atName }) => `v1/wexaus/projects/${projectId}/data/${atName}/updatecolumns`),
      //   },
      // },
    },
    dataTable: {
      meta: dataTableMetadata(Endpoint),
      data: dataTableData(Endpoint),
      listofchoices: dataTableListofchoices(Endpoint),
      updateCell: dataTableUpdateCell(Endpoint),
      updateCol: dataTableUpdateColumn(Endpoint),
      updateRow: dataTableUpdateRow(Endpoint),
    },
  }
}
