


































import { Component, mixins } from 'nuxt-property-decorator'
import fieldMixin from './mixin'

// NOTE: Component Decorator
@Component({
  name: 'FormFlags',
})

// NOTE: Component Class
export default class FormDropdown extends mixins(fieldMixin) {
  langFlags = {
    fr: { flag: 'fr', label: 'Français' },
    en: { flag: 'us', label: 'English' },
  }

  switchLang (lang: string) {
    // this.$router.push(this.switchLocalePath(lang))
    this.$emit('input', lang)
    this.$i18n.locale = lang
  }
}
