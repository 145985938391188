





































import { defineComponent, computed } from '@vue/composition-api'
import { Project } from '@/plugins/abbd-api/sdk/endpoints/v1/wexaus/projects'
import AbbdSidebarHeader from '@/components/common/AbbdSidebar/AbbdSidebarHeader.vue'
import AbbdSidebarMenu from '@/components/common/AbbdSidebar/AbbdSidebarMenu.vue'
import useSidebarMenu from '@/composables/use-sidebar-menu'

export default defineComponent({
  name: 'AbbdSidebar',
  components: {
    AbbdSidebarHeader,
    AbbdSidebarMenu,
  },
  setup (props, ctx) {
    const { isAdminPage, isSidebarOpened, toggleSidebarStatus, hasSidebar } = useSidebarMenu({ ctx })
    const projects = computed<Project[]>(() => ctx.root.$store.getters['projects/projects'])
    const selectedProject = computed<Project>(() => ctx.root.$store.getters['projects/selectedProject'])

    return {
      isAdminPage,
      projects,
      selectedProject,
      isSidebarOpened,
      toggleSidebarStatus,
      hasSidebar,
    }
  },
})
