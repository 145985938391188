import { Project } from '@/plugins/abbd-api/sdk/endpoints/v1/wexaus/projects'

export interface ProjectsState {
  projects: Project[],
  selectedProject: Project | undefined
}

const state = (): ProjectsState => ({
  projects: [],
  selectedProject: undefined,
})

export default state
