import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'

export interface DashboardData {
  values: { [key: string]: any }[]
}

export interface UriParams {
  projectId: string
  tableName: string
}

type Args = [
  EndpointsOptions<UriParams>?
]

export const dataTableData = (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>(({ projectId, tableName }) => `v1/wexaus/projects/${projectId}/${tableName}/data`)

  return (...args: Args) => endpoint.get<DashboardData>(...args)
}
