import { ColGroupDef } from '@ag-grid-enterprise/all-modules'
import { DashboardMetadata } from '@/plugins/abbd-api/sdk/endpoints/v1/wexaus/projects/_projectId/dashboard/metadata'

export interface DashboardState {
  anomalyCardDesign: any[]
  columnDefsFromDesign: any[]
  columnDefs: ColGroupDef[]
  data: any[]
  viewName: string
  anomalyIdColumn: string
  footerSumCols: string[]
  meta: DashboardMetadata | undefined
  prefilteredView: string
  modalConfig: any
}

const state = (): DashboardState => ({
  columnDefs: [],
  data: [],
  viewName: '',
  anomalyIdColumn: '',
  footerSumCols: [],
  meta: undefined,
  prefilteredView: '',
  modalConfig: {
    name: '',
    data: {},
  },
  anomalyCardDesign: [],
  columnDefsFromDesign: [],
})

export default state
