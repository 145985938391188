import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'

export interface UriParams {
  projectId: string
  atName: string
}

type Args = [
  EndpointsOptions<UriParams>?
]

export default (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>(({ projectId, atName }) => {
    return `v1/wexaus/projects/${projectId}/dashboard/export/${atName}`
  })

  return (...args: Args) => endpoint.get(...args)
}
