export const confirmSmDanger = {
  okVariant: 'danger',
  cancelVariant: 'link',
  hideHeaderClose: false,
  buttonSize: 'sm',
  centered: true,
  bodyClass: 'text-center',
  footerClass: 'p-2',
  size: 'sm',
}
