



























import { defineComponent, PropType } from '@vue/composition-api'
import { GridApi } from '@ag-grid-enterprise/all-modules'
import { FileBrowserObj } from '@@/types'
import useModal from '@/composables/pages/transferts/gerer-les-resultats/use-modal'
import useModalsLoadingStatus from '@/composables/pages/transferts/gerer-les-resultats/use-modals-loading-status'
import SelectedObjectsList from '@/components/pages/transferts/gerer-les-fichiers/SelectedObjectsList.vue'

export default defineComponent({
  name: 'ModalDeleteObjects',
  components: {
    SelectedObjectsList,
  },
  props: {
    gridApi: {
      type: Object as PropType<GridApi>,
      required: true,
    },
  },
  setup (props, ctx) {
    const { modalConfig, setModal, modalDefaultConfig, isModalOpen } = useModal({ ctx, modalName: 'ModalDeleteObjects' })
    const { isLoading, submitButtonStatus, preventClosingWhileLoading } = useModalsLoadingStatus({
      ctx,
      submitBtnI18nKey: 'transferts.gerer.modals.deleteItem.form.okBtn',
    })

    const keysToDelete: string[] = modalConfig.value.data.selectedObjects
      .map((node: FileBrowserObj) => node.key)

    const onSubmit = async () => {
      isLoading.value = true
      await ctx.root.$store.dispatch('files/browser/deleteObjects', keysToDelete)
      props.gridApi.redrawRows()
      isLoading.value = false
      setModal({})
    }

    return {
      modalConfig,
      setModal,
      modalDefaultConfig,
      isModalOpen,
      isLoading,
      submitButtonStatus,
      preventClosingWhileLoading,
      onSubmit,
    }
  },
})
