












































import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'
import Cleave from 'vue-cleave-component'
import dayjs from 'dayjs'
import toObject from 'dayjs/plugin/toObject'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import { fixDateTimezone } from '@/helpers/fix-date-timezone'

dayjs.extend(utc)
dayjs.extend(toObject)
dayjs.extend(customParseFormat)

export default defineComponent({
  name: 'FormulateMask',
  mixins: [FormulateInputMixin],
  components: {
    Cleave,
  },
  setup (props, ctx) {
    const state = reactive({
      cleaveInput: null,
      tempDate: '',
      originalDate: ctx.parent?.context.model,
      isCalendarOpened: false,
    })

    const getValue = (value: string) => {
      if (!value) return null

      let returnedValue

      if (state.originalDate) {
        const originDate = dayjs(fixDateTimezone(state.originalDate)).toObject()

        returnedValue = dayjs(value, ctx.root.$t('common.dateFormat'))
          .set('hour', originDate.hours)
          .set('minute', originDate.minutes)
          .set('second', originDate.seconds)
          .set('millisecond', originDate.milliseconds)
      } else {
        returnedValue = dayjs(value, ctx.root.$t('common.dateFormat'))
      }

      const newDate = returnedValue.utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        .replace(/(\d{2}):(\d{2})$/, '$1$2')

      // Prevent IE to think the date has been updated at startup
      // (IE sets the Milliseconds to )...
      const dateIsSame = dayjs(fixDateTimezone(state.originalDate), 'YYYY-mm-DD')
        .isSame(dayjs(newDate, 'YYYY-mm-DD'))

      return dateIsSame ? state.originalDate : newDate
    }

    if (state.originalDate) {
      const originDateObj = new Date(fixDateTimezone(state.originalDate))
      state.tempDate = dayjs(originDateObj).format(ctx.root.$t('common.dateFormat') as string)
    }

    return {
      ...toRefs(state),
      getValue,
    }
  },
})
