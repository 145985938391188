



















































import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from '@vue/composition-api'
import IeBanner from '@/components/common/IeBanner.vue'
import AbbdSidebar from '@/components/common/AbbdSidebar/AbbdSidebar.vue'
import useSidebarMenu from '@/composables/use-sidebar-menu'
import useAuth from '@/composables/use-auth'
import { useNuxtApp } from '@/plugins/vue-composition-api'

export default defineComponent({
  name: 'LayoutAuthenticated',
  head () {
    const { i18n } = useNuxtApp()
    return {
      title: i18n.t('common.title'),
    }
  },
  components: {
    IeBanner,
    AbbdSidebar,
  },
  middleware: 'requireAuth',
  setup (props, ctx) {
    const { isSidebarOpened, toggleSidebarStatus } = useSidebarMenu({ ctx })
    const { i18n, localePath, router } = useNuxtApp()
    const { signout } = useAuth({ route: ctx.root.$route })
    let userActivityTimeout:any = null
    // délai d’expiration du régulateur
    let userActivityThrottlerTimeout:any = null
    const isInactive = ref(false)
    // effacer délai d'attente existant et en démarrer un nouveau
    const resetUserActivityTimeout = () => {
      clearTimeout(userActivityTimeout)

      userActivityTimeout = setTimeout(() => {
        userActivityThrottler()
        inactiveUserAction()
      }, 7200000)
      // }, 10000)
    }
    const rememberMe = computed(() => window.localStorage.getItem('rememberMe'))
    const userActivityThrottler = () => {
      if (isInactive.value) {
        isInactive.value = false
      }
      // On crée un déclencheur si il n'existe pas toute les 2 secondes pour éviter de créer un à chaque mouvement de souris
      if (!userActivityThrottlerTimeout) {
        userActivityThrottlerTimeout = setTimeout(() => {
          resetUserActivityTimeout()
          clearTimeout(userActivityThrottlerTimeout)
          userActivityThrottlerTimeout = null
        }, 1000)
      }
    }

    const activateActivityTracker = () => {
      window.addEventListener('mousemove', userActivityThrottler)
      window.addEventListener('keydown', userActivityThrottler)
    }

    const deactivateActivityTracker = () => {
      window.removeEventListener('mousemove', userActivityThrottler)
      window.removeEventListener('keydown', userActivityThrottler)
    }
    const modalShow = ref(false)
    const idleTimeout = ref(60)
    const idleSecondsCounter = ref(0)
    let logoutTimer:any = null
    // après une minute on logout l'utilisateur s'il a pas prolonger la session
    const CheckIdleTime = () => {
      idleSecondsCounter.value++
      if (idleSecondsCounter.value === 60) {
        stopSession()
      }
    }
    const counter = computed(() => idleTimeout.value - idleSecondsCounter.value)
    const startCounter = () => {
      if (!logoutTimer) {
        logoutTimer = setInterval(CheckIdleTime, 1000)
      }
    }
    const inactiveUserAction = () => {
      isInactive.value = true
      deactivateActivityTracker()
      if (document.hasFocus()) {
        startCounter()
        modalShow.value = true
      } else {
        stopSession()
      }
    }
    const addSession = () => {
      modalShow.value = false
      isInactive.value = false
      idleTimeout.value = 60
      idleSecondsCounter.value = 0
      clearInterval(logoutTimer)
      logoutTimer = null
      activateActivityTracker()
    }
    const stopSession = async () => {
      modalShow.value = false
      isInactive.value = false
      idleTimeout.value = 60
      idleSecondsCounter.value = 0
      clearInterval(logoutTimer)
      await ctx.root.$accessor.user.logout()
      ctx.root.$router.push(localePath.call({ i18n, router }, { name: 'login', query: { redirect: ctx.root.$route.fullPath } }))
      ctx.root.$toast.error(ctx.root.$t('common.logout') as string, { icon: 'xmark' })
    }
    const exausCustomer = computed(() => ctx.root.$accessor.user.user.roleType === 'ExausCustomer')
    onMounted(() => {
      if (exausCustomer.value && !rememberMe.value) {
        activateActivityTracker()
      }
    })
    onBeforeUnmount(() => {
      deactivateActivityTracker()
      clearTimeout(userActivityTimeout)
      clearTimeout(userActivityThrottlerTimeout)
      clearInterval(logoutTimer)
    })

    return {
      isSidebarOpened,
      toggleSidebarStatus,
      isInactive,
      activateActivityTracker,
      deactivateActivityTracker,
      userActivityThrottlerTimeout,
      userActivityTimeout,
      userActivityThrottler,
      startCounter,
      signout,
      counter,
      modalShow,
      addSession,
      stopSession,
      idleSecondsCounter,
      exausCustomer,
      rememberMe,
    }
  },
})
