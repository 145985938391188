

export default () => {
    if (process.server) {
        return;
    }

    const detect = () =>
    'from' in Array &&
    function () {
      try {
        Array.from({ length: -Infinity });
        return true;
      } catch (e) {
        return false;
      }
    }();

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "core-js/es/array/from"
        ], function (require) {
            const polyfill = require("core-js/es/array/from");

            resolve();
        });
    });
}