import { ICellRendererComp, ICellRendererParams } from '@ag-grid-enterprise/all-modules'
import { ComponentInstance } from '@vue/composition-api'
import is from 'is_js'

export class CellRendererDefault implements ICellRendererComp {
  eGui: HTMLElement = document.createElement('div')

  isSelectCell (params: ICellRendererParams) {
    const isEditable = typeof params.colDef.editable === 'function' && params.colDef.editable(params)
    const isListOfChoicesCell = params.colDef.cellEditorParams && (params.colDef.cellEditorParams.isAsyncOptions || params.colDef.cellEditorParams.listOfChoices)
    const isEntry = params.data && params.data.path && params.data.path.length > 1
    const isAnomalyCol = (params.context.app as ComponentInstance).$accessor.dashboard.meta?.anomalyColumns.includes(params.colDef.colId || '')
    return isListOfChoicesCell && !(isEntry && isAnomalyCol) && isEditable
  }

  markText (params: ICellRendererParams) {
    let value: string = ''

    if ((params.value || params.value === 0)) {
      if (params.api.isQuickFilterPresent() && is.not.ie()) {
        // @ts-ignore TODO: Find a better way to get quickfilter
        const searchTerms = params.api.filterManager.quickFilter
        const searchRegex = new RegExp(`(${searchTerms})`, 'gi')

        value = params.valueFormatted
          .toString()
          .replace(searchRegex, '<span class="text-white bg-blue-500">$1</span>')
      } else {
        value = params.valueFormatted
      }
    }

    return value
  }

  updateDom (params: ICellRendererParams) {
    this.eGui.className = 'h-100 d-flex align-items-center'

    const template = []
    template.push(`<div class="w-100 ag-header-group-text" title="${params.valueFormatted}">`)

    if (params.value && params.colDef.type === 'LINK') {
      template.push(`<a href="${params.value}" target=“_blank”><i class="fas fa-external-link-alt mr-1"></i></a>`)
    }

    template.push(this.markText(params))
    template.push('</div>')

    if (this.isSelectCell(params)) {
      template.push('<a class="enable-edit w-4 text-center"><i class="fas fa-angle-down text-secondary ml-auto"></i></a>')
    }

    this.eGui.innerHTML = template.join('')

    const editCaret: HTMLElement | null = this.eGui.querySelector('.enable-edit')
    if (editCaret && (params.node.rowIndex !== null || params.node.rowIndex !== undefined)) {
      editCaret.onclick = () => params.api.startEditingCell({
        rowIndex: params.node.rowIndex || 0,
        colKey: params.column,
      })
    }
  }

  init (params: ICellRendererParams) {
    this.updateDom(params)

    if (is.not.ie() && params.api) {
      params.api.addEventListener('modelUpdated', () => this.updateDom(params))
    }
  }

  refresh () {
    return true
  }

  getGui () {
    return this.eGui
  }
}
