










































import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'

export default defineComponent({
  name: 'FormulateInputSelect',
  mixins: [FormulateInputMixin],
  setup (props, ctx) {
    const state = reactive({
      options: {},
      isFreeText: false,
      loading: false,
    })
    const routePath: string = ctx.root.$route.path
    let tableName: string

    if (routePath.includes('duplicate-suppliers')) {
      tableName = 'suppliersduplicate'
    } else if (routePath.includes('suppliers')) {
      tableName = 'mainsuppliers'
    } else if (routePath.includes('free-anomaly')) {
      tableName = 'freeanomaly'
    } else {
      tableName = 'dashboard'
    }

    ctx.root.$abbd.dataTable.listofchoices({
      uriParams: {
        projectId: ctx.root.$route.params.projectId,
        tableName,
        columnName: ctx.parent?.context.attributes.col.name ? ctx.parent?.context.attributes.col.name : ctx.parent?.context.attributes.columnName,
        viewName: ctx.root.$accessor.dashboard.prefilteredView,
      },
    })
      .then((res) => {
        // Activate free text typing
        state.isFreeText = res.data.editable
        state.loading = false

        const formModel = ctx.parent?.getFormValues()
        let resValueKey: string
        if (res.data.filterWithColumn && formModel[res.data.filterWithColumn]) {
          resValueKey = formModel[res.data.filterWithColumn]
        } else {
          resValueKey = Object.keys(res.data.values)[0]
        }

        if (res.data.values[resValueKey]) {
          state.options = res.data.values[resValueKey]
            .map((value: any) => ({ id: value, label: value, value }))
        }
      })

    return {
      ...toRefs(state),
    }
  },
})
