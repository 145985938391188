import Vue from 'vue'
import { actionTree } from 'typed-vuex'
import { getColGroupDefs, Rows } from '@/components/pages/resultats/helpers'
import { getColGroupDefsFromDesign } from '@/components/pages/resultats/colDefFromDesign'
import groupBy from 'lodash/groupBy'
import state from './state'
import mutations from './mutations'
import getters from './getters'

const actions = actionTree({ state, mutations, getters }, {
  getProjectData ({ commit }, uriParams: {projectId: string, prefilteredView: string}) {
    return Vue.$abbd.projects.dashboard.meta({ uriParams: { projectId: uriParams.projectId }, params: uriParams.prefilteredView && uriParams.prefilteredView !== '' ? { prefilteredView: uriParams.prefilteredView } : {} })
      .then(async (dashboardMeta) => {
        const anomalyCardDesignByGroup = groupBy(dashboardMeta.data.anomalyCardDesign, (design: { group: any }) => design.group)
        const dashboardData = await Vue.$abbd.projects.dashboard.data.get({
          uriParams: { projectId: uriParams.projectId, viewName: '' },
          params: uriParams.prefilteredView && uriParams.prefilteredView !== ''
            ? { timestamp: dashboardMeta.data.viewMetaData.timestamp, prefilteredView: uriParams.prefilteredView }
            : { timestamp: dashboardMeta.data.viewMetaData.timestamp },
        })
        const columnDefsFromDesign = getColGroupDefsFromDesign(dashboardMeta.data)
        const columnDefs = getColGroupDefs(dashboardMeta.data)
        const data = new Rows(dashboardData.data.values, dashboardMeta.data).entries
        const footerSumCols = dashboardMeta.data.viewMetaData.columns
          .filter((col: any) => col.footerStyle === 'SUM')
          .map((col: any) => col.name)

        commit('SET_ANOMALY_ID_COLUMN', dashboardMeta.data.anomalyIdColumn)
        commit('SET_COLUMN_DEFS', columnDefs)
        commit('SET_COLUMN_DEFS_FROM_DESIGN', columnDefsFromDesign)
        commit('SET_META', dashboardMeta.data)
        commit('SET_DATA', data)
        commit('SET_VIEW_NAME', dashboardMeta.data.viewName)
        commit('SET_FOOTER_SUM_COLS', footerSumCols)
        commit('SET_PREFILTERED_VIEW', uriParams.prefilteredView)
        commit('SET_ANOAMLY_CARD_DESIGN', anomalyCardDesignByGroup)
        return {
          columnDefs,
          data,
        }
      })
  },
  setModal ({ commit }, modalConfig) {
    commit('SET_MODAL_CONFIG', modalConfig)
  },
})

export default actions
