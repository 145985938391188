import { NuxtAppOptions } from '@nuxt/types'
import { mutationTree } from 'typed-vuex'
import state, { EmptyUser } from './state'

const mutations = mutationTree(state, {
  UPDATE_AUTH (this: NuxtAppOptions, state, payload): void {
    this.$cookies.set('access_token', payload.accessToken)
    state.user.refreshToken = payload.refreshToken
    state.user.accessToken = payload.accessToken
    state.user.role = payload.role
    state.user.roleType = payload.roleType
    state.user.lastLogin = payload.lastLogin
  },
  KILL_AUTH (this: NuxtAppOptions, state): void {
    this.$cookies.remove('access_token')
    state.user.refreshToken = ''
    state.user.accessToken = ''
    state.user.role = ''
    state.user.roleType = ''
    state.user.lastLogin = ''
  },
  UPDATE_USER (state, payload): void {
    state.user = Object.assign(state.user, payload)
  },
  LOGOUT (state): void {
    state.user = new EmptyUser()
  },
  SAVE_USER_SETTINGS (state, settings) {
    state.userSettings[state.user.mail] = {
      ...state.userSettings[state.user.mail],
      ...settings,
    }
    const newUserSettings = { ...state.userSettings }
    state.userSettings = newUserSettings
  },
  DELETE_USER_SETTING (state, settingId) {
    const newUserSettings = { ...state.userSettings }
    delete newUserSettings[state.user.mail][settingId]
    state.userSettings = newUserSettings
  },
})

export default mutations
