


































































import { defineComponent, inject, PropType, reactive, Ref, computed } from '@vue/composition-api'
import { GridApi, GridOptions } from '@ag-grid-enterprise/all-modules'
import useModal from '@/composables/pages/resultats/vue-globale/use-modal'
import useModalsLoadingStatus from '@/composables/pages/resultats/vue-globale/use-modals-loading-status'
import { Formfield } from '@@/types'
import useAgGridUpdateCells from '@/composables/pages/resultats/vue-globale/use-ag-grid-update-cell'
import useSupplierUpdateCells from '@/composables/pages/resultats/supplier/use-supplier-update-cell'
import useDuplicateSupplierUpdateCells from '@/composables/pages/resultats/duplicate-suppliers/use-duplicate-supplier-update-cell'
import useFreeAnomalyUpdateCells from '@/composables/pages/resultats/free-anomaly/use-free-anomaly-update-cell'
import { FormulateInput } from '@braid/vue-formulate'

export default defineComponent({
  name: 'ModalMultipleEdit',
  props: {
    gridApi: {
      type: Object as PropType<GridApi>,
      required: true,
    },
  },
  setup (props, ctx) {
    const gridOptions = inject('gridOptions') as Ref<GridOptions>
    const { modalConfig, setModal, modalDefaultConfig, isModalOpen } = useModal({ ctx, modalName: 'ModalMultipleEdit' })
    const { isLoading, submitButtonStatus, preventClosingWhileLoading } = useModalsLoadingStatus({
      ctx,
      submitBtnI18nKey: 'resultats.global.modals.multipleEdit.form.okBtn',
    })
    let updateCellsFunction
    let updateMutipleFileFunctoin: any
    if (ctx.root.$route.path.includes('duplicate-suppliers')) {
      updateCellsFunction = useDuplicateSupplierUpdateCells({ ctx, gridOptions })
      updateMutipleFileFunctoin = useAgGridUpdateCells({ ctx, gridOptions })
    } else if (ctx.root.$route.path.includes('suppliers')) {
      updateCellsFunction = useSupplierUpdateCells({ ctx, gridOptions })
      updateMutipleFileFunctoin = useAgGridUpdateCells({ ctx, gridOptions })
    } else if (ctx.root.$route.path.includes('free-anomaly')) {
      updateCellsFunction = useFreeAnomalyUpdateCells({ ctx, gridOptions })
      updateMutipleFileFunctoin = useFreeAnomalyUpdateCells({ ctx, gridOptions })
    } else {
      updateCellsFunction = useAgGridUpdateCells({ ctx, gridOptions })
      updateMutipleFileFunctoin = useAgGridUpdateCells({ ctx, gridOptions })
    }

    const { updateMutipleCellsAndRows } = updateCellsFunction
    const { updateMutipleFile } = updateMutipleFileFunctoin
    const form = reactive({
      newValue: modalConfig.value.data.newValue,
      rangesToUpdate: modalConfig.value.data.rangesToUpdate,
      typeInput: modalConfig.value.data.typeInput,
      isListOfChoicesCell: modalConfig.value.data.isListOfChoicesCell,
      col: modalConfig.value.data.col,
    })
    const isDashboard = computed(() => ctx.root.$route.path.includes('dashboard'))
    const component = computed(() => getInputFromCol(form.col))
    const getInputFromCol = (col: any) => {
      const defaultInput: FormulateInput = {
        type: 'text',
        label: col.label,
        name: col.name,
        col,
      }

      const inputDefs: { [key: string]: FormulateInput } = {
        STRING: { ...defaultInput },
        LINK: { ...defaultInput },
        INTEGER: {
          ...defaultInput,
          type: 'maskMultipleEdit',
          maskOptions: { numeral: true, numeralDecimalMark: ',', delimiter: ' ' },
        },
        REAL: {
          ...defaultInput,
          type: 'maskMultipleEdit',
          maskOptions: { numeral: true, numeralDecimalMark: ',', delimiter: ' ' },
        },
        BOOLEAN: {
          ...defaultInput,
          type: 'select',
          options: [
            {
              id: 'null',
              label: ctx.root.$t('common.agGridCellEditors.boolean.null'),
              value: null,
            },
            {
              id: 'null',
              label: ctx.root.$t('common.agGridCellEditors.boolean.true'),
              value: true,
            },
            {
              id: 'null',
              label: ctx.root.$t('common.agGridCellEditors.boolean.false'),
              value: false,
            },
          ],
        },
        FILE: {
          ...defaultInput,
          type: 'fileDashboard',
        },
        AMOUNT: {
          ...defaultInput,
          type: 'maskMultipleEdit',
          maskOptions: { numeral: true, numeralDecimalMark: ',', delimiter: ' ', suffix: '€' },
        },
        PERCENT: {
          ...defaultInput,
          type: 'maskMultipleEdit',
          maskOptions: { numeral: true, numeralDecimalMark: ',', delimiter: '.', suffix: '%' },
        },
        DATE: {
          ...defaultInput,
          type: 'date',
          maskOptions: { date: true, delimiter: '/', datePattern: '' },
        },
        DATE_TIME: {
          ...defaultInput,
          type: 'date',
          maskOptions: { date: true, delimiter: '/', datePattern: '' },
        },
      }

      if (form.isListOfChoicesCell) {
        return {
          ...defaultInput,
          type: 'dynSelect',
          columnName: col.colId,
        }
      } else {
        return inputDefs[col.type] ? inputDefs[col.type] : inputDefs.STRING
      }
    }

    const formFields = reactive<Formfield[]>([
      {
        component: form.isListOfChoicesCell ? 'FormulateInput' : 'FormInput',
        model: 'newValue',
        labelI18nKey: 'resultats.global.modals.multipleEdit.form.newValue',
        validation: {
          required: true,
        },
        group: {
          id: 'new-value-group',
          labelFor: 'new-value',
        },
        input: {
          id: 'new-value',
          autofocus: true,
        },
        provider: {
          immediate: true,
        },
      },
    ])
    const onSubmit = async () => {
      isLoading.value = true
      await editSelectedCells()
      isLoading.value = false
      setModal({})
      props.gridApi.clearRangeSelection()
    }
    const selectedRows = computed(() => {
      const model: any = props.gridApi.getModel()
      const rowNodes: any[] = []
      form.rangesToUpdate.forEach((range: any) => {
        for (let i = range.startRowIndex; i <= range.endRowIndex; i++) {
          const rowId: any = model.rowsToDisplay[i]?.id
          const rowNode = props.gridApi!.getRowNode(rowId)!
          // on enlève les lignes avec doublons
          if (rowNode.selectable && !rowNode.key?.includes('_')) {
            rowNodes.push(rowNode)
          }
        }
      })
      const remindList = rowNodes.reduce((total, rowNode) => {
        const anomalyType = rowNode.data['Type d\'Anomalie'] || 'N/A'

        total[anomalyType] = total[anomalyType] || {
          qty: 0,
          totalAmount: 0,
        }

        total[anomalyType].qty = total[anomalyType].qty + 1

        if (rowNode.data['Amount to recover']) {
          total[anomalyType].totalAmount = total[anomalyType].totalAmount + rowNode.data['Amount to recover']
        }

        return total
      }, {})
      return remindList
    })
    const editSelectedCells = async () => {
      const model: any = props.gridApi.getModel()
      const rowNodes: any[] = []
      form.rangesToUpdate.forEach((range: any) => {
        for (let i = range.startRowIndex; i <= range.endRowIndex; i++) {
          const rowId: any = model.rowsToDisplay[i]?.id
          const rowNode = props.gridApi!.getRowNode(rowId)!
          if (rowNode.selectable) {
            rowNodes.push(rowNode)
          }
        }
      })

      const rowsValues = rowNodes
        .map((row: any) => ({
          ...row.data,
          [form.rangesToUpdate[0].column]: form.newValue,
        }))
        .map((row) => {
          const { path, entryId, ...rowData } = row
          return rowData
        })
      if (form.typeInput === 'FILE') {
        if (form.newValue) {
          await updateMutipleFile(
            form.newValue,
            rowsValues,
            form.rangesToUpdate[0].column,
          )
        }
      } else {
        await updateMutipleCellsAndRows({
          rowsValues,
          columnsToUpdate: [
            form.rangesToUpdate[0].column,
          ],
        })
      }
    }

    return {
      modalConfig,
      setModal,
      modalDefaultConfig,
      isModalOpen,
      isLoading,
      submitButtonStatus,
      preventClosingWhileLoading,
      onSubmit,
      form,
      formFields,
      editSelectedCells,
      getInputFromCol,
      component,
      selectedRows,
      isDashboard,
    }
  },
})
