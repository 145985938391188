import { IHeaderGroupParams, IHeaderGroup } from '@ag-grid-enterprise/all-modules'

export class GroupHeaderComp implements IHeaderGroup {
  eGui: HTMLElement = document.createElement('div')
  opened: boolean = false
  params!: IHeaderGroupParams

  getHiddenChildren (params: IHeaderGroupParams) {
    return params.columnGroup
      .getOriginalColumnGroup()
      .getLeafColumns()
      .filter(col => col.getColDef().hide)
  }

  isOpened (params: IHeaderGroupParams) {
    const leafCols = params.columnGroup
      .getOriginalColumnGroup()
      .getLeafColumns()

    const visibleCols = leafCols
      .filter(col => col.isVisible())

    return leafCols.length === visibleCols.length
  }

  updateDom (params: IHeaderGroupParams) {
    this.eGui.className = 'd-flex flex-direction-row align-items-center flex-fill ag-header-group-cell-label'
    this.eGui.setAttribute('data-test', `colgroup-${params.displayName}`)
    this.eGui.setAttribute('ref', 'agContainer')
    this.eGui.setAttribute('role', 'presentation')

    const expandBtn = `
      <button class="pr-0 bg-transparent" data-test="expand-colgroup-btn">
        <i class="fas ${this.isOpened(params) ? 'fa-minus-square' : 'fa-plus-square'} text-blue-600"></i>
      </button>
    `

    const hiddenChildrenAndOpened = this.getHiddenChildren(params).length && this.isOpened(params)

    this.eGui.innerHTML = `
      <span ref="agLabel" class="ag-header-group-text" role="columnheader" title="${params.displayName}">${params.displayName}</span>
      <div class="ml-auto">
        ${!this.isOpened(params) || hiddenChildrenAndOpened ? expandBtn : ''}
      </div>
    `

    const toggleBtn: HTMLElement | null = this.eGui.querySelector('[data-test="expand-colgroup-btn"]')

    if (toggleBtn) {
      toggleBtn.onclick = () => this.toggleChildrenVisibility(params)
    }
  }

  init (params: IHeaderGroupParams) {
    this.params = params

    this.updateDom(params)

    params.api.addEventListener('columnVisible', () => {
      this.updateDom(params)
    })
  }

  refresh () {
    return true
  }

  getGui () {
    return this.eGui
  }

  toggleChildrenVisibility (params: IHeaderGroupParams) {
    const leafCols = params.columnGroup.getOriginalColumnGroup().getLeafColumns()
    const hiddenCols = this.getHiddenChildren(params)
    const childrenCols = hiddenCols.length ? hiddenCols : leafCols
    const colToToggle = childrenCols.map(child => child.getId())

    params.columnApi.setColumnsVisible(colToToggle, !this.isOpened(params))
  }
}
