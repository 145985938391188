import { ref, SetupContext, computed } from '@vue/composition-api'

interface Options {
  ctx: SetupContext
  submitBtnI18nKey: string
  loadingStatusI18nKey?: string
}

export default function useModalsLoadingStatus ({ ctx, submitBtnI18nKey, loadingStatusI18nKey = 'common.btnLoadingStatus' }: Options) {
  const isLoading = ref<boolean>(false)

  const submitButtonStatus = computed(() => isLoading.value
    ? `<i class="fas fa-spinner fa-spin"></i> ${ctx.root.$t(loadingStatusI18nKey)}`
    : ctx.root.$t(submitBtnI18nKey))

  const preventClosingWhileLoading = (e: Event) => {
    if (isLoading.value) {
      e.preventDefault()
    }
  }

  return {
    isLoading,
    submitButtonStatus,
    preventClosingWhileLoading,
  }
}
