import sfrAllMedia from '../../../_154a98c0-da73-41cb-895c-5494a93d88d1/dashboard/synthesis/metadata.json'
import debugProd from '../../../_debug-prod/dashboard/synthesis/metadata.json'

// An id (not provided by the API) is added in each file to match the right project (then deleted before sending to the frontend)
sfrAllMedia.projectId = '_154a98c0-da73-41cb-895c-5494a93d88d1'
debugProd.projectId = '_debug-prod'
export default [
  sfrAllMedia,
  debugProd,
]
