import { SetupContext } from '@vue/composition-api'
import { RowNode } from '@ag-grid-enterprise/all-modules'

interface Options {
  ctx: SetupContext
  gridOptions: any
}

export default function useDragDropObjects ({ ctx, gridOptions }: Options) {
  /**
   * Check if destination node is a file type
   * @param destinationNode
   */
  const isDestiFile = (destinationNode?: RowNode) => {
    if (!destinationNode || (destinationNode && !destinationNode.data)) return false
    return destinationNode.data.type === 'file'
  }

  /**
   * Check if at leat one source node is the destination node
   * @param sourceNodes
   * @param destinationNode
   */
  const isDestiSrc = (sourceNodes: RowNode[], destinationNode?: RowNode): boolean => {
    if (!destinationNode) return false
    return Boolean(sourceNodes.find(node => node.id === destinationNode.id))
  }

  /**
   * Check if at least one source node if parent of the destination node
   * @param sourceNodes
   * @param destinationNode
   */
  const isDestChildOfSrc = (sourceNodes: RowNode[], destinationNode?: RowNode): boolean => {
    if (!destinationNode) return false

    const match: RowNode | undefined = sourceNodes
      .reduce((all: RowNode[], node) => [...all, ...node.allLeafChildren], [])
      .find(node => destinationNode.id === node.id)

    return Boolean(match)
  }

  /**
   * Check if at least one node already exists in destination node
   * @param sourceNodes
   * @param destinationNode
   */
  const alreadyExists = (sourceNodes: RowNode[], destinationNode?: RowNode): boolean => {
    if (!destinationNode) return false

    const match: RowNode | undefined = sourceNodes
      .reduce((all: RowNode[], node) => [...all, ...node.allLeafChildren], [])
      .find(node => destinationNode.childrenAfterGroup?.find(child => child.key === node.key))

    return Boolean(match)
  }

  /**
   * Check if at least one source node is the first level children of the destination
   * @param sourceNodes
   * @param destinationNode
   */
  const isSrcAlreadyFirstChildOfDesti = (sourceNodes: RowNode[], destinationNode?: RowNode): boolean => {
    const destiId = destinationNode ? destinationNode.id : 'ROOT_NODE_ID'
    return Boolean(sourceNodes.find(node => node.parent && node.parent.id === destiId))
  }

  /**
   * Check if the sources nodes are movable to the destination
   * @param sourceNodes
   * @param destinationNode
   */
  const isMovable = (sourceNodes: RowNode[], destinationNode?: RowNode) => {
    return !isDestiFile(destinationNode) &&
      !isDestiSrc(sourceNodes, destinationNode) &&
      !isDestChildOfSrc(sourceNodes, destinationNode) &&
      !isSrcAlreadyFirstChildOfDesti(sourceNodes, destinationNode) &&
      !alreadyExists(sourceNodes, destinationNode)
  }

  /**
   * Generate new node names from sources and destination nodes
   * @param sourceNodes
   * @param destinationNode
   */
  const getRenamedNodes = (sourceNodes: RowNode[], destinationNode?: RowNode): RowNode[] => {
    return sourceNodes.reduce((renamedNodes: any[], node) => {
      const baseNewName = destinationNode ? destinationNode.id : `${node.id?.split('/')[0]}/`
      const newName = `${baseNewName}${node.key}${node.data.type === 'folder' ? '/' : ''}`
      // $$ using replace() it will replace to single $ ==> hack replace(/\$/g, '$$$$')
      const renamedSet = node.allLeafChildren.map(leaf => ({
        oldKey: leaf.id,
        newKey: `${leaf.id?.replace(node.id as string, newName.replace(/\$/g, '$$$$'))}`,
      }))
      return [...renamedNodes, ...renamedSet]
    }, [])
  }

  /**
   * opy and delete on s3
   * @param event
   */
  const s3CopyAndDelete = async (sourceNodes: RowNode[], nodesToCopyOrRename: any[]) => {
    // On recharge les credentials aws
    const selectedProject = ctx.root.$store.getters['projects/selectedProject']
    await ctx.root.$store.dispatch('files/getCredentials', selectedProject.id)
    const renamedNodes = nodesToCopyOrRename
      .map(node => ctx.root.$store.dispatch('files/browser/renameObjects', node))

    if (gridOptions.api) {
      gridOptions.api.showLoadingOverlay()
    }

    await Promise.all(renamedNodes)

    const toDelete = sourceNodes
      .reduce((all: RowNode[], node) => [...all, ...node.allLeafChildren], [])
      .map(node => node.id)

    await ctx.root.$store.dispatch('files/browser/deleteObjects', toDelete)

    if (gridOptions.api) {
      gridOptions.api?.redrawRows()
      gridOptions.api.hideOverlay()
    }

    return Promise.resolve(true)
  }

  /**
   * On drag end event of the filebrowser grid
   * @param event
   */
  const renameAndMoveObjects = async (sourceNodes: RowNode[], destinationNode: RowNode | undefined) => {
    const nodesToCopyOrRename = getRenamedNodes(sourceNodes, destinationNode)
    await s3CopyAndDelete(sourceNodes, nodesToCopyOrRename)
  }

  return {
    isDestiFile,
    isDestiSrc,
    isDestChildOfSrc,
    alreadyExists,
    isSrcAlreadyFirstChildOfDesti,
    isMovable,
    getRenamedNodes,
    s3CopyAndDelete,
    renameAndMoveObjects,
  }
}
