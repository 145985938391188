import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'

export interface CellUpdate {
  updatedRows: {
    values: { [key: string]: any }[]
  }
  keyColumns: string[]
}

export interface Payload {
  rowValues: { [key: string]: any }[]
  columnsToUpdate: string[]
}

export interface DashboardData {
  values: { [key: string]: any }[]
}

export interface UriParams {
  projectId: string,
  viewName: string
}

type Args = [
  EndpointsOptions<UriParams>?
]

type PostArgs = [
  Payload,
  EndpointsOptions<UriParams>?
]

export default (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>(({ projectId }) => `v1/wexaus/projects/${projectId}/dashboard/data`)
  const endpointUpdate = new Endpoint<UriParams>(({ projectId, viewName }) => `v1/wexaus/projects/${projectId}/dashboard/data/update?prefilteredView=${viewName}`)

  return {
    get: (...args: Args) => endpoint.get<DashboardData>(...args),
    put: (...args: PostArgs) => endpointUpdate.put<DashboardData>(...args),
  }
}
