import { User } from '@/plugins/abbd-api/sdk/types/api/User.d'

export interface UserState {
  user: User
  userSettings: {
    [email: string]: {
      [settingId: string]: any
    }
  }
}

export class EmptyUser implements User {
  fullName = ''
  login = ''
  mail = ''
  company = ''
  adminWExaus = false
  refreshToken = ''
  accessToken = ''
  role = ''
  optionalFeatures: string[] = []
  roleType = ''
  lastLogin = ''
}

const state = (): UserState => ({
  user: new EmptyUser(),
  userSettings: {},
})

export default state
