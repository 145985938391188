import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules'
import dayjs from 'dayjs'
import { fixDateTimezone } from '@/helpers/fix-date-timezone'

export const valueFormatterDefault = (params: ValueFormatterParams) => {
  if (params.value === null) {
    return ''
  }
  if (params.value === 0) {
    return String(0)
  }

  return params.value
}

export const valueFormatterPercent = (params: ValueFormatterParams) => {
  if (params.context.app.$route.name.includes('synthese')) {
    return params.value || params.value === 0 ? `${params.context.app.$n((params.value) * 100, { minimumFractionDigits: 0, maximumFractionDigits: 0 })} %` : ''
  }
  return params.value || params.value === 0 ? `${params.context.app.$n((params.value * 100), { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %` : ''
}

export const valueFormatterNumber = (params: ValueFormatterParams) => {
  if (params.context.app.$route.name.includes('synthese')) {
    return params.value || params.value === 0 ? params.context.app.$n(Math.round(params.value), { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''
  }
  return params.value || params.value === 0 ? params.context.app.$n(params.value, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''
}

export const valueFormatterAmount = (params: ValueFormatterParams) => {
  if (params.context.app.$route.name.includes('synthese')) {
    return params.value || params.value === 0 ? params.context.app.$n(Math.round(params.value), 'currency', params.context.app.$i18n.locale, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''
  }
  return params.value || params.value === 0 ? params.context.app.$n(params.value, 'currency', params.context.app.$i18n.locale) : ''
}

export const valueFormatterDate = (params: ValueFormatterParams) => {
  if (params.value) {
    const format = params.context.app.$t('common.dateFormat')
    return dayjs(fixDateTimezone(params.value)).format(format)
  } else {
    return ''
  }
}

export const valueFormatterDateTime = (params: ValueFormatterParams) => {
  if (params.value) {
    const format = params.context.app.$t('common.dateTimeFormat')
    return dayjs(fixDateTimezone(params.value)).format(format)
  } else {
    return ''
  }
}

export const valueFormatterBoolean = (params: ValueFormatterParams) => {
  if (params.value === null) {
    return 'En attente'
  }
  if (params.value === 0) {
    return String(0)
  }

  return params.value === 'true' ? 'Oui' : 'Non'
}
