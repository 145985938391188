import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'

export type ProjectOptionalFeatures = 'synthesis_views_access' | 'dashboard'
export interface Project {
  id: string
  label: string
  country: string
  dbName: string
  dbSchema: string
  moduleFlow: string
  softwareVersion: string
  statusCode: number
  optionalFeatures: ProjectOptionalFeatures[]
  ownerLogin: string
}

export interface UriParams {}

type Args = [
  EndpointsOptions<UriParams>?
]

export default (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>('v1/wexaus/projects')

  return (...args: Args) => endpoint.get<Project[]>(...args)
}
