import { ascNumberFilterComparator } from '@/components/common/ag-grid/column-types'
import { ColDef, ColGroupDef } from '@ag-grid-enterprise/all-modules'
import { DataTableApiEndpoint } from '@/plugins/abbd-api/sdk/endpoints/dataTable/metadata'
import { getTextColorFromBgColor } from '@/helpers/getTextColorFromBgColor'
import { getCellClassFromCol } from '@/helpers/getCellClassFromCol'
import CellEditorSelect from '@/components/common/ag-grid/cell-editors/Select.vue'
import { GroupHeaderComp } from '@/components/pages/resultats/common/GroupHeaderComp'
import { ValideIcon } from '@/components/common/ag-grid/cell-renderers/ValideIcon'
import { MovementsLink } from '@/components/common/ag-grid/cell-renderers/MovementsLink'

export const useAgGridHelpers = () => {
  const apiDataTableToAgColDef = (
    metadata: DataTableApiEndpoint.DataTableMetadata,
    groupName: string,
  ) => {
    return metadata.viewMetaData.columns
      .filter(col => groupName === (col.groupLabel && col.groupLabel !== null ? col.groupLabel : col.group))
      .filter(col => !col.hidden)
      .map((col) => {
        const colDef: ColDef = {
          field: col.name,
          colId: col.name,
          headerTooltip: col.label,
          headerName: col.label,
          type: col.type,
          hide: !col.displayByDefault,
          pinned: col.frozen ? 'left' : null,
          headerClass: col.editable ? 'editable-header-icon' : '',
          toolPanelClass: col.editable ? 'toolpanel-col-editable-icon' : '',
          cellClass: getCellClassFromCol(col),
          cellStyle: (params) => {
            if (!col.color || params.node.rowPinned === 'bottom') return {}

            return {
              background: col.color,
              color: getTextColorFromBgColor(col.color, 'white', 'black'),
            }
          },
          editable: (params) => {
            let cantEdit = col.editable

            const isLocked = params.colDef.field !== 'Row Locked' && params.data['Row Locked']
            const isFooterCell = params.node.rowPinned === 'bottom'

            if (isLocked || isFooterCell) {
              cantEdit = false
            }

            return cantEdit
          },
        }

        // Dashboard | anomaly validation icon
        if (
          metadata.validatedColumn &&
          metadata.validatedColumn === col.name
        ) {
          colDef.cellRenderer = ValideIcon
        }

        // Dashboard | Movements link cell renderer
        if (
          metadata.linkMovementColumn &&
          metadata.linkMovementColumn === col.name
        ) {
          colDef.width = 100
          colDef.cellRenderer = MovementsLink
          colDef.filterParams = {
            comparator: ascNumberFilterComparator,
          }
          colDef.cellRendererParams = {
            metadata,
          }
        }

        // List of choices
        if (col.isListOfChoices) {
          colDef.width = 150
          colDef.cellEditorFramework = CellEditorSelect
          colDef.cellEditorParams = {
            isAsyncOptions: true,
          }
        }

        return colDef
      })
  }

  const getColGroupDefs = (metadata: DataTableApiEndpoint.DataTableMetadata): ColGroupDef[] => {
    const groupHeaderNames = metadata.viewMetaData.columns
      .map(col => col.groupLabel && col.groupLabel !== null ? col.groupLabel : col.group)
      .reduce((groupNameList: string[], groupName) => {
        if (!groupNameList.includes(groupName)) {
          groupNameList.push(groupName)
        }
        return groupNameList
      }, [])
    // Gen groups from group header names
    const groupDef: ColGroupDef[] = groupHeaderNames
      .map(headerName => ({
        headerName,
        headerGroupComponent: GroupHeaderComp,
        children: apiDataTableToAgColDef(metadata, headerName),
      }))
    return groupDef
  }

  return { apiDataTableToAgColDef, getColGroupDefs }
}
