import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'
import { ListOfChoices } from '../../../../../../../types/api/ListOfChoices'

export interface UriParams {
  projectId: string
  columnName: string
  viewName: string
}

type Args = [
  EndpointsOptions<UriParams>?
]

export default (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>(({ projectId, columnName, viewName }) => `v1/wexaus/projects/${projectId}/dashboard/data/listofchoices/${columnName}?prefilteredView=${viewName}`)

  return (...args: Args) => endpoint.get<ListOfChoices>(...args)
}
