

































































































































































































































import { defineComponent, ref, computed, watch } from '@vue/composition-api'
import filesize from 'filesize'
import promiseAllSequential from 'promise-all-sequential'
import dragDrop from 'drag-drop'
import is from 'is_js'
import { UploadQueuedFile } from '@@/types'
import useUploadQueue from '@/composables/pages/transferts/use-upload-queue'
import { Project } from '@/plugins/abbd-api/sdk/endpoints/v1/wexaus/projects'
import useNotifyAction from '@/composables/use-notify-action'

interface Props {
  basePrefix: string
}

export default defineComponent<Props>({
  name: 'FileUploader',
  props: {
    basePrefix: {
      default: '',
    },
  },
  directives: {
    dragDrop: {
      bind (el, binding, { context }: any) {
        dragDrop(el, (files: File[]) => {
          context?.dropFiles(files)
        })
      },
    },
  },
  setup (props, ctx) {
    const fileList = ref<HTMLInputElement>()
    const filesToAdd : File[] = []
    const fileInput = ref<HTMLInputElement>()
    const isLoading = ref<boolean>(false)
    const isDraggingOverFileList = ref<boolean>(false)
    const disableUpload = ref<boolean>(false)
    const setModal = (payload: any) => ctx.root.$accessor.files.uploader.setModal(payload)
    const modalConfig = computed(() => {
      const uploader = ctx.root.$accessor.files.uploader
      return uploader?.modalConfig
    })
    const destinationFolder = computed(() => {
      const uploader = ctx.root.$accessor.files.uploader
      return uploader?.destinationFolder ? `${uploader.destinationFolder?.path?.join('/')}/` : ''
    })
    const {
      uploadQueue,
      removeFromQueue,
      clearQueue,
      addFiles,
    } = useUploadQueue({ ctx, fileInput })
    const selectedProject = computed<Project>(() => ctx.root.$store.getters ? ctx.root.$store.getters['projects/selectedProject'] : null)
    const cancelUpload = (key: string) => ctx.root.$store.dispatch('files/uploader/cancelUpload', key)

    const dropFiles = (files: File[], fileList: FileList) => {
      isDraggingOverFileList.value = false

      if (is.ie()) {
        files = Object.keys(fileList).map((fileIndex: any) => fileList[fileIndex])
      }

      if (files && files.length) {
        filesToAdd.push(...files)
        addFiles(files, destinationFolder.value !== '' ? destinationFolder.value : props.basePrefix)
        // on active le bouton d'envoie
        disableUpload.value = false
      }
    }

    const addFilesToqueue = (files: FileList) => {
      filesToAdd.push(...Object.keys(files).map((key: any) => files[key]))
      if (files.length) {
        addFiles(Object.keys(files).map((key: any) => files[key]), destinationFolder.value !== '' ? destinationFolder.value : props.basePrefix)
        // on active le bouton d'envoie
        disableUpload.value = false
      }
    }

    const uploadFiles = async () => {
      isLoading.value = true
      // On recharge les credentials aws
      await ctx.root.$store.dispatch('files/getCredentials', selectedProject.value?.id)
      const allFilesProm = uploadQueue.value
        .filter(file => file.status === 'queued')
        .map((file: UploadQueuedFile) => () => ctx.root.$store.dispatch('files/uploader/uploadObject', file))
      const fileResults = await promiseAllSequential(allFilesProm)
      // On desactive le bouton d'envoie
      disableUpload.value = true
      await slackNotify(fileResults)
      await prepareNotifyAction(fileResults)
      isLoading.value = false

      const errorList = fileResults.filter((file: any) => !['queued', 'success', 'canceled'].includes(file.status))
      if (!errorList.length) {
        ctx.emit('done')
      }
      ctx.root.$store.dispatch('files/browser/getObjects')

      ctx.root.$ga.event({
        eventCategory: 'file-uploader',
        eventAction: 'upload',
      })
    }

    const slackNotify = (files: UploadQueuedFile[]) => {
      const getStatusEmoji = (file: UploadQueuedFile) => file.status === 'success' ? '&#x2705;' : '&#10060;'
      const getFileErrMsg = (file: UploadQueuedFile) => file.status !== 'success' ? `: ${file.msg}` : ''

      const filteredFiles = files
        .filter((file: UploadQueuedFile) => file.msg !== 'Request aborted by user' && file.status !== 'alreadyExists')

      const fileNames = filteredFiles
        .map((file: UploadQueuedFile) => {
          return `<br>${getStatusEmoji(file)} ${file.Key} (${filesize(file.size)})${getFileErrMsg(file)}`
        })

      if (fileNames.length) {
        const amountToShow = 5
        const total = fileNames.length
        const remainingFiles = total - amountToShow

        // if there is a real error and less than 5 files, display the file in error
        const filesToShow = fileNames.slice(0, amountToShow)
        const fileErrorAmount = filteredFiles.filter(file => file.status === 'error').length

        let message = `${ctx.root.$tc('common.fileUploader.notification.subject', filesToShow.length, { files: filesToShow })}<br>`

        if (remainingFiles > 0) {
          message += `${ctx.root.$tc('common.fileUploader.notification.remainingFiles', remainingFiles, { count: remainingFiles })}<br>`
        }

        message += ctx.root.$tc('common.fileUploader.notification.total', total, { count: total })

        if (fileErrorAmount > 0) {
          message += '<br>'
          message += ctx.root.$tc('common.fileUploader.notification.fileErrorAmount', fileErrorAmount, { count: fileErrorAmount })
        }
        message += '<br>'

        return ctx.root.$store.dispatch('files/uploader/notify', message)
      } else {
        return false
      }
    }
    const { notifyAction } = useNotifyAction({ ctx })
    const prepareNotifyAction = async (files: UploadQueuedFile[]) => {
      const filteredFiles = files
        .filter((file: UploadQueuedFile) => file.msg !== 'Request aborted by user' && file.status !== 'alreadyExists')
      const fileNames = filteredFiles
        .map((file: UploadQueuedFile) => {
          return `${file.Key};`
        })
      const amountToShow = 5
      const total = filteredFiles.length
      let message = ''
      for (let i = 0; i < amountToShow; i++) {
        if (fileNames[i]) {
          message = message.concat(fileNames[i])
        }
      }
      if (filteredFiles.length !== 0) {
        await notifyAction({ action: 'fileTransfert' }, { args: [total, message] })
      }
    }
    const resetUpload = () => {
      ctx.root.$accessor.files.uploader.setDestinationFolder('')
      filesToAdd.splice(0, filesToAdd.length)
      clearQueue()
    }
    watch(() => destinationFolder.value, () => {
      clearQueue()
      if (filesToAdd && filesToAdd.length) {
        addFiles(filesToAdd, destinationFolder.value !== '' ? destinationFolder.value : props.basePrefix)
      }
    })

    resetUpload()
    return {
      fileList,
      fileInput,
      addFilesToqueue,
      isLoading,
      isDraggingOverFileList,
      uploadQueue,
      removeFromQueue,
      clearQueue,
      cancelUpload,
      filesize,
      dropFiles,
      addFiles,
      uploadFiles,
      slackNotify,
      notifyAction,
      prepareNotifyAction,
      selectedProject,
      resetUpload,
      setModal,
      filesToAdd,
      modalConfig,
      destinationFolder,
      disableUpload,
    }
  },
})
