

export default () => {
    if (process.server) {
        return;
    }

    const detect = () => 'get' in self.Reflect;

    if (detect()) {
        return;
    }

            const polyfill = require("core-js/es/reflect");
}