import { ResponseErrorInterceptor } from '../types/'

/**
 * Error interceptor: Refresh access token when expired
 * @param error
 */
export const refreshTokenInterceptor: ResponseErrorInterceptor = (options, axiosInstance, endpoints) => (error) => {
  const config = error.config
  const response = error.response
  const isRefreshTokenCall = error.config && error.config.url && error.config.url.includes(endpoints.auth.refreshToken.uri)

  if (response && response.status === 440 && config && !config.__retrying && !isRefreshTokenCall) {
    config.__retrying = true

    // Call refresh token endpoint
    return endpoints.auth.refreshToken.get()
      .then((result) => {
        // Dispatch store action to update user
        return options.updateAuthCallback(result.data)
          .then(() => {
            // Update Authorization header before retry
            config.headers.Authorization = `Bearer ${options.getAuthToken(config, endpoints)}`

            // Retry
            return axiosInstance(config)
          })
      })
  } else if (isRefreshTokenCall) {
    options.unauthorizedCallback()
    return Promise.reject(error)
  } else {
    return Promise.reject(error)
  }
}
