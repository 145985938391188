import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faAngleDown as freeFasFaAngleDown,
  faAngleLeft as freeFasFaAngleLeft,
  faAngleRight as freeFasFaAngleRight,
  faBan as freeFasFaBan,
  faCalendarDays as freeFasFaCalendarDays,
  faCheck as freeFasFaCheck,
  faChevronRight as freeFasFaChevronRight,
  faGear as freeFasFaGear,
  faDownload as freeFasFaDownload,
  faEllipsisH as freeFasFaEllipsisH,
  faFile as freeFasFaFile,
  faFileExcel as freeFasFaFileExcel,
  faFolder as freeFasFaFolder,
  faFolderPlus as freeFasFaFolderPlus,
  faHourglassHalf as freeFasFaHourglassHalf,
  faCircleInfo as freeFasFaCircleInfo,
  faLink as freeFasFaLink,
  faRectangleList as freeFasFaRectangleList,
  faMinusSquare as freeFasFaMinusSquare,
  faPen as freeFasFaPen,
  faPlus as freeFasFaPlus,
  faPlusSquare as freeFasFaPlusSquare,
  faMagnifyingGlass as freeFasFaMagnifyingGlass,
  faShare as freeFasFaShare,
  faRightFromBracket as freeFasFaRightFromBracket,
  faArrowDownAZ as freeFasFaArrowDownAZ,
  faArrowUpAZ as freeFasFaArrowUpAZ,
  faSpinner as freeFasFaSpinner,
  faTable as freeFasFaTable,
  faXmark as freeFasFaXmark,
  faTrashCan as freeFasFaTrashCan,
  faEye as freeFasFaEye,
  faFilterCircleXmark as freeFasFaFilterCircleXmark,
  faFileArrowUp as freeFasFaFileArrowUp
} from '@fortawesome/free-solid-svg-icons'

import {
  faMicrosoft as freeFabFaMicrosoft
} from '@fortawesome/free-brands-svg-icons'

library.add(
  freeFasFaAngleDown,
  freeFasFaAngleLeft,
  freeFasFaAngleRight,
  freeFasFaBan,
  freeFasFaCalendarDays,
  freeFasFaCheck,
  freeFasFaChevronRight,
  freeFasFaGear,
  freeFasFaDownload,
  freeFasFaEllipsisH,
  freeFasFaFile,
  freeFasFaFileExcel,
  freeFasFaFolder,
  freeFasFaFolderPlus,
  freeFasFaHourglassHalf,
  freeFasFaCircleInfo,
  freeFasFaLink,
  freeFasFaRectangleList,
  freeFasFaMinusSquare,
  freeFasFaPen,
  freeFasFaPlus,
  freeFasFaPlusSquare,
  freeFasFaMagnifyingGlass,
  freeFasFaShare,
  freeFasFaRightFromBracket,
  freeFasFaArrowDownAZ,
  freeFasFaArrowUpAZ,
  freeFasFaSpinner,
  freeFasFaTable,
  freeFasFaXmark,
  freeFasFaTrashCan,
  freeFasFaEye,
  freeFasFaFilterCircleXmark,
  freeFasFaFileArrowUp,
  freeFabFaMicrosoft
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
