import axios, { AxiosInstance } from 'axios'
import { Options } from './types/'
import { ieFixIntercpetor } from './interceptors/ie-fix'
import { authInterceptor } from './interceptors/auth'
import { authResponseInterceptor } from './interceptors/auth-response'
import { refreshTokenInterceptor } from './interceptors/refresh-token'
import { analyticsInterceptor } from './interceptors/analytics'
import { unauthorizedInterceptor } from './interceptors/unauthorized'
import { errorResponseInterceptor } from './interceptors/response-error-callback'
import getEndpoints from './endpoints'

export default (options: Options) => {
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: `${process.env.VUE_APP_SERVICE_HOST}services/`,
  })

  const endpoints = getEndpoints(axiosInstance)

  // Interceptors: requests
  axiosInstance.interceptors.request.use(ieFixIntercpetor(options, axiosInstance, endpoints))
  axiosInstance.interceptors.request.use(authInterceptor(options, axiosInstance, endpoints))

  // Interceptors: responses
  axiosInstance.interceptors.response.use(authResponseInterceptor(options, axiosInstance, endpoints))
  axiosInstance.interceptors.response.use(undefined, refreshTokenInterceptor(options, axiosInstance, endpoints))
  axiosInstance.interceptors.response.use(undefined, unauthorizedInterceptor(options, axiosInstance, endpoints))
  axiosInstance.interceptors.response.use(analyticsInterceptor(options, axiosInstance, endpoints))
  axiosInstance.interceptors.response.use(undefined, errorResponseInterceptor(options, axiosInstance, endpoints))

  return endpoints
}
