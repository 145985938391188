import { mutationTree } from 'typed-vuex'
import { UploadQueuedFile, UploadQueuedFileList } from '@@/types'
import state from './state'

const mutations = mutationTree(state, {
  ADD_TO_QUEUE (state, files: UploadQueuedFile[]) {
    const newFiles = files
      .filter((file: UploadQueuedFile) => !state.uploadQueue[file.Key])
      .reduce((finalObj: UploadQueuedFileList, file: UploadQueuedFile) => {
        finalObj[file.Key] = file
        return finalObj
      }, {})

    state.uploadQueue = {
      ...state.uploadQueue,
      ...newFiles,
    }
  },
  UPDATE_QUEUE (state, file: UploadQueuedFile) {
    state.uploadQueue = {
      ...state.uploadQueue,
      [file.Key]: file,
    }
  },
  REMOVE_FROM_QUEUE (state, file) {
    state.uploadQueue = Object.keys(state.uploadQueue)
      .filter((key: string) => key !== file.Key)
      .reduce((finalObj: UploadQueuedFileList, key: string) => {
        finalObj[key] = state.uploadQueue[key]
        return finalObj
      }, {})
  },
  CLEAR_QUEUE (state) {
    state.uploadQueue = {}
  },
  SET_MODAL_CONFIG (state, modalConfig) {
    state.modalConfig = modalConfig
  },
  SET_DESTINATION_FOLDER (state, destinationFolder) {
    state.destinationFolder = destinationFolder
  },
})

export default mutations
