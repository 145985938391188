
































import { Component, mixins } from 'nuxt-property-decorator'
import fieldMixin from './mixin'

// NOTE: Component Decorator
@Component({
  name: 'FormInput',
})

// NOTE: Component Class
export default class FormInput extends mixins(fieldMixin) {
}
