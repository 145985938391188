import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'
import { ListOfChoices } from '../../types/api/ListOfChoices'

export interface UriParams {
  projectId: string
  tableName: string
  columnName: string
  viewName: string
}

type Args = [
  EndpointsOptions<UriParams>?
]

export const dataTableListofchoices = (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>(({ projectId, tableName, columnName, viewName }) => {
    return `v1/wexaus/projects/${projectId}/${tableName}/data/listofchoices/${columnName}?prefilteredView=${viewName}`
  })

  return (...args: Args) => endpoint.get<ListOfChoices>(...args)
}
