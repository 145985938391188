import { mutationTree } from 'typed-vuex'
import { FileBrowserObj } from '@@/types'
import state from './state'

const sortComparator = (a: any, b: any) => {
  if (a.type === 'folder' && b.type !== 'folder') {
    return -1 // a is a folder, b is not, so a comes first
  } else if (a.type !== 'folder' && b.type === 'folder') {
    return 1 // b is a folder, a is not, so b comes first
  } else {
    // Types are the same, sort alphabetically by objectName
    return a.path[0].localeCompare(b.path[0], undefined, { numeric: true, sensitivity: 'base' })
  }
}
const mutations = mutationTree(state, {
  INIT_FILE_BROWSER (state): void {
    state.fileBrowserList = []
    state.selectedObjects = []
    state.modalConfig = {}
  },
  GET_OBJECTS (state, fileBrowserObjects: FileBrowserObj[]): void {
    // Sort the file browser objects based on type
    const sortedFileBrowserObjects: FileBrowserObj[] = fileBrowserObjects
    sortedFileBrowserObjects.sort(sortComparator)
    state.fileBrowserList = sortedFileBrowserObjects
  },
  SET_SELECTED_OBJECTS (state, selectedObjects: FileBrowserObj[]) {
    state.selectedObjects = selectedObjects
  },
  SET_MODAL_CONFIG (state, modalConfig) {
    state.modalConfig = modalConfig
  },
})

export default mutations
