import { getterTree } from 'typed-vuex'
import state from './state'

const getters = getterTree(state, {
  user: state => state.user,
  userInitials: state => state.user.fullName
    .split(' ')
    .map((txt: string) => txt[0])
    .join('')
    .toUpperCase(),
  userIsAuthenticated: state => Boolean(state.user.accessToken),
  isAdmin: state => state.user.optionalFeatures.includes('admin_wexaus'),
  userSettings: state => state.userSettings[state.user.mail],
})

export default getters
