import useNetworkCheck from '@/composables/use-network-check'
import { useNuxtApp } from '@/plugins/vue-composition-api'
import { ResponseErrorInterceptor } from '../types/'

/**
 * Error interceptor: Execute error callback
 * @param error
 */
export const errorResponseInterceptor: ResponseErrorInterceptor = options => (error) => {
  const { isOnline, checkOnlineStatus } = useNetworkCheck()
  const { i18n, $toast } = useNuxtApp()
  checkOnlineStatus().then(() => {
    if (!isOnline.value) {
      $toast.error(i18n.t('common.offline') as string, { icon: 'wifi' })
    } else if (error.response?.status === 504 || error.response?.status === 503 || error.response === undefined) {
      $toast.error(i18n.t('login.serverDown') as string, { icon: 'xmark' })
    } else {
      options.errorResponseCallback(error)
    }
  })
  return Promise.reject(error)
}
