import { computed } from '@vue/composition-api'
import { Route } from 'vue-router'
import { LoginFormModel } from '@@/types'
import { useNuxtApp } from '@/plugins/vue-composition-api'
import { Project } from '@/plugins/abbd-api/sdk/endpoints/v1/wexaus/projects'

interface Options {
  route: Route
}

export default function useAuth ({ route }: Options) {
  const { router, localePath, i18n, $ga, $accessor, $toast } = useNuxtApp()

  const user = computed(() => $accessor.user.user)

  /**
   *
   * @param redirectUri
   * @param router
   * @param projects
   */
  const getRouteFromRedirectQuery = (redirectUri: string, router: any, projects: Project[]) => {
    const decodedRedirect = decodeURIComponent(redirectUri)
    const resolvedRoute = router.resolve(decodedRedirect)

    const projectExists = Boolean(projects.find(project => project.id === resolvedRoute.route.params.projectId))

    if (projectExists) {
      return resolvedRoute
    }
  }

  /**
   * Signin a user, get project list and store the selected project
   * @param form
   * @returns the auth status: success: true / fail: false
   */
  const signin = (form: LoginFormModel) => {
    return $accessor.user.authenticate(form)
      .then((result) => {
        if (result && result.status === 200) {
          authenticate()
          return true
        } else if (!result || result === undefined) {
          $toast.error(i18n.t('login.wrongCredentials') as string, { icon: 'xmark' })
          return 'wrongCredentials'
        } else if (result.response?.data?.statusCode === 1005) {
          $toast.error(i18n.t('login.passwordExpired') as string, { icon: 'xmark' })
          router?.push(localePath.call({ i18n, router }, { name: 'forgotten-password' }))
        } else if (result.response?.status === 503 || result.response?.status === 504 || result.response === undefined) {
          return 'serverDown'
        } else {
          return false
        }
      })
      .catch((error) => {
        if (error) {
          $toast.error(i18n.t('login.serverDown') as string, { icon: 'xmark' })
          return 'serverDown'
        } else {
          $toast.error(i18n.t('login.wrongCredentials') as string, { icon: 'xmark' })
          return 'wrongCredentials'
        }
      })
  }

  const authenticate = async () => {
    await $accessor.projects.getProjects()
    if ($accessor.projects.projects.length) {
      const selectedProject = $accessor.projects.projects[0].id
      await $accessor.projects.selectProject(selectedProject)
    }
    $ga.set({
      dimension1: user.value.role,
      dimension2: user.value.company,
    })
    router?.push(localePath.call({ i18n, router }, {
      ...route,
      name: 'index',
    }, i18n.locale))
  }

  /**
   * Signout user
   */
  const signout = async () => {
    await $accessor.user.logout()
    router?.push(localePath.call({ i18n, router }, { name: 'login' }))
  }

  return {
    user,
    getRouteFromRedirectQuery,
    signin,
    signout,
    authenticate,
  }
}
