import createPersistedState from 'vuex-persistedstate'
import { Context } from '@nuxt/types'

class CustomStorage {
  persistSessionKey: string

  constructor (persistSessionKey: string) {
    this.persistSessionKey = persistSessionKey
  }

  getItem (key: string) {
    return this.getStorage(key).getItem(key)
  }

  setItem (key: string, value: any) {
    return this.getStorage(key).setItem(key, value)
  }

  removeItem (key: string) {
    return this.getStorage(key).removeItem(key)
  }

  getStorage (key: string) {
    const storage = window.localStorage.getItem(this.persistSessionKey)
      ? 'localStorage'
      : 'sessionStorage'

    // Get the previous storage type
    let prevStorage: string
    if (storage === 'sessionStorage') {
      prevStorage = 'localStorage'
    } else {
      prevStorage = 'sessionStorage'
    }

    window[prevStorage].removeItem(key)

    return window[storage]
  }
}

export default ({ store }: Context) => {
  createPersistedState({
    key: 'abbd',
    storage: new CustomStorage('rememberMe'),
    paths: [
      'user.user',
      'user.refreshToken',
      'user.accessToken',
      'user.role',
      'user.roleType',
      'user.lastLogin',
      'projects.selectedProject',
      'projects.projects',
    ],
  })(store)

  createPersistedState({
    key: 'abbd.localStorage',
    storage: localStorage,
    // storage: new CustomStorage('rememberMe'),
    paths: [
      'user.userSettings',
    ],
  })(store)
}
