import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import { ColDef } from '@ag-grid-community/core'
import { useAgGridHelpers } from '@/composables/use-ag-grid-helpers'
import { DataTableApiEndpoint } from '@/plugins/abbd-api/sdk/endpoints/dataTable/metadata'

export type DataTableRow = Record<string, any>

interface DataTableState {
  metadata: Partial<DataTableApiEndpoint.DataTableMetadata>
  columnDefs: ColDef[]
  rows: DataTableRow[]
  footerSumCols: DataTableRow[]
  anomalyIdColumn: string
}

export const state = (): DataTableState => ({
  metadata: {},
  columnDefs: [],
  rows: [],
  footerSumCols: [],
  anomalyIdColumn: '',
})

export const mutations = mutationTree(state, {
  SET_META (state, payload) {
    state.metadata = payload
  },
  SET_ROWS (state, payload: DataTableRow[]) {
    state.rows = payload
  },
  SET_COLUMN_DEFS (state, payload: ColDef[]) {
    state.columnDefs = payload
  },
  SET_FOOTER_SUM_COLS (state, payload: DataTableRow[]) {
    state.footerSumCols = payload
  },
  SET_ANOMALY_ID_COLUMN (state, anomalyIdColumn: string) {
    state.anomalyIdColumn = anomalyIdColumn
  },
})

export const getters = getterTree(state, {
  columnDefsAsObj: state =>
    state.columnDefs
      .reduce((finalArray: any[], colGroup: any) => [
        ...finalArray,
        ...colGroup.children,
      ], [])
      .reduce((finalObj: any, col: ColDef) => {
        if (col.colId) {
          finalObj[col.colId] = col
        }
        return finalObj
      }, {}),
})

export const actions = actionTree({ state, mutations, getters }, {
  getProjectData ({ commit }, uriParams: { projectId: string, tableName: string }) {
    const { getColGroupDefs } = useAgGridHelpers()

    return this.app.$abbd.dataTable
      .meta({ uriParams })
      .then(async (dataTableMeta) => {
        const dashboardData = await this.app.$abbd.dataTable
          .data({
            uriParams,
            params: { timestamp: dataTableMeta.data.viewMetaData.timestamp },
          })

        const columnDefs = getColGroupDefs(dataTableMeta.data)
        const data = dashboardData.data.values
          .map((entry) => {
            return {
              ...entry,
              entryId: dataTableMeta.data.viewMetaData.keyColumns
                .map(key => entry[key]).join('$$'),
            }
          })
        const footerSumCols = dataTableMeta.data.viewMetaData.columns
          .filter((col: any) => col.footerStyle === 'SUM')
          .map((col: any) => col.name)

        commit('SET_META', dataTableMeta.data)
        commit('SET_ROWS', data)
        commit('SET_COLUMN_DEFS', columnDefs)
        commit('SET_FOOTER_SUM_COLS', footerSumCols)
        commit('SET_ANOMALY_ID_COLUMN', dataTableMeta.data.anomalyIdColumn)

        return {
          columnDefs,
          data,
        }
      })
  },
})

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
