import Vue from 'vue'
import { actionTree } from 'typed-vuex'
import { S3 } from 'aws-sdk'
import { FileBrowserObj } from '@@/types'
import state from './state'
import mutations from './mutations'
import getters from './getters'

const listAllKeys = (params: S3.ListObjectsV2Request, out: S3.ObjectList = []) => new Promise<S3.ObjectList>((resolve, reject) => {
  Vue.$aws.s3.listObjectsV2(params).promise()
    .then(({ Contents, IsTruncated, NextContinuationToken }) => {
      if (Contents) {
        out.push(...Contents)
      }

      if (IsTruncated) {
        resolve(listAllKeys(Object.assign(params, { ContinuationToken: NextContinuationToken }), out))
      } else {
        resolve(out)
      }
    })
    .catch(reject)
})

const actions = actionTree({ state, mutations, getters }, {
  initBrowserFile ({ commit }): void {
    commit('INIT_FILE_BROWSER')
  },

  getObjects ({ commit, rootGetters }): Promise<any> {
    const Prefix = `${rootGetters['files/s3Credentials'].prefix}/`

    const handleSuccess = (Contents: S3.ObjectList) => {
      if (!Contents) return []

      const items: FileBrowserObj[] = Contents
        .filter((item: any) => item.Key !== Prefix)
        .map((item: any) => {
          const path = item.Key
            .split('/')
            .filter((fragment: string) => fragment)

          return {
            key: item.Key,
            size: item.Size,
            lastModified: item.LastModified,
            path: path.slice(1, path.length),
            type: /\/$/.test(item.Key) ? 'folder' : 'file',
          }
        })

      commit('GET_OBJECTS', items)
      return Promise.resolve(items)
    }

    const params = {
      Bucket: rootGetters['files/s3Credentials'].name,
      Prefix,
    }

    return listAllKeys(params)
      .then(handleSuccess)
  },

  async addFolder ({ rootGetters, dispatch }, folderName: string): Promise<any> {
    const prefix = `${rootGetters['files/s3Credentials'].prefix}/`
    const fileAlreadyExists = await dispatch('files/checkObjectExists', folderName, { root: true })

    if (!fileAlreadyExists) {
      await Vue.$aws.s3
        .putObject({
          Bucket: rootGetters['files/s3Credentials'].name,
          Key: `${prefix}${folderName}`,
        })
        .promise()

      await dispatch('getObjects')

      return Promise.resolve(`${prefix}${folderName}`)
    } else {
      return Promise.reject(new Error('folderAlreadyExists'))
    }
  },

  async deleteObjects ({ rootGetters, dispatch }, objects: string[]): Promise<any> {
    await Vue.$aws.s3
      .deleteObjects({
        Bucket: rootGetters['files/s3Credentials'].name,
        Delete: {
          Objects: objects.map(key => ({ Key: key })),
          Quiet: false,
        },
      })
      .promise()

    return dispatch('getObjects')
  },

  async renameObjects ({ rootGetters }, { oldKey, newKey }) {
    const Bucket = rootGetters['files/s3Credentials'].name
    const Key = newKey
    const CopySource = encodeURIComponent(`${Bucket}/${oldKey}`)

    const objectInfos = await Vue.$aws.s3
      .headObject({ Bucket, Key: oldKey })
      .promise()

    const partSize = 10 * 1024 * 1024

    if (objectInfos.ContentLength && objectInfos.ContentLength > partSize) {
      const totalParts = Math.floor(objectInfos.ContentLength / partSize)

      const multipartInstance: S3.CreateMultipartUploadOutput = await Vue.$aws.s3
        .createMultipartUpload({ Bucket, Key })
        .promise()

      const MultipartUpload: any = { Parts: [] }

      for (let index = 0; index < totalParts; index++) {
        const startByte = index * partSize
        const endByte = index + 1 === totalParts ? objectInfos.ContentLength - 1 : (index + 1) * partSize

        await Vue.$aws.s3
          .uploadPartCopy({
            Bucket,
            CopySource,
            CopySourceRange: `bytes=${startByte}-${endByte}`,
            PartNumber: index + 1,
            UploadId: multipartInstance.UploadId as string,
            Key,
          })
          .promise()
          .then((res: any) => {
            MultipartUpload.Parts.push({
              ETag: res.ETag,
              PartNumber: index + 1,
            })
          })
      }

      return Vue.$aws.s3
        .completeMultipartUpload({
          Bucket,
          Key,
          UploadId: multipartInstance.UploadId as string,
          MultipartUpload,
        })
        .promise()
    } else {
      return Vue.$aws.s3
        .copyObject({ Bucket, CopySource, Key })
        .promise()
    }
  },

  getFileUrl ({ rootGetters }, { key, path }) {
    return Vue.$aws.s3.getSignedUrl('getObject', {
      Bucket: rootGetters['files/s3Credentials'].name,
      ResponseContentDisposition: `attachment; filename="${path.slice(-1).pop()}"`,
      Key: key,
    })
  },

  setSelectedObjects ({ commit }, selectedObjects: FileBrowserObj[]) {
    commit('SET_SELECTED_OBJECTS', selectedObjects)
  },

  setModal ({ commit }, modalConfig) {
    commit('SET_MODAL_CONFIG', modalConfig)
  },
})

export default actions
