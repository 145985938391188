import { SetupContext, Ref } from '@vue/composition-api'
import { GridOptions } from '@ag-grid-enterprise/all-modules'

interface Options {
  ctx: SetupContext
  gridOptions: Ref<GridOptions>
}
export default function useFreeAnomalyUpdateCells ({ ctx, gridOptions }: Options) {
  const updateMutipleCellsAndRows = (body: any) => {
    const queryOptions = {
      uriParams: {
        projectId: ctx.root.$accessor.projects.selectedProject?.id || '',
      },
      params: {
        timestamp: ctx.root.$accessor.dataTable.metadata?.viewMetaData?.timestamp,
      },
    }
    return ctx.root.$abbd.projects.freeAnomaly.updateMultiple(body, queryOptions)
      .then((res) => {
        refreshAfterUpdate(res.data)
      })
  }

  const refreshAfterUpdate = ({ updatedRows }: any) => {
    const keyColumns = ctx.root.$accessor.dataTable.metadata?.viewMetaData?.keyColumns
    const mutatedRows = updatedRows.values.map((row: any) => {
      const rowId = keyColumns?.map((key: string) => row[key]).join('$$')
      return {
        ...gridOptions.value.api?.getRowNode(rowId || '')?.data,
        ...row,
      }
    })

    if (mutatedRows) {
      const updatedNodes = gridOptions.value.api?.applyTransaction({ update: mutatedRows })
      gridOptions.value.api?.redrawRows({ rowNodes: updatedNodes?.update })
    }
  }
  const updateMutipleFile = (file: File, rowsValues: any, columnName: string) => {
    const formData = new FormData()
    formData.append('file', file, file.name)
    formData.append('meta-data', new Blob([JSON.stringify({ filename: file.name })], { type: 'application/json' }))
    formData.append('data', new Blob([JSON.stringify({ rowsValues })], { type: 'application/json' }), 'data')
    const queryOptions = {
      uriParams: {
        projectId: ctx.root.$accessor.projects.selectedProject?.id || '',
        columnName,
      },
      params: {
        timestamp: ctx.root.$accessor.dataTable.metadata.viewMetaData?.timestamp,
      },
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    return ctx.root.$abbd.projects.freeAnomaly.multiFile.put(formData, queryOptions)
      .then((res) => {
        refreshAfterUpdate(res.data)
      })
  }

  return {
    updateMutipleCellsAndRows,
    refreshAfterUpdate,
    updateMutipleFile,
  }
}
