


























































import { defineComponent, reactive, toRefs, computed } from '@vue/composition-api'
import fileDownload from 'js-file-download'
import { AxiosResponse } from 'axios'
import FormulateInputMixin from '@braid/vue-formulate/src/FormulateInputMixin'
import { UriParams } from '~/plugins/abbd-api/sdk/endpoints/v1/wexaus/projects/_projectId/dashboard/files'

interface State {
  fileInput: HTMLInputElement | null
  isLoading: boolean
  uriParams: UriParams
}

export default defineComponent({
  name: 'FormulateFile',
  mixins: [FormulateInputMixin],
  setup (props, ctx) {
    const state = reactive<State>({
      fileInput: null,
      isLoading: false,
      uriParams: {
        projectId: ctx.root.$route.params.projectId,
        columnName: ctx.parent?.context.attributes.col.name,
        idAnomaly: ctx.parent?.getFormValues()[ctx.root.$store.getters['dashboard/meta']?.anomalyIdColumn],
        idEntry: ctx.parent?.getFormValues()['Ecriture Id'],
      },
    })

    const downloadFile = () => {
      ctx.root.$abbd.projects.dashboard.file
        .get({ responseType: 'arraybuffer', uriParams: state.uriParams })
        .then((res: AxiosResponse) => {
          fileDownload(res.data, ctx.parent?.context.model)
        })
    }

    const fileName = computed<string>(() => {
      if (typeof ctx.parent?.context.model === 'string') {
        return ctx.parent?.context.model
      } else if (ctx.parent?.context.model[0]?.url) {
        return ctx.parent?.context.model[0]?.url
      } else if (ctx.parent?.context.model.name) {
        return ctx.parent?.context.model.name
      } else {
        return ''
      }
    })

    return {
      ...toRefs(state),
      downloadFile,
      fileName,
    }
  },
})
