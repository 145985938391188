import { getterTree } from 'typed-vuex'
import { ColDef, ColGroupDef } from '@ag-grid-enterprise/all-modules'
import state from './state'

interface ColumnDefsAsObj {
  [key: string]: ColDef
}

const getters = getterTree(state, {
  columnDefs: state => state.columnDefs,
  columnDefsAsObj: state =>
    state.columnDefs
      .reduce(
        (finalArray: ColDef[], colGroup: ColGroupDef) => [
          ...finalArray,
          ...colGroup.children,
        ],
        [],
      )
      .reduce((finalObj: ColumnDefsAsObj, col: ColDef) => {
        if (col.colId) {
          finalObj[col.colId] = col
        }
        return finalObj
      }, {}),
  data: state => state.data,
  viewName: state => state.viewName,
  anomalyIdColumn: state => state.anomalyIdColumn,
  footerSumCols: state => state.footerSumCols,
  meta: state => state.meta,
  prefilteredView: state => state.prefilteredView,
  modalConfig: state => state.modalConfig,
  anomalyCardDesign: state => state.anomalyCardDesign,
  columnDefsFromDesign: state => state.columnDefsFromDesign,
})

export default getters
