import { Context, Middleware } from '@nuxt/types'

const requireAuth: Middleware = function ({ store, redirect, app, route }: Context) {
  if (!store.getters['user/userIsAuthenticated']) {
    const queryParams = { redirect: '' }

    if (route.fullPath !== '/') {
      queryParams.redirect = encodeURIComponent(route.fullPath)
    }

    return redirect(app.localePath({ name: 'login' }), queryParams)
  }
}

export default requireAuth
