const middleware = {}

middleware['homeRedirect'] = require('../src/middleware/homeRedirect.ts')
middleware['homeRedirect'] = middleware['homeRedirect'].default || middleware['homeRedirect']

middleware['requireAdmin'] = require('../src/middleware/requireAdmin.ts')
middleware['requireAdmin'] = middleware['requireAdmin'].default || middleware['requireAdmin']

middleware['requireAuth'] = require('../src/middleware/requireAuth.ts')
middleware['requireAuth'] = middleware['requireAuth'].default || middleware['requireAuth']

middleware['requireDashboard'] = require('../src/middleware/requireDashboard.ts')
middleware['requireDashboard'] = middleware['requireDashboard'].default || middleware['requireDashboard']

middleware['requireDuplicateSuppliers'] = require('../src/middleware/requireDuplicateSuppliers.ts')
middleware['requireDuplicateSuppliers'] = middleware['requireDuplicateSuppliers'].default || middleware['requireDuplicateSuppliers']

middleware['requireFreeAnomaly'] = require('../src/middleware/requireFreeAnomaly.ts')
middleware['requireFreeAnomaly'] = middleware['requireFreeAnomaly'].default || middleware['requireFreeAnomaly']

export default middleware
