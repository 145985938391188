import { ResponseInterceptor } from '../types/'

/**
 * Success interceptor: Handle response of authentication and refresh
 * @param result
 */
export const authResponseInterceptor: ResponseInterceptor = ({ updateAuthCallback }, axiosInstance, endpoints) => (result) => {
  if (result && result.config && result.config.url) {
    const isAuthEndpoint = result.config.url.endsWith('auth')

    const isRefreshEndpoint = result.config.url
      .includes(endpoints.auth.refreshToken.uri)

    if (isAuthEndpoint || isRefreshEndpoint) {
      updateAuthCallback(result.data)
    }
  }

  return result
}
