import { AxiosError } from 'axios'
import { SetupContext, Ref } from '@vue/composition-api'
import { GridOptions } from '@ag-grid-enterprise/all-modules'
import useModalMsgBox from '@/composables/use-modal-msg-box'

interface Options {
  ctx: SetupContext
  gridOptions: Ref<GridOptions>
}

export default function useAgGridUpdateCells ({ ctx, gridOptions }: Options) {
  const msgBoxes = useModalMsgBox({ ctx })
  const onCellValueChanged = (params: any) => {
    if (params.colDef.type === 'FILE') return

    if (params.newValue === undefined || params.newValue === '') {
      params.newValue = null
    }
    if (params.oldValue === undefined || params.oldValue === '') {
      params.oldValue = null
    }

    if (params.oldValue === params.newValue) return

    gridOptions.value.api?.redrawRows()

    if (params.oldValue !== params.newValue) {
      const rowValues = { ...params.data }
      delete rowValues.path
      delete rowValues.entryId

      if (rowValues[params.column.colId] === '') {
        rowValues[params.column.colId] = null
      }
      const body = {
        rowsValues: [rowValues],
        columnsToUpdate: [
          params.column.colId,
        ],
      }
      const queryOptions = {
        uriParams: {
          projectId: ctx.root.$accessor.projects.selectedProject?.id || '',
          atName: ctx.root.$accessor.dashboard.viewName,
          columnName: params.column.colId,
          viewName: ctx.root.$accessor.dashboard.prefilteredView,
        },
        params: {
          timestamp: ctx.root.$accessor.dashboard.meta?.viewMetaData.timestamp,
        },
      }

      return ctx.root.$abbd.projects.dashboard.update.cell(body, queryOptions)
        .then((res) => {
          refreshAfterUpdate(res.data)
        })
        .catch((error: AxiosError) => {
          if (error.response?.data?.statusCode === 1001) {
            msgBoxes.dashboardStructureErr()
          } else if (error.response?.data?.statusCode === 1003) {
            ctx.root.$toast.error(ctx.root.$t('resultats.global.dashboardBlockedRow') as string, { icon: 'xmark' })
          } else {
            ctx.root.$toast.error(error.message, { icon: 'xmark' })
          }
        })
    }
  }

  const updateMutipleCells = (body: any) => {
    const queryOptions = {
      uriParams: {
        projectId: ctx.root.$accessor.projects.selectedProject?.id || '',
        viewName: ctx.root.$accessor.dashboard.prefilteredView,
      },
      params: {
        timestamp: ctx.root.$accessor.dashboard.meta?.viewMetaData.timestamp,
      },
    }

    return ctx.root.$abbd.projects.dashboard.update.cells(body, queryOptions)
      .then((res) => {
        refreshAfterUpdate(res.data)
      })
  }

  const updateMutipleCellsAndRows = (body: any) => {
    const queryOptions = {
      uriParams: {
        projectId: ctx.root.$accessor.projects.selectedProject?.id || '',
        viewName: ctx.root.$accessor.dashboard.prefilteredView,
      },
      params: {
        timestamp: ctx.root.$accessor.dashboard.meta?.viewMetaData.timestamp,
      },
    }

    return ctx.root.$abbd.projects.dashboard.data.put(body, queryOptions)
      .then((res) => {
        refreshAfterUpdate(res.data)
      })
  }

  const updateMutipleFile = (file: File, rowsValues: any, columnName: string) => {
    const formData = new FormData()
    formData.append('file', file, file.name)
    formData.append('meta-data', new Blob([JSON.stringify({ filename: file.name })], { type: 'application/json' }))
    formData.append('data', new Blob([JSON.stringify({ rowsValues })], { type: 'application/json' }), 'data')
    const queryOptions = {
      uriParams: {
        projectId: ctx.root.$accessor.projects.selectedProject?.id || '',
        columnName,
        viewName: ctx.root.$accessor.dashboard.prefilteredView,
      },
      params: {
        timestamp: ctx.root.$accessor.dashboard.meta?.viewMetaData.timestamp,
      },
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    return ctx.root.$abbd.projects.dashboard.update.multiFile.put(formData, queryOptions)
      .then((res) => {
        refreshAfterUpdate(res.data)
      })
  }

  const updateMutipleRows = (selectedObjects: any, columnName: string, value: any) => {
    const queryOptions = {
      uriParams: {
        projectId: ctx.root.$accessor.projects.selectedProject?.id || '',
        atName: ctx.root.$accessor.dashboard.viewName,
        columnName,
        viewName: ctx.root.$accessor.dashboard.prefilteredView,
      },
      params: {
        timestamp: ctx.root.$accessor.dashboard.meta?.viewMetaData.timestamp,
      },
    }

    const body = selectedObjects
      .map((entry: any) => {
        delete entry.path
        delete entry.entryId
        entry[queryOptions.uriParams.columnName] = value
        return entry
      })

    return ctx.root.$abbd.projects.dashboard.update.rows(body, queryOptions)
      .then((res) => {
        refreshAfterUpdate(res.data)
      })
      .catch((error: AxiosError) => {
        if (error.response?.data?.statusCode === 1001) {
          msgBoxes.dashboardStructureErr()
        } else {
          ctx.root.$toast.error(error.message, { icon: 'xmark' })
        }
      })
  }

  const refreshAfterUpdate = ({ updatedRows }: any) => {
    const keyColumns = ctx.root.$accessor.dashboard.meta?.viewMetaData.keyColumns

    const mutatedRows = updatedRows.values.map((row: any) => {
      const rowId = keyColumns?.map((key: string) => row[key]).join('$$')

      return {
        ...gridOptions.value.api?.getRowNode(rowId || '')?.data,
        ...row,
      }
    })

    if (mutatedRows) {
      const updatedNodes = gridOptions.value.api?.applyTransaction({ update: mutatedRows })
      gridOptions.value.api?.redrawRows({ rowNodes: updatedNodes?.update })
    }
  }

  return {
    updateMutipleRows,
    updateMutipleCells,
    updateMutipleCellsAndRows,
    onCellValueChanged,
    refreshAfterUpdate,
    updateMutipleFile,
  }
}
