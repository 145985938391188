import { GridOptions, ICellRendererComp, ICellRendererParams } from '@ag-grid-enterprise/all-modules'
import { confirmSmDanger } from '@/utils/modals-presets'
import { ComponentInstance } from '@vue/composition-api'
import { CellUpdate } from '@/plugins/abbd-api/sdk/endpoints/v1/wexaus/projects/_projectId/dashboard/data/update'

interface ExtendedICellRendererParams extends ICellRendererParams {
  app: ComponentInstance
  parentGridOptions: GridOptions
}

export class File implements ICellRendererComp {
  eGui: HTMLElement = document.createElement('div')
  uriParams = {
    projectId: '',
    columnName: '',
    idAnomaly: '',
    idEntry: '',
    tableName: '',
    row: '',
  }

  init (params: ExtendedICellRendererParams) {
    if (params.node.rowPinned) return
    this.uriParams.columnName = params.colDef.colId as string
    this.uriParams.idAnomaly = params.data[params.context.app.$accessor.dashboard.anomalyIdColumn]
    this.uriParams.idEntry = params.data['N° ABBD']
    this.uriParams.projectId = params.context.app.$route.params.projectId
    if (params.context.app.$route.path.includes('duplicate-suppliers')) {
      this.uriParams.tableName = 'suppliersduplicate'
    } else if (params.context.app.$route.path.includes('free-anomaly')) {
      this.uriParams.tableName = 'freeanomaly'
      this.uriParams.idAnomaly = params.data[params.context.app.$accessor.dataTable.anomalyIdColumn]
      this.uriParams.idEntry = params.data.Id
    } else {
      this.uriParams.tableName = 'dashboard'
    }
    const row = { ...params.data }
    delete row.path
    delete row.entryId
    this.uriParams.row = encodeURIComponent(JSON.stringify(row))
    const downloadUrl = [
      '//',
      document.location.host,
      '/services/v1/wexaus/projects/',
      this.uriParams.projectId,
      `/${this.uriParams.tableName}/data/files/`,
      `${this.uriParams.columnName}`,
      this.uriParams.tableName.includes('suppliersduplicate') ? `?row=${this.uriParams.row}` : '/',
      this.uriParams.tableName.includes('suppliersduplicate') ? '' : `${this.uriParams.idAnomaly}/`,
      this.uriParams.tableName.includes('suppliersduplicate') ? '' : `${this.uriParams.idEntry}`,
    ].join('')

    const hasNoFile = !params.value && typeof params.colDef.editable === 'function' && params.colDef.editable(params)

    this.eGui.className = 'd-flex align-items-center h-100  ml-2'

    const noFileTemplate = `
    <label class="pt-0 pb-0 text-gray-500 cursor-pointer flex items-center mb-0">
      <i class="upload-status fas fa-file-arrow-up text-sm mr-1 flex items-center"></i>
      ${params.context.app.$t('resultats.global.uploadFile')}

      <input
        id="fileInput"
        type="file"
        style="position: fixed; top: -100rem; visibility: hidden;"
        multiple
        data-test="file-uploader-input"
        maxlength="1"
      >
    </label>
    `

    let fileTemplate = `
    <a
      href="${downloadUrl}"
      target="_blank"
      class="download-btn pt-0 pb-0 text-green-600"
      data-test="download-file"
    >
      <font-awesome-icon
        icon="file"
        class="mr-1"
        size="sm"
      />
      <span>${params.value}</span>
    </a>
    `

    if (typeof params.colDef.editable === 'function' && params.colDef.editable(params)) {
      fileTemplate += `
      <button class="delete-btn ml-auto">
        <i class="fas fa-xmark float-right text-red-600 text-sm"></i>
      </button>
      `
    }

    const template = []

    if (hasNoFile) {
      template.push(noFileTemplate)
    } else if (!hasNoFile && params.value) {
      template.push(fileTemplate)
    }

    this.eGui.innerHTML = template.join('')

    if (hasNoFile) {
      const fileInput: HTMLInputElement | null = this.eGui.querySelector('input[type="file"]')

      if (fileInput) {
        fileInput.onchange = () => this.uploadFile(params, fileInput?.files)
      }
    } else {
      const deleteBtn: HTMLElement | null = this.eGui.querySelector('.delete-btn')

      if (deleteBtn) {
        deleteBtn.onclick = () => this.deleteFile(params)
      }
    }
  }

  refresh () {
    return true
  }

  getGui () {
    return this.eGui
  }

  uploadFile (params: ExtendedICellRendererParams, files: FileList | null) {
    if (!files) return
    const file = files[0]
    const formData = new FormData()
    formData.append('file', file, file.name)
    formData.append('meta-data', new Blob([JSON.stringify({ filename: file.name })], { type: 'application/json' }))
    this.toggleLoadingStatus(true)
    if (this.uriParams.tableName.includes('suppliersduplicate')) {
      params.context.app.$abbd.projects.duplicateSuppliers.file
        .put(formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          uriParams: this.uriParams,
          params: {
            timestamp: params.context.getViewConfig().timestamp,
          },
        })
        .then((res: any) => {
          this.toggleLoadingStatus(false)
          this.updateRows(params, res.data)
        })
    } else if (this.uriParams.tableName.includes('freeanomaly')) {
      params.context.app.$abbd.projects.freeAnomaly.file
        .put(formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          uriParams: this.uriParams,
          params: {
            timestamp: params.context.getViewConfig().timestamp,
          },
        })
        .then((res: any) => {
          this.toggleLoadingStatus(false)
          this.updateRows(params, res.data)
        })
    } else {
      params.context.app.$abbd.projects.dashboard.file
        .put(formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          uriParams: this.uriParams,
        })
        .then((res: any) => {
          this.toggleLoadingStatus(false)
          this.updateRows(params, res.data)
        })
    }
  }

  deleteFile (params: ExtendedICellRendererParams) {
    params.context.app.$bvModal
      .msgBoxConfirm(params.context.app.$t('resultats.global.deleteFileModal.body', { fileName: params.value }) as string, {
        ...confirmSmDanger,
        id: 'modal-delete-file',
        title: params.context.app.$t('resultats.global.deleteFileModal.title') as string,
        okTitle: params.context.app.$t('resultats.global.deleteFileModal.okBtn') as string,
        cancelTitle: params.context.app.$t('resultats.global.deleteFileModal.cancelBtn') as string,
      })
      .then((isConfirmed: boolean) => {
        if (isConfirmed && this.uriParams.tableName.includes('suppliersduplicate')) {
          params.context.app.$abbd.projects.duplicateSuppliers.file.delete({ uriParams: this.uriParams })
            .then((res: any) => {
              this.updateRows(params, res.data)
            })
        } else if (isConfirmed && this.uriParams.tableName.includes('freeanomaly')) {
          params.context.app.$abbd.projects.freeAnomaly.file.delete({ uriParams: this.uriParams })
            .then((res: any) => {
              this.updateRows(params, res.data)
            })
        } else {
          params.context.app.$abbd.projects.dashboard.file.delete({ uriParams: this.uriParams })
            .then((res: any) => {
              this.updateRows(params, res.data)
            })
        }
      })
  }

  toggleLoadingStatus (status: boolean) {
    const uploadIcon = this.eGui.querySelector('.upload-status')

    if (uploadIcon) {
      if (status) {
        uploadIcon.className = uploadIcon.className.replace('fa-file-arrow-up', 'fa-spinner')
        uploadIcon.className = `${uploadIcon.className} fa-spin`
      } else {
        uploadIcon.className = uploadIcon.className.replace('fa-spinner', 'fa-file-arrow-up')
        uploadIcon.className = uploadIcon.className.replace(' fa-spin', '')
      }
    }
  }

  updateRows (params: ExtendedICellRendererParams, { updatedRows }: CellUpdate) {
    const tableName = params.context.getViewConfig().tableName
    const keyColumns = tableName.includes('suppliersduplicate') || tableName.includes('freeanomaly')
      ? params.context.app.$accessor.dataTable.metadata?.viewMetaData?.keyColumns
      : params.context.app.$accessor.dashboard.meta?.viewMetaData.keyColumns
    const mutatedRows = updatedRows.values
      .filter((row: any) => {
        const rowId = keyColumns?.map((key: string) => row[key]).join('$$') || ''
        return params.api.getRowNode(rowId)
      })
      .map((row: any) => {
        const rowId = keyColumns?.map((key: string) => row[key]).join('$$') || ''

        return {
          ...params.api.getRowNode(rowId)?.data,
          ...row,
        }
      })

    if (mutatedRows) {
      const updatedNodes = params.api.applyTransaction({ update: mutatedRows })
      params.api.redrawRows({ rowNodes: updatedNodes?.update })
    }

    if (params.parentGridOptions) {
      const mutatedRows = updatedRows.values.map((row: any) => {
        const rowId = keyColumns?.map((key: string) => row[key]).join('$$') || ''

        return {
          ...params.parentGridOptions.api?.getRowNode(rowId)?.data,
          ...row,
        }
      })

      if (mutatedRows) {
        const updatedNodes = params.parentGridOptions.api?.applyTransaction({ update: mutatedRows })
        params.parentGridOptions.api?.redrawRows({ rowNodes: updatedNodes?.update })
      }
    }
  }
}
