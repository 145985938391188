import { RowNode, ValueGetterParams, ICellRendererParams } from '@ag-grid-enterprise/all-modules'

/**
* AG-GRID | Define the col header value getter, used to get header translations
* https://www.ag-grid.com/javascript-grid-value-getters/#header-value-getters
* @param params
*/
export const headerValueGetter = function (this: any, params: ValueGetterParams) {
  return this.$root.$i18n.t(params.colDef.headerName)
}

/**
 * AG-GRID | Define the group cell renderer, used to render folder/file icons
 * https://www.ag-grid.com/javascript-grid-provided-renderer-group/#group-cell-renderer
 * @param params
 */
export const objectGroupInnerRenderer = (params: ICellRendererParams) => {
  if (!params.data) return ''

  const icon = params.data ? params.data.type : 'folder'
  const objectName = params.data.path.slice(-1).pop()
  return `<i class="fas fa-${icon} mr-2 text-black-50"></i> ${objectName}`
}

/**
 * Get the first unselected parent node in a tree-data mode
 * @param originNode Node you want to get the first unselected parent from
 */
export const getFirstParentUnselected = (originNode: RowNode): RowNode => {
  if (!originNode.parent || (originNode.parent && !originNode.parent.data)) {
    return originNode
  } else if (originNode.parent.isSelected()) {
    return getFirstParentUnselected(originNode.parent)
  } else {
    return originNode.parent
  }
}

/**
 * Get a dedup list of first unselected parents from a node list
 * @param nodeList The list of nodes you want to get a dedup list of unselected parents from
 */
export const getDedupFirstUnselectedParents = (nodeList: RowNode[]) => {
  const parents = nodeList.map(node => getFirstParentUnselected(node))
  return dedupNodesById(parents)
}

/**
 * Get a dedup list of nodes
 * @param nodes List of nodes to dedup
 */
export const dedupNodesById = (nodes: RowNode[]) => {
  return nodes
    .reduce((list: RowNode[], node) => {
      const exists = list.find(item => item.id === node.id)

      if (!exists) {
        list.push(node)
      }
      return list
    }, [])
}

/**
 * Filter out the children nodes from a list
 * @param sourceList list with multiple nodes to filter
 */
export const getHighestParentsFromList = (nodeList: RowNode[]): RowNode[] => {
  const getParents = (node: RowNode, sourceList: RowNode[]): RowNode => {
    const parentInList = sourceList
      .find(item => node.parent && node.parent.id === item.data.key)

    return node.parent && parentInList ? getParents(node.parent, sourceList) : node
  }

  const parents: RowNode[] = nodeList.map(node => getParents(node, nodeList))
  return dedupNodesById(parents)
}
