import { SetupContext } from '@vue/composition-api'

interface Options {
  ctx: SetupContext
}
export default function useNotifyAction ({ ctx }: Options) {
  const notifyAction = async (action: any, args: any) => {
    if (ctx.root.$accessor.user?.user.roleType === 'ExausCustomer') {
      await ctx.root.$abbd.projects.notifyAction({ action: action.action, args: args.args }, {
        uriParams: {
          projectId: ctx.root.$store.getters['projects/selectedProject'].id,
        },
      })
    }
  }
  return {
    notifyAction,
  }
}
