import { computed, Ref, SetupContext } from '@vue/composition-api'
import { UploadQueuedFile } from '@@/types'

interface Options {
  ctx: SetupContext
  fileInput: Ref<HTMLInputElement | undefined>
}

export default function useUploadQueue ({ ctx, fileInput }: Options) {
  const uploadQueue = computed<UploadQueuedFile[]>(() => ctx.root.$store.getters['files/uploader/uploadQueue'])

  const removeFromQueue = (files: UploadQueuedFile[]) => ctx.root.$store.dispatch('files/uploader/removeFromQueue', files)
  const clearQueue = () => ctx.root.$store.dispatch('files/uploader/clearQueue')

  const addFiles = (filesList: File[], basePrefix: string) => {
    const files = filesList
      .filter(file => file.name)
      .map(file => ({
        Key: `${basePrefix}${file.name.replace(/[&$@=;:+,?/\\{^}%`\]"'>[~<#|€£¥]/gm, '')}`,
        Body: file,
        size: file.size,
        status: 'queued',
        msg: '',
      }))

    ctx.root.$store.dispatch('files/uploader/addToQueue', files)

    if (fileInput.value) {
      fileInput.value.value = ''
    }
  }

  return {
    uploadQueue,
    removeFromQueue,
    clearQueue,
    addFiles,
  }
}
