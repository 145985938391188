

export default () => {
    if (process.server) {
        return;
    }

    const detect = () => 'find-index' in window.Array;

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "core-js/es/array/find-index"
        ], function (require) {
            const polyfill = require("core-js/es/array/find-index");

            resolve();
        });
    });
}