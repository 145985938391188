import { getterTree } from 'typed-vuex'
import state from './state'

const getters = getterTree(state, {
  fileBrowserList: state => state.fileBrowserList,
  selectedObjects: state => state.selectedObjects,
  modalConfig: state => state.modalConfig,
  folderList: state => state.fileBrowserList
    .filter(object => object.type === 'folder'),
  fileList: state => state.fileBrowserList
    .filter(object => object.type === 'file'),
})

export default getters
