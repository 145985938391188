import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'
import { CellUpdate } from './data/update'

export interface UriParams {
  projectId: string
  columnName: string
  idAnomaly: string
  idEntry: string
}

type Args = [
  EndpointsOptions<UriParams>?
]

type PutArgs = [
  FormData,
  Args[0]?
]

export default (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>(({ projectId, columnName, idAnomaly, idEntry }) => {
    return `v1/wexaus/projects/${projectId}/dashboard/data/files/${columnName}/${idAnomaly}/${idEntry}`
  })

  return {
    get: (...args: Args) => endpoint.get(...args),
    put: (...args: PutArgs) => endpoint.put<CellUpdate>(...args),
    delete: (...args: Args) => endpoint.delete<CellUpdate>(...args),
  }
}
