





























import { defineComponent, ref, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'SelectedObjectsList',
  props: {
    selectedRows: {
      default: [],
    },
  },
  setup (props) {
    const maxAmount = ref<number>(4)
    const remainingObjects = computed(() => props.selectedRows.length - maxAmount.value)

    return {
      maxAmount,
      remainingObjects,
    }
  },
})
