import { mutationTree } from 'typed-vuex'
import state from './state'

const mutations = mutationTree(state, {
  UPDATE_PROJECTS (state, projects) {
    state.projects = projects
  },
  CLEAR_PROJECTS (state) {
    state.projects = []
  },
  UPDATE_SELECTED_PROJECT (state: any, { projectId, optionalFeatures }) {
    state.selectedProject = state.projects
      .find((project: { id: any }) => project.id === projectId)
    state.selectedProject = {
      ...state.selectedProject,
      optionalFeatures,
    }
  },
})

export default mutations
