










import { defineComponent } from '@vue/composition-api'
import IeBanner from '@/components/common/IeBanner.vue'
import { useNuxtApp } from '~/plugins/vue-composition-api'

export default defineComponent({
  name: 'Default',
  head () {
    const { i18n } = useNuxtApp()
    return {
      title: i18n.t('common.title'),
    }
  },
  components: {
    IeBanner,
  },
})
