import { SetupContext } from '@vue/composition-api'
import { Context, Middleware } from '@nuxt/types'
import useSidebarMenu from '@/composables/use-sidebar-menu'
import { Project } from '@/plugins/abbd-api/sdk/endpoints/v1/wexaus/projects'

const getRouteFromRedirectQuery = (redirectUri: string, router: any, projects: Project[]) => {
  const decodedRedirect = decodeURIComponent(redirectUri)
  const resolvedRoute = router.resolve(decodedRedirect)

  const projectExists = Boolean(projects.find(project => project.id === resolvedRoute.route.params.projectId))

  if (projectExists) {
    return resolvedRoute
  }
}

const homeRedirect: Middleware = ({ store, redirect, app, route }: Context) => {
  const ctx = { root: { $store: store, $route: route } } as SetupContext
  const { defaultMenuItemName } = useSidebarMenu({ ctx })
  const projects: Project[] = store.getters['projects/projects']
  const selectedProject: Project = store.getters['projects/selectedProject']
  const resolvedRoute = getRouteFromRedirectQuery(route.query.redirect as string, app.router, projects)

  let routeToRedirectTo = {}

  if (!projects.length) {
    routeToRedirectTo = { name: 'no-projects' }
  } else if (!defaultMenuItemName.value) {
    routeToRedirectTo = {
      name: 'projectId-resultats-no-features',
      params: {
        projectId: selectedProject.id,
      },
    }
  } else if (route.query.redirect && decodeURIComponent(route.query.redirect as string).search('/services/') !== -1) {
    redirect(`//${window.location.host}${decodeURIComponent(route.query.redirect as string)}`)
  } else if (route.query.redirect && resolvedRoute) {
    store.dispatch('projects/selectProject', resolvedRoute.route.params.projectId)
    redirect(resolvedRoute.route)
  } else {
    routeToRedirectTo = {
      name: defaultMenuItemName.value,
      params: {
        projectId: selectedProject.id,
      },
    }
  }

  redirect(app.localePath(routeToRedirectTo))
}

export default homeRedirect
