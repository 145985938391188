

























import { Vue, Component, Getter } from 'nuxt-property-decorator'
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules'
// NOTE: Component Decorator
@Component({
  name: 'CellEditorSelect',
})
// NOTE: Component Class
export default class CellEditorSelect extends Vue {
  // NOTE: Store
  @Getter('dashboard/viewName') viewName!: string
  // NOTE: Data
  params!: ICellRendererParams
  isFreeText: boolean = false
  selectedValue: string = ''
  options: { text: string, value: any }[] = []
  loading: boolean = false
  // NOTE: Methods
  isPopup () {
    return true
  }

  getValue () {
    return this.selectedValue
  }

  getAsyncOptions () {
    this.loading = true

    this.$abbd.dataTable.listofchoices({
      uriParams: {
        projectId: this.$route.params.projectId,
        tableName: this.params.context.getViewConfig().tableName,
        columnName: this.params.colDef.colId as string,
        viewName: this.$accessor.dashboard.prefilteredView,
      },
      params: {
        timestamp: this.params.context.getViewConfig().timestamp,
      },
    })
      .then((res) => {
        // Activate free text typing
        this.isFreeText = res.data.editable
        this.loading = false
        let resValueKey: string
        if (res.data.filterWithColumn && this.params.data[res.data.filterWithColumn]) {
          resValueKey = this.params.data[res.data.filterWithColumn]
        } else {
          resValueKey = Object.keys(res.data.values)[0]
        }
        this.options = res.data.values[resValueKey]
          .map((value: any) => ({ text: value, value }))
      })
  }

  created () {
    this.selectedValue = this.params.value
    if (this.params.colDef.cellEditorParams) {
      if (this.params.colDef.cellEditorParams.isAsyncOptions) {
        this.getAsyncOptions()
      }
      if (this.params.colDef.cellEditorParams.listOfChoices) {
        this.options = this.params.colDef.cellEditorParams.listOfChoices
          .map((option: any) => ({
            ...option,
            text: this.$t(option.text),
          }))
      }
    }
  }
}
