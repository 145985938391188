import { getAccessorType } from 'typed-vuex'

import dashboardState, { DashboardState } from '@/store/dashboard/state'
import dashboardActions from '@/store/dashboard/actions'
import dashboardMutations from '@/store/dashboard/mutations'
import dashboardGetters from '@/store/dashboard/getters'

import filesState, { FilesState } from '@/store/files/state'
import filesActions from '@/store/files/actions'
import filesMutations from '@/store/files/mutations'
import filesGetters from '@/store/files/getters'

import browserState from '@/store/files/browser/state'
import browserActions from '@/store/files/browser/actions'
import browserMutations from '@/store/files/browser/mutations'
import browserGetters from '@/store/files/browser/getters'

import uploaderState from '@/store/files/uploader/state'
import uploaderActions from '@/store/files/uploader/actions'
import uploaderMutations from '@/store/files/uploader/mutations'
import uploaderGetters from '@/store/files/uploader/getters'

import projectsState, { ProjectsState } from '@/store/projects/state'
import projectsActions from '@/store/projects/actions'
import projectsMutations from '@/store/projects/mutations'
import projectsGetters from '@/store/projects/getters'

import userState, { UserState } from '@/store/user/state'
import userActions from '@/store/user/actions'
import userMutations from '@/store/user/mutations'
import userGetters from '@/store/user/getters'

import dataTable from './dataTable'

export interface RootState {
  dashboard: () => DashboardState
  files: () => FilesState
  i18n: any
  projects: () => ProjectsState
  route: any
  user: () => UserState
}

const dashboard = {
  state: dashboardState,
  actions: dashboardActions,
  mutations: dashboardMutations,
  getters: dashboardGetters,
}

const browser = {
  state: browserState,
  actions: browserActions,
  mutations: browserMutations,
  getters: browserGetters,
}

const uploader = {
  state: uploaderState,
  actions: uploaderActions,
  mutations: uploaderMutations,
  getters: uploaderGetters,
}

const files = {
  state: filesState,
  actions: filesActions,
  mutations: filesMutations,
  getters: filesGetters,
  modules: {
    browser,
    uploader,
  },
}

const projects = {
  state: projectsState,
  actions: projectsActions,
  mutations: projectsMutations,
  getters: projectsGetters,
}

const user = {
  state: userState,
  actions: userActions,
  mutations: userMutations,
  getters: userGetters,
}

// This compiles to nothing and only serves to return the correct type of the accessor
export const accessorType = getAccessorType({
  state () {},
  actions: {},
  mutations: {},
  getters: {},
  modules: {
    dashboard,
    files,
    projects,
    user,
    dataTable,
  },
})
