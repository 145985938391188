import { mutationTree } from 'typed-vuex'
import { Filestore } from '@/plugins/abbd-api/sdk/endpoints/v1/wexaus/projects/_projectId/filestore'

import state from './state'

const mutations = mutationTree(state, {
  GET_S3_CREDENTIALS (state, payload: Filestore): void {
    state.s3Credentials = payload
  },
})

export default mutations
