import { ICellRendererComp, ICellRendererParams } from '@ag-grid-enterprise/all-modules'

export class ValideIcon implements ICellRendererComp {
  eGui: HTMLElement = document.createElement('div')

  init (params: ICellRendererParams) {
    if (params.node.rowPinned) return

    const isEditable = typeof params.colDef.editable === 'function' && params.colDef.editable(params)
    const editabletemplate = `
      <a class="enable-edit w-4 text-center">
        <i class="fas fa-angle-down text-secondary ml-auto"></i>
      </a>
    `

    this.eGui.className = 'text-center d-flex align-items-center h-100 justify-center'

    let iconTemplate = ''
    if (params.value === false) {
      iconTemplate = '<i class="fas fa-xmark text-danger" data-test="bool-false"></i>'
    } else if (params.value === true) {
      iconTemplate = '<i class="fas fa-check text-success" data-test="bool-true"></i>'
    } else {
      iconTemplate = '<i class="fas fa-hourglass-half text-gray-500" data-test="bool-null"></i>'
    }

    this.eGui.innerHTML = `
      <div class="flex-1 text-center">${iconTemplate}</div>
      ${isEditable ? editabletemplate : ''}
    `

    const editCaret: HTMLElement | null = this.eGui.querySelector('.enable-edit')
    if (editCaret) {
      editCaret.onclick = () => params.api.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.column,
      })
    }
  }

  refresh () {
    return true
  }

  getGui () {
    return this.eGui
  }
}
