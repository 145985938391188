import { SetupContext } from '@vue/composition-api'

interface Options {
  ctx: SetupContext
}

export default ({ ctx }: Options) => {
  const defaultMsgBox = {
    size: 'sm',
    buttonSize: 'sm',
    contentClass: 'pt-8',
    bodyClass: 'text-center',
    footerClass: 'p-2 border-top-0',
    centered: true,
  }

  const lockedMsgBox = {
    noCloseOnBackdrop: true,
    noCloseOnEsc: true,
  }

  const dashboardStructureErr = () => ctx.root.$bvModal
    .msgBoxOk(ctx.root.$t('resultats.global.dashboardStructureErr.message') as string, {
      ...defaultMsgBox,
      ...lockedMsgBox,
      okTitle: ctx.root.$t('resultats.global.dashboardStructureErr.okButton') as string,
    })
    .then(() => window.location.reload())

  return {
    dashboardStructureErr,
  }
}
