import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2839defe = () => interopDefault(import('../src/pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _548b2480 = () => interopDefault(import('../src/pages/admin/home.vue' /* webpackChunkName: "pages/admin/home" */))
const _126ae9fe = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _99eab562 = () => interopDefault(import('../src/pages/forgotten-password.vue' /* webpackChunkName: "pages/forgotten-password" */))
const _7f1ba3d0 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _badcdc16 = () => interopDefault(import('../src/pages/no-projects.vue' /* webpackChunkName: "pages/no-projects" */))
const _965fafce = () => interopDefault(import('../src/pages/resetpassword.vue' /* webpackChunkName: "pages/resetpassword" */))
const _c8a2ab14 = () => interopDefault(import('../src/pages/_projectId.vue' /* webpackChunkName: "pages/_projectId" */))
const _2430211c = () => interopDefault(import('../src/pages/_projectId/analysis/free-anomaly.vue' /* webpackChunkName: "pages/_projectId/analysis/free-anomaly" */))
const _22af5a60 = () => interopDefault(import('../src/pages/_projectId/resultats/duplicate-suppliers.vue' /* webpackChunkName: "pages/_projectId/resultats/duplicate-suppliers" */))
const _0fc7c044 = () => interopDefault(import('../src/pages/_projectId/resultats/no-features.vue' /* webpackChunkName: "pages/_projectId/resultats/no-features" */))
const _41645b62 = () => interopDefault(import('../src/pages/_projectId/resultats/suppliers.vue' /* webpackChunkName: "pages/_projectId/resultats/suppliers" */))
const _35b8dc56 = () => interopDefault(import('../src/pages/_projectId/resultats/vue-globale.vue' /* webpackChunkName: "pages/_projectId/resultats/vue-globale" */))
const _806f6b4e = () => interopDefault(import('../src/pages/_projectId/resultats/vue-globale/index.vue' /* webpackChunkName: "pages/_projectId/resultats/vue-globale/index" */))
const _327a46a3 = () => interopDefault(import('../src/pages/_projectId/resultats/vue-globale/anomalie/_anomalyId.vue' /* webpackChunkName: "pages/_projectId/resultats/vue-globale/anomalie/_anomalyId" */))
const _3734af04 = () => interopDefault(import('../src/pages/_projectId/resultats/vue-globale/anomalie/_anomalyId/mouvements/_entryId.vue' /* webpackChunkName: "pages/_projectId/resultats/vue-globale/anomalie/_anomalyId/mouvements/_entryId" */))
const _9b09329e = () => interopDefault(import('../src/pages/_projectId/resultats/vue-globale/mouvements/_entryId.vue' /* webpackChunkName: "pages/_projectId/resultats/vue-globale/mouvements/_entryId" */))
const _d7758216 = () => interopDefault(import('../src/pages/_projectId/transferts/envoyer-des-fichiers.vue' /* webpackChunkName: "pages/_projectId/transferts/envoyer-des-fichiers" */))
const _2e798810 = () => interopDefault(import('../src/pages/_projectId/transferts/gerer-les-fichiers.vue' /* webpackChunkName: "pages/_projectId/transferts/gerer-les-fichiers" */))
const _78ef14c6 = () => interopDefault(import('../src/pages/_projectId/resultats/dashboards/_vueName.vue' /* webpackChunkName: "pages/_projectId/resultats/dashboards/_vueName" */))
const _a821132c = () => interopDefault(import('../src/pages/_projectId/resultats/dashboards/_vueName/anomalie/_anomalyId.vue' /* webpackChunkName: "pages/_projectId/resultats/dashboards/_vueName/anomalie/_anomalyId" */))
const _1d57a276 = () => interopDefault(import('../src/pages/_projectId/resultats/dashboards/_vueName/anomalie/_anomalyId/mouvements/_entryId.vue' /* webpackChunkName: "pages/_projectId/resultats/dashboards/_vueName/anomalie/_anomalyId/mouvements/_entryId" */))
const _2bf09678 = () => interopDefault(import('../src/pages/_projectId/resultats/dashboards/_vueName/mouvements/_entryId.vue' /* webpackChunkName: "pages/_projectId/resultats/dashboards/_vueName/mouvements/_entryId" */))
const _29735c46 = () => interopDefault(import('../src/pages/_projectId/resultats/synthese/_syntheseName.vue' /* webpackChunkName: "pages/_projectId/resultats/synthese/_syntheseName" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _2839defe,
    name: "admin___fr___default",
    children: [{
      path: "home",
      component: _548b2480,
      name: "admin-home___fr___default"
    }]
  }, {
    path: "/en",
    component: _126ae9fe,
    name: "index___en"
  }, {
    path: "/forgotten-password",
    component: _99eab562,
    name: "forgotten-password___fr___default"
  }, {
    path: "/fr",
    component: _126ae9fe,
    name: "index___fr"
  }, {
    path: "/login",
    component: _7f1ba3d0,
    name: "login___fr___default"
  }, {
    path: "/no-projects",
    component: _badcdc16,
    name: "no-projects___fr___default"
  }, {
    path: "/resetpassword",
    component: _965fafce,
    name: "resetpassword___fr___default"
  }, {
    path: "/en/admin",
    component: _2839defe,
    name: "admin___en",
    children: [{
      path: "home",
      component: _548b2480,
      name: "admin-home___en"
    }]
  }, {
    path: "/en/forgotten-password",
    component: _99eab562,
    name: "forgotten-password___en"
  }, {
    path: "/en/login",
    component: _7f1ba3d0,
    name: "login___en"
  }, {
    path: "/en/no-projects",
    component: _badcdc16,
    name: "no-projects___en"
  }, {
    path: "/en/resetpassword",
    component: _965fafce,
    name: "resetpassword___en"
  }, {
    path: "/fr/admin",
    component: _2839defe,
    name: "admin___fr",
    children: [{
      path: "home",
      component: _548b2480,
      name: "admin-home___fr"
    }]
  }, {
    path: "/fr/forgotten-password",
    component: _99eab562,
    name: "forgotten-password___fr"
  }, {
    path: "/fr/login",
    component: _7f1ba3d0,
    name: "login___fr"
  }, {
    path: "/fr/no-projects",
    component: _badcdc16,
    name: "no-projects___fr"
  }, {
    path: "/fr/resetpassword",
    component: _965fafce,
    name: "resetpassword___fr"
  }, {
    path: "/en/:projectId",
    component: _c8a2ab14,
    name: "projectId___en",
    children: [{
      path: "analysis/free-anomaly",
      component: _2430211c,
      name: "projectId-analysis-free-anomaly___en"
    }, {
      path: "resultats/duplicate-suppliers",
      component: _22af5a60,
      name: "projectId-resultats-duplicate-suppliers___en"
    }, {
      path: "resultats/no-features",
      component: _0fc7c044,
      name: "projectId-resultats-no-features___en"
    }, {
      path: "resultats/suppliers",
      component: _41645b62,
      name: "projectId-resultats-suppliers___en"
    }, {
      path: "resultats/vue-globale",
      component: _35b8dc56,
      children: [{
        path: "",
        component: _806f6b4e,
        name: "projectId-resultats-vue-globale___en"
      }, {
        path: "anomalie/:anomalyId?",
        component: _327a46a3,
        name: "projectId-resultats-vue-globale-anomalie-anomalyId___en",
        children: [{
          path: "mouvements/:entryId?",
          component: _3734af04,
          name: "projectId-resultats-vue-globale-anomalie-anomalyId-mouvements-entryId___en"
        }]
      }, {
        path: "mouvements/:entryId?",
        component: _9b09329e,
        name: "projectId-resultats-vue-globale-mouvements-entryId___en"
      }]
    }, {
      path: "transferts/envoyer-des-fichiers",
      component: _d7758216,
      name: "projectId-transferts-envoyer-des-fichiers___en"
    }, {
      path: "transferts/gerer-les-fichiers",
      component: _2e798810,
      name: "projectId-transferts-gerer-les-fichiers___en"
    }, {
      path: "resultats/dashboards/:vueName?",
      component: _78ef14c6,
      name: "projectId-resultats-dashboards-vueName___en",
      children: [{
        path: "anomalie/:anomalyId?",
        component: _a821132c,
        name: "projectId-resultats-dashboards-vueName-anomalie-anomalyId___en",
        children: [{
          path: "mouvements/:entryId?",
          component: _1d57a276,
          name: "projectId-resultats-dashboards-vueName-anomalie-anomalyId-mouvements-entryId___en"
        }]
      }, {
        path: "mouvements/:entryId?",
        component: _2bf09678,
        name: "projectId-resultats-dashboards-vueName-mouvements-entryId___en"
      }]
    }, {
      path: "resultats/synthese/:syntheseName?",
      component: _29735c46,
      name: "projectId-resultats-synthese-syntheseName___en"
    }]
  }, {
    path: "/fr/:projectId",
    component: _c8a2ab14,
    name: "projectId___fr",
    children: [{
      path: "analysis/free-anomaly",
      component: _2430211c,
      name: "projectId-analysis-free-anomaly___fr"
    }, {
      path: "resultats/duplicate-suppliers",
      component: _22af5a60,
      name: "projectId-resultats-duplicate-suppliers___fr"
    }, {
      path: "resultats/no-features",
      component: _0fc7c044,
      name: "projectId-resultats-no-features___fr"
    }, {
      path: "resultats/suppliers",
      component: _41645b62,
      name: "projectId-resultats-suppliers___fr"
    }, {
      path: "resultats/vue-globale",
      component: _35b8dc56,
      children: [{
        path: "",
        component: _806f6b4e,
        name: "projectId-resultats-vue-globale___fr"
      }, {
        path: "anomalie/:anomalyId?",
        component: _327a46a3,
        name: "projectId-resultats-vue-globale-anomalie-anomalyId___fr",
        children: [{
          path: "mouvements/:entryId?",
          component: _3734af04,
          name: "projectId-resultats-vue-globale-anomalie-anomalyId-mouvements-entryId___fr"
        }]
      }, {
        path: "mouvements/:entryId?",
        component: _9b09329e,
        name: "projectId-resultats-vue-globale-mouvements-entryId___fr"
      }]
    }, {
      path: "transferts/envoyer-des-fichiers",
      component: _d7758216,
      name: "projectId-transferts-envoyer-des-fichiers___fr"
    }, {
      path: "transferts/gerer-les-fichiers",
      component: _2e798810,
      name: "projectId-transferts-gerer-les-fichiers___fr"
    }, {
      path: "resultats/dashboards/:vueName?",
      component: _78ef14c6,
      name: "projectId-resultats-dashboards-vueName___fr",
      children: [{
        path: "anomalie/:anomalyId?",
        component: _a821132c,
        name: "projectId-resultats-dashboards-vueName-anomalie-anomalyId___fr",
        children: [{
          path: "mouvements/:entryId?",
          component: _1d57a276,
          name: "projectId-resultats-dashboards-vueName-anomalie-anomalyId-mouvements-entryId___fr"
        }]
      }, {
        path: "mouvements/:entryId?",
        component: _2bf09678,
        name: "projectId-resultats-dashboards-vueName-mouvements-entryId___fr"
      }]
    }, {
      path: "resultats/synthese/:syntheseName?",
      component: _29735c46,
      name: "projectId-resultats-synthese-syntheseName___fr"
    }]
  }, {
    path: "/",
    component: _126ae9fe,
    name: "index___fr___default"
  }, {
    path: "/:projectId",
    component: _c8a2ab14,
    name: "projectId___fr___default",
    children: [{
      path: "analysis/free-anomaly",
      component: _2430211c,
      name: "projectId-analysis-free-anomaly___fr___default"
    }, {
      path: "resultats/duplicate-suppliers",
      component: _22af5a60,
      name: "projectId-resultats-duplicate-suppliers___fr___default"
    }, {
      path: "resultats/no-features",
      component: _0fc7c044,
      name: "projectId-resultats-no-features___fr___default"
    }, {
      path: "resultats/suppliers",
      component: _41645b62,
      name: "projectId-resultats-suppliers___fr___default"
    }, {
      path: "resultats/vue-globale",
      component: _35b8dc56,
      children: [{
        path: "",
        component: _806f6b4e,
        name: "projectId-resultats-vue-globale___fr___default"
      }, {
        path: "anomalie/:anomalyId?",
        component: _327a46a3,
        name: "projectId-resultats-vue-globale-anomalie-anomalyId___fr___default",
        children: [{
          path: "mouvements/:entryId?",
          component: _3734af04,
          name: "projectId-resultats-vue-globale-anomalie-anomalyId-mouvements-entryId___fr___default"
        }]
      }, {
        path: "mouvements/:entryId?",
        component: _9b09329e,
        name: "projectId-resultats-vue-globale-mouvements-entryId___fr___default"
      }]
    }, {
      path: "transferts/envoyer-des-fichiers",
      component: _d7758216,
      name: "projectId-transferts-envoyer-des-fichiers___fr___default"
    }, {
      path: "transferts/gerer-les-fichiers",
      component: _2e798810,
      name: "projectId-transferts-gerer-les-fichiers___fr___default"
    }, {
      path: "resultats/dashboards/:vueName?",
      component: _78ef14c6,
      name: "projectId-resultats-dashboards-vueName___fr___default",
      children: [{
        path: "anomalie/:anomalyId?",
        component: _a821132c,
        name: "projectId-resultats-dashboards-vueName-anomalie-anomalyId___fr___default",
        children: [{
          path: "mouvements/:entryId?",
          component: _1d57a276,
          name: "projectId-resultats-dashboards-vueName-anomalie-anomalyId-mouvements-entryId___fr___default"
        }]
      }, {
        path: "mouvements/:entryId?",
        component: _2bf09678,
        name: "projectId-resultats-dashboards-vueName-mouvements-entryId___fr___default"
      }]
    }, {
      path: "resultats/synthese/:syntheseName?",
      component: _29735c46,
      name: "projectId-resultats-synthese-syntheseName___fr___default"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
