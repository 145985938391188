import Vue from 'vue'
import { actionTree } from 'typed-vuex'
import state from './state'
import mutations from './mutations'
import getters from './getters'

const actions = actionTree({ state, mutations, getters }, {
  getCredentials ({ commit, dispatch }, projectId): Promise<any> {
    return Vue.$abbd.projects.s3Credentials({ uriParams: { projectId } })
      .then(async (result) => {
        if (!result) return

        const credentials: any = new Vue.$aws.instance.Credentials({
          accessKeyId: result.data.credentials.accessKeyId,
          secretAccessKey: result.data.credentials.secretAccessKey,
          sessionToken: result.data.credentials.sessionToken,
        })

        credentials.expireTime = result.data.credentials.expiration

        credentials.refresh = async function (callback: Function) {
          await dispatch('getCredentials', projectId)
          await callback()
        }

        if (!Vue.$aws.s3.config) {
          await Vue.$aws.initS3(result.data.region)
        }

        await Vue.$aws.s3.config.update({
          region: result.data.region,
          credentials,
        })

        commit('GET_S3_CREDENTIALS', result.data)
      }).catch((error) => {
        console.info(error)
      })
  },

  async checkObjectExists ({ getters }, Key) {
    const prefix = `${getters.s3Credentials?.prefix}/`
    let objectExists

    await Vue.$aws.s3
      .headObject({
        Bucket: getters.s3Credentials?.name as string,
        Key: `${prefix}${Key}`,
      })
      .promise()
      .then(() => {
        objectExists = true
      })
      .catch(() => {
        objectExists = false
      })

    return objectExists
  },
})

export default actions
