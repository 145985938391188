import { mutationTree } from 'typed-vuex'
import { ColGroupDef } from '@ag-grid-enterprise/all-modules'
import { DashboardMetadata } from '@/plugins/abbd-api/sdk/endpoints/v1/wexaus/projects/_projectId/dashboard/metadata'
import state from './state'

const mutations = mutationTree(state, {
  SET_COLUMN_DEFS (state, colDefs: ColGroupDef[]) {
    state.columnDefs = colDefs
  },

  SET_META (state, meta: DashboardMetadata) {
    state.meta = meta
  },

  SET_DATA (state, data: any) {
    state.data = data
  },

  SET_ANOMALY_ID_COLUMN (state, anomalyIdColumn: string) {
    state.anomalyIdColumn = anomalyIdColumn
  },

  SET_VIEW_NAME (state, viewName: string) {
    state.viewName = viewName
  },

  SET_FOOTER_SUM_COLS (state, footerSumCols: string[]) {
    state.footerSumCols = footerSumCols
  },

  SET_PREFILTERED_VIEW (state, prefilteredView: string) {
    state.prefilteredView = prefilteredView
  },
  SET_MODAL_CONFIG (state, modalConfig) {
    state.modalConfig = modalConfig
  },
  SET_ANOAMLY_CARD_DESIGN (state, anomalyCardDesign: any) {
    state.anomalyCardDesign = anomalyCardDesign
  },
  SET_COLUMN_DEFS_FROM_DESIGN (state, columnDefsFromDesign: any) {
    state.columnDefsFromDesign = columnDefsFromDesign
  },
})

export default mutations
