





























































import { defineComponent, reactive, PropType } from '@vue/composition-api'
import { GridApi, RowNode } from '@ag-grid-enterprise/all-modules'
import { Formfield, FileBrowserObj } from '@@/types'
import useModal from '@/composables/pages/transferts/gerer-les-resultats/use-modal'
import useModalsLoadingStatus from '@/composables/pages/transferts/gerer-les-resultats/use-modals-loading-status'
import useMoveObjects from '@/composables/pages/transferts/gerer-les-resultats/use-move-objects'
import { getHighestParentsFromList } from '@/components/pages/transferts/gerer-les-fichiers/helpers'

export default defineComponent({
  name: 'ModalRenameObject',
  props: {
    gridApi: {
      type: Object as PropType<GridApi>,
      required: true,
    },
  },
  setup (props, ctx) {
    const { modalConfig, setModal, modalDefaultConfig, isModalOpen } = useModal({ ctx, modalName: 'ModalDeleteObjects' })
    const { isLoading, submitButtonStatus, preventClosingWhileLoading } = useModalsLoadingStatus({
      ctx,
      submitBtnI18nKey: 'transferts.gerer.modals.renameObject.form.okBtn',
    })

    const { s3CopyAndDelete } = useMoveObjects({ ctx, gridOptions: { api: props.gridApi } })

    const form = reactive({
      newName: modalConfig.value.data.originObject.path.slice(-1).pop().split('.')[0],
    })

    const formFields = reactive<Formfield[]>([
      {
        component: 'FormInput',
        model: 'newName',
        labelI18nKey: 'transferts.gerer.modals.renameObject.form.newName',
        validation: {
          required: true,
        },
        group: {
          id: 'new-name-group',
          labelFor: 'new-name',
        },
        input: {
          id: 'new-name',
          autofocus: true,
        },
        provider: {
          immediate: true,
        },
      },
    ])

    const onSubmit = async (e: Event) => {
      e.preventDefault()
      if (form.newName && form.newName !== '') {
        const sourceNodes: RowNode[] = [props.gridApi.getRowNode(modalConfig.value.data.originObject.key) as RowNode]

        const renameRow = (node: RowNode, sourceRow: FileBrowserObj, newObjectName: string) => {
          const sourceKeyAsArray: string[] = sourceRow.key
            .split('/')
            .filter(fragment => fragment)

          const sourceObjectName: string = `${sourceKeyAsArray.slice(0, sourceKeyAsArray.length - 1).join('/')}/`
          const sourceSuffix: string = sourceRow.type === 'file' ? `.${sourceRow.key.split('.').pop()}` : '/'
          const newObjName = `${sourceObjectName}${newObjectName}${sourceSuffix}`

          return node.id?.replace(sourceRow.key, newObjName.replace(/\$/g, '$$$$'))
        }

        const newNames = (props.gridApi.getRowNode(modalConfig.value.data.originObject.key) as RowNode).allLeafChildren
          .map(node => ({
            oldKey: node.id,
            newKey: renameRow(node, modalConfig.value.data.originObject, form.newName),
          }))

        const alreadyExists = (fileBrowserList: FileBrowserObj[], renamedRows: any[]) => {
          return Boolean(fileBrowserList.find(row => renamedRows.find((newName: any) => newName.newKey === row.key)))
        }

        if (!alreadyExists(ctx.root.$store.getters['files/browser/fileBrowserList'], newNames)) {
          await s3CopyAndDelete(sourceNodes, newNames)

          const newNodes = newNames.map(row => props.gridApi.getRowNode(row.newKey as string)) as RowNode[]
          const newMasterNode = getHighestParentsFromList(newNodes)

          if (newMasterNode.length) {
            newMasterNode[0].setExpanded(true)
          }

          setModal({})
        } else {
          ctx.root.$toast.error(ctx.root.$t('transferts.gerer.cantRename') as string, { icon: 'xmark' })
        }
      }
    }

    return {
      modalConfig,
      setModal,
      modalDefaultConfig,
      isModalOpen,
      isLoading,
      submitButtonStatus,
      preventClosingWhileLoading,
      form,
      formFields,
      onSubmit,
    }
  },
})
