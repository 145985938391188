import Vue from 'vue'
import { Context } from '@nuxt/types'
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate'
import { required, email, regex, confirmed, min } from 'vee-validate/dist/rules'

export default ({ app }: Context) => {
  configure({
    defaultMessage: (_, values = {}) => app.i18n ? app.i18n.t(`validations.${values._rule_}`, values) as string : '',
  })

  extend('required', required)
  extend('email', email)
  extend('regex', regex)
  extend('confirmed', confirmed)
  extend('min', min)

  Vue.component('ValidationProvider', ValidationProvider)
  Vue.component('ValidationObserver', ValidationObserver)
}
