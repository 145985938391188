import { SetupContext, computed, reactive } from '@vue/composition-api'

interface Options {
  ctx: SetupContext
  modalName: string
}

export default function useModal ({ ctx, modalName }: Options) {
  const modalConfig = computed(() => ctx.root.$store.getters['files/uploader/modalConfig'])
  const setModal = (modalConfig: any) => ctx.root.$store.dispatch('files/uploader/setModal', modalConfig)
  const isModalOpen = computed(() => modalConfig.value.name === modalName)
  const modalDefaultConfig = reactive({
    size: 'md',
    centered: true,
    footerClass: 'p-2',
    okVariant: 'primary',
    cancelVariant: 'link',
    hideHeaderClose: false,
    bodyClass: 'text-center',
  })

  return {
    modalConfig,
    setModal,
    isModalOpen,
    modalDefaultConfig,
  }
}
