import { SetupContext, Ref } from '@vue/composition-api'
import { GridOptions } from '@ag-grid-enterprise/all-modules'

interface Options {
  ctx: SetupContext
  gridOptions: Ref<GridOptions>
}
export default function useDuplicateSupplierUpdateCells ({ ctx, gridOptions }: Options) {
  const updateMutipleCellsAndRows = (body: any) => {
    const queryOptions = {
      uriParams: {
        projectId: ctx.root.$accessor.projects.selectedProject?.id || '',
      },
      params: {
        timestamp: ctx.root.$accessor.dataTable.metadata?.viewMetaData?.timestamp,
      },
    }

    return ctx.root.$abbd.projects.duplicateSuppliers.updateMultiple(body, queryOptions)
      .then((res) => {
        refreshAfterUpdate(res.data)
      })
  }

  const refreshAfterUpdate = ({ updatedRows }: any) => {
    const keyColumns = ctx.root.$accessor.dataTable.metadata?.viewMetaData?.keyColumns

    const mutatedRows = updatedRows.values.map((row: any) => {
      const rowId = keyColumns?.map((key: string) => row[key]).join('$$')

      return {
        ...gridOptions.value.api?.getRowNode(rowId || '')?.data,
        ...row,
      }
    })

    if (mutatedRows) {
      const updatedNodes = gridOptions.value.api?.applyTransaction({ update: mutatedRows })
      gridOptions.value.api?.redrawRows({ rowNodes: updatedNodes?.update })
    }
  }

  return {
    updateMutipleCellsAndRows,
    refreshAfterUpdate,
  }
}
