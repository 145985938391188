import { Model, Serializer, Server, Response } from 'miragejs'
import users from './fixtures/wexaus/users'
import projects from './fixtures/wexaus/projects'
import fileStores from './fixtures/wexaus/projects/__projectId/filestore'
import dashboardMetadata from './fixtures/wexaus/projects/__projectId/dashboard/metadata'
import dashboardData from './fixtures/wexaus/projects/__projectId/dashboard/data'
import synthesisMetadata from './fixtures/wexaus/projects/__projectId/dashboard/synthesis/metadata'
import synthesisData from './fixtures/wexaus/projects/__projectId/dashboard/synthesis/data'

import s3GetObject from './fixtures/aws-s3/__projectId/getObjects'

const CleanReturnSerializer = Serializer.extend({
  root: false,
  embed: true,
  serialize () {
    const json = Serializer.prototype.serialize.apply(this, arguments)
    delete json.projectId
    delete json.syntheseId
    return json
  },
})

const S3ReturnSerializer = Serializer.extend({
  root: false,
  embed: true,
  serialize () {
    const json = Serializer.prototype.serialize.apply(this, arguments)

    console.info(json) // eslint-disable-line

    return json.content
  },
})

const makeWexausServer = function makeWexausServer ({ environment = 'development' } = {}) {
  const server = new Server({
    environment,
    models: {
      user: Model,
      project: Model,
      fileStore: Model,
      dashboardMetadata: Model,
      dashboardData: Model,
      synthesisMetadata: Model,
      synthesisData: Model,
      s3GetObject: Model,
    },
    fixtures: {
      users,
      projects,
      fileStores,
      dashboardMetadata,
      dashboardData,
      synthesisMetadata,
      synthesisData,
      s3GetObject,
    },
    serializers: {
      application: CleanReturnSerializer,
      s3GetObject: S3ReturnSerializer,
    },
    seeds (server) {
      server.loadFixtures()
    },
    routes () {
      // Wexaus endpoints
      this.get('/services/auth', (schema, request) => {
        return schema.findBy('user', { login: request.requestBody.login })
      })

      this.get('/services/v1/wexaus/projects', (schema) => {
        return schema.all('project')
      })

      this.get('/services/v1/wexaus/projects/:projectId/filestore', (schema, request) => {
        return schema.findBy('fileStore', { projectId: request.params.projectId })
      })

      this.get('/services/v1/wexaus/projects/:projectId/dashboard/metadata', (schema, request) => {
        return schema.findBy('dashboardMetadata', { projectId: request.params.projectId })
      })

      this.get('/services/v1/wexaus/projects/:projectId/dashboard/data', (schema, request) => {
        return schema.findBy('dashboardData', { projectId: request.params.projectId })
      })

      this.get('/services/v1/wexaus/projects/:projectId/dashboard/synthesis/metadata', (schema, request) => {
        return schema.findBy('synthesisMetadata', { projectId: request.params.projectId })
      })

      this.get('/services/v1/wexaus/projects/:projectId/dashboard/synthesis/data/:syntheseId', (schema, request) => {
        return schema.findBy('synthesisData', {
          projectId: request.params.projectId,
          syntheseId: request.params.syntheseId,
        })
      })

      // AWS S3 mock
      this.namespace = 'https://s3.eu-west-3.amazonaws.com'

      this.get('https://s3.eu-west-3.amazonaws.com/dev.9ef4470d-9cd6-4765-acf8-8c9acb5c8ce5', (schema, request) => {
        const projectId = `_${request.queryParams.prefix}`.replace(/\/$/, '')
        console.info(projectId) // eslint-disable-line
        console.info(schema.findBy('s3GetObject', { projectId })) // eslint-disable-line

        if (request.queryParams['list-type'] === 2) {
          return new Response(
            200,
            { 'Content-Type': 'application/xml' },
            'kj',
          )
        }
      })

      // Passthrough if endpoint not defined
      this.passthrough()
    },
  })

  return server
}

if (process.env.mockApi) {
  makeWexausServer()
}
