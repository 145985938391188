




































import { defineComponent, PropType } from '@vue/composition-api'
import { GridApi } from '@ag-grid-enterprise/all-modules'
import useModal from '@/composables/pages/transferts/gerer-les-resultats/use-modal'
import FileUploader from '@/components/common/FileUploader.vue'

export default defineComponent({
  name: 'ModalAddFile',
  components: {
    FileUploader,
  },
  props: {
    gridApi: {
      type: Object as PropType<GridApi>,
      required: true,
    },
  },
  setup (props, ctx) {
    const { modalConfig, setModal, modalDefaultConfig, isModalOpen } = useModal({ ctx, modalName: 'ModalAddFiles' })

    const closeModal = () => {
      // await ctx.root.$store.dispatch('files/browser/getObjects')
      const target = props.gridApi.getRowNode(modalConfig.value.data.target.key)

      if (target) {
        target.setExpanded(true)
      }
      setTimeout(() => {
        props.gridApi.redrawRows()
      }, 300)
      setModal({})
    }

    const openCancelModal = (e: Event) => {
      if (ctx.root.$store.getters['files/uploader/isUploading']) {
        e.preventDefault()
        ctx.root.$bvModal.show('modal-cancel-uploads')
      } else {
        setModal({})
      }
    }

    const confirmCancel = async () => {
      await ctx.root.$store.dispatch('files/uploader/cancelUpload')
      ctx.root.$bvModal.hide('modal-cancel-uploads')
      closeModal()
    }

    return {
      modalConfig,
      setModal,
      modalDefaultConfig,
      isModalOpen,
      closeModal,
      openCancelModal,
      confirmCancel,
    }
  },
})
