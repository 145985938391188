import Vue from 'vue'
import { actionTree } from 'typed-vuex'
import { LoginFormModel } from '@@/types'
import state from './state'
import mutations from './mutations'
import getters from './getters'

const actions = actionTree({ state, mutations, getters }, {
  authenticate ({ commit }, payload: LoginFormModel): Promise<any> {
    let body
    if (payload.authStrategy === 'azureToken') {
      body = {
        authStrategy: payload.authStrategy,
        azureToken: payload.azureToken,
      }
    } else {
      body = {
        authStrategy: payload.authStrategy,
        login: payload.login,
        plainPassword: payload.plainPassword,
      }
    }
    return Vue.$abbd.auth.login(body)
      .then((result) => {
        if (result) {
          commit('UPDATE_USER', result.data)
          if (payload.rememberMe) {
            window.localStorage.setItem('rememberMe', 'true')
          }
          return result
        }
      })
      .catch((error) => {
        return error
      })
  },
  resetPasswordAuth ({ commit }, data) {
    commit('UPDATE_USER', data)
  },
  updateAuth ({ commit }, payload): void {
    commit('UPDATE_AUTH', payload)
  },
  async logout ({ commit }): Promise<any> {
    this.app.$accessor.projects.CLEAR_PROJECTS()
    commit('KILL_AUTH')
    commit('LOGOUT')
    window.localStorage.removeItem('rememberMe')
    await Promise.resolve()
  },
  sendPasswordRecoveryEmail (_, payload): Promise<any> {
    return Vue.$abbd.auth.passwordRecovery(payload)
  },
  saveUserSettings ({ commit }, settings) {
    commit('SAVE_USER_SETTINGS', settings)
  },
  deleteUserSetting ({ commit }, settingID) {
    commit('DELETE_USER_SETTING', settingID)
  },
})

export default actions
