import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'
import { ColumnMeta } from '@/plugins/abbd-api/sdk/types/api/ColumnMeta'
import { SubLevelKeyMeta } from '@/plugins/abbd-api/sdk/types/api/SubLevelKeyMeta'

export interface MergeSynthese {
  timestamp: number
  columns: ColumnMeta[]
  name: string
  visible: boolean
  subLevelKey: SubLevelKeyMeta[]
}

export interface Synthese {
  timestamp: number
  columns: ColumnMeta[]
  subLevelKey: SubLevelKeyMeta[]
  description: string
}

export interface SyntheseMeta {
  syntheses: Synthese[]
  synthesesNames: string[]
  synthesysVisibles: string[]
}

export interface UriParams {
  projectId: string
}

type Args = [
  EndpointsOptions<UriParams>?
]

type ArgsPut = [
  MergeSynthese[],
  EndpointsOptions<UriParams>?
]

export default (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>(({ projectId }) => `v1/wexaus/projects/${projectId}/dashboard/synthesis/metadata`)
  const endpointPut = new Endpoint<UriParams>(({ projectId }) => `v1/wexaus/projects/${projectId}/dashboard/synthesis/updateaccesses`)

  return {
    get: (...args: Args) => endpoint.get<SyntheseMeta>(...args)
      .then<MergeSynthese[]>((res) => {
        return res.data.syntheses
          .map((synthese: any, index: number) => {
            synthese.name = res.data.synthesesNames[index]
            synthese.visible = res.data.synthesysVisibles.includes(synthese.name)
            return synthese
          })
    }),
    put: (...args: ArgsPut) => endpointPut.post<SyntheseMeta>({
      accesses: args[0].map(synthese => ({
        viewName: synthese.name,
        visibleForCustomer: synthese.visible,
      })),
    }, args[1]),
  }
}
