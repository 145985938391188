import { ref } from '@vue/composition-api'

export default function useNetworkCheck () {
  const isOnline = ref<boolean>(true)
  const checkOnlineStatus = async () => {
    try {
      const crossDomainUrl = 'https://www.runview.fr/hubfs/raw_assets/public/Runview-website/images/shapes/hero-hp-bg.svg'
      const online = await fetch(crossDomainUrl, { cache: 'no-store' })
      isOnline.value = online.status >= 200 && online.status < 300 // either true or false
    } catch (err) {
      isOnline.value = false // definitely offline
    }
  }
  return {
    isOnline,
    checkOnlineStatus,
  }
}
