





































import { defineComponent, reactive, ref } from '@vue/composition-api'
import { GridOptions, RowNode } from '@ag-grid-enterprise/all-modules'
import { FileBrowserObj } from '@@/types'
import useModal from '@/composables/pages/transferts/envoyer-des-fichiers/use-modal'
import useModalsLoadingStatus from '@/composables/pages/transferts/envoyer-des-fichiers/use-modals-loading-status'
import { headerValueGetter, objectGroupInnerRenderer } from '../../gerer-les-fichiers/helpers'

export default defineComponent({
  name: 'ModalSelectFolder',
  setup (props, ctx) {
    const { modalConfig, setModal, modalDefaultConfig, isModalOpen } = useModal({ ctx, modalName: 'ModalSelectFolder' })
    const { isLoading, submitButtonStatus, preventClosingWhileLoading } = useModalsLoadingStatus({
      ctx,
      submitBtnI18nKey: 'transferts.gerer.modals.selectFolder.okBtn',
    })

    const gridOptions = reactive<GridOptions>({
      getRowNodeId: (data: FileBrowserObj) => data.key,
      getDataPath: (data: FileBrowserObj) => {
        if (data.path.length === 1 && data.path[0] === '/') {
          return data.path
        } else {
          return ['/', ...data.path]
        }
      },
      defaultColDef: { headerValueGetter: headerValueGetter.bind(ctx.root) },
      columnDefs: [],
      autoGroupColumnDef: {
        headerName: 'transferts.gerer.columns.headerNames.name',
        cellRendererParams: { innerRenderer: objectGroupInnerRenderer },
      },
      onGridReady: (params) => {
        params.api.sizeColumnsToFit()
      },
    })
    const folders = ref<FileBrowserObj[]>([
      ...ctx.root.$store.getters['files/browser/folderList'],
      {
        key: `${ctx.root.$store.getters['files/s3Credentials'].prefix}/`,
        path: ['/'],
        type: 'folder',
        lastModified: new Date(),
        size: 0,
      },
    ])
    /**
     * Check if a Node is selectable based on selected Nodes
     * @param node
     */
    const isRowSelectable = (node: RowNode): boolean => {
      return !(node.data.path.length === 1 && node.data.path[0] === '/')
    }
    const selectedObjects = ref<RowNode[]>([])
    const onRowDataChanged = () => {
      if (gridOptions.api) {
        gridOptions.api.forEachNode((node) => {
          if (node.level === 0) {
            node.setExpanded(true)
          }
        })
      }
    }

    // /**
    //  * On form submit, select folder
    //  * @param e
    //  */
    const onSubmit = (e: Event) => {
      e.preventDefault()
      if (!selectedObjects.value[0]) return
      const destinationNode: RowNode = selectedObjects.value[0] as RowNode
      ctx.root.$accessor.files.uploader.setDestinationFolder(destinationNode.data)
      setModal({})
    }
    return {
      modalConfig,
      setModal,
      modalDefaultConfig,
      isModalOpen,
      isLoading,
      gridOptions,
      folders,
      submitButtonStatus,
      selectedObjects,
      isRowSelectable,
      onRowDataChanged,
      preventClosingWhileLoading,
      onSubmit,
    }
  },
})
