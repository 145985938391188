import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'
import { User } from '../../types/api/User'

export interface PayloadAzureAuth {
  authStrategy: 'azureToken'
  azureToken: string
}

export interface PayloadBasicLogin {
  authStrategy: 'basicLogin'
  login: string
  plainPassword: string
}

export interface UriParams {}

type Args = [
  (PayloadBasicLogin | PayloadAzureAuth),
  EndpointsOptions<UriParams>?
]

export default (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>('auth')

  return (...args: Args) => endpoint.post<User>(...args)
}
