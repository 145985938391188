
















import { defineComponent, ref } from '@vue/composition-api'
import is from 'is_js'

export default defineComponent({
  name: 'IeBanner',
  setup () {
    const ieBannerStatus = ref<boolean>(is.ie() && !sessionStorage.getItem('ieBannerDismissed'))
    const hideIeBanner = () => {
      ieBannerStatus.value = false
      sessionStorage.setItem('ieBannerDismissed', 'true')
    }

    return {
      ieBannerStatus,
      hideIeBanner,
    }
  },
})
