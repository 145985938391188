





























import { Vue, Component } from 'nuxt-property-decorator'
import { ICellEditorParams } from '@ag-grid-enterprise/all-modules'
import Cleave from 'vue-cleave-component'
import dayjs from 'dayjs'
import toObject from 'dayjs/plugin/toObject'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import { fixDateTimezone } from '@/helpers/fix-date-timezone'

dayjs.extend(utc)
dayjs.extend(toObject)
dayjs.extend(customParseFormat)

// NOTE: Component Decorator
@Component({
  name: 'CellEditorDate',
  components: {
    Cleave,
  },
})

// NOTE: Component Class
export default class CellEditorDate extends Vue {
  $refs!: {
    cleaveInput: any
  }

  // NOTE: Data
  params!: ICellEditorParams
  value: string = ''
  originValue: any
  format: string = ''
  cleaveOptions: any = {
    date: true,
    delimiter: '/',
    datePattern: '',
  }

  // NOTE: Methods
  isPopup () {
    return true
  }

  getValue () {
    if (!this.value) return null

    let returnedValue

    if (this.params.value) {
      const originDate = dayjs(fixDateTimezone(this.params.value)).toObject()

      returnedValue = dayjs(this.value, this.format)
        .set('hour', originDate.hours)
        .set('minute', originDate.minutes)
        .set('second', originDate.seconds)
        .set('millisecond', originDate.milliseconds)
    } else {
      returnedValue = dayjs(this.value, this.format)
    }

    return returnedValue.utc()
      .format('YYYY-MM-DDTHH:mm:ss.sssZ')
      .replace(/(\d{2}):(\d{2})$/, '$1$2')
  }

  isCancelAfterEnd () {
    const isSameValue = this.originValue === this.value
    const isValidDateFormat = this.value ? /\d{2}\/\d{2}\/\d{4}/.test(this.value) : true

    return isSameValue || !isValidDateFormat
  }

  mounted () {
    this.format = this.$t('common.dateFormat') as string
    this.cleaveOptions.datePattern = this.$t('common.dateInputFormat')

    this.originValue = this.params.value ? dayjs(new Date(fixDateTimezone(this.params.value))).format(this.format) : ''
    this.value = this.params.value ? dayjs(new Date(fixDateTimezone(this.params.value))).format(this.format) : ''

    Vue.nextTick(() => {
      if (this.$refs.cleaveInput) {
        this.$refs.cleaveInput.$el.focus()
      }
    })
  }
}
