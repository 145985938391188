import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'

export interface Payload {
  [key: string]: any
}

export interface UriParams {
  projectId: string
}

type Args = [
  Payload,
  EndpointsOptions<UriParams>?
]

export default (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>(({ projectId }) => `/v1/wexaus/projects/${projectId}/dashboard/mouvements/export`)

  return (...args: Args) => endpoint.post(...args)
}
