import { UploadQueuedFileList } from '~~/types'

export interface FileUploaderState {
  uploadQueue: UploadQueuedFileList
  destinationFolder: any
  modalConfig: any
}

const fileState = (): FileUploaderState => ({
  uploadQueue: {},
  destinationFolder: '',
  modalConfig: {
    name: '',
    data: {},
  },
})
export default fileState
