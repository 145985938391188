import Vue from 'vue';

import {
  ModalPlugin,
  LayoutPlugin,
  CardPlugin,
  FormPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormCheckboxPlugin,
  ButtonPlugin,
  TooltipPlugin,
  PopoverPlugin,
  CollapsePlugin,
  ListGroupPlugin,
  ButtonGroupPlugin,
  InputGroupPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  BadgePlugin,
  DropdownPlugin,
  TabsPlugin,
  IconsPlugin
} from 'bootstrap-vue';

Vue.use(ModalPlugin);
Vue.use(LayoutPlugin);
Vue.use(CardPlugin);
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(ButtonPlugin);
Vue.use(TooltipPlugin);
Vue.use(PopoverPlugin);
Vue.use(CollapsePlugin);
Vue.use(ListGroupPlugin);
Vue.use(ButtonGroupPlugin);
Vue.use(InputGroupPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(BadgePlugin);
Vue.use(DropdownPlugin);
Vue.use(TabsPlugin);
Vue.use(IconsPlugin);
