





















































































import { defineComponent, computed, ref } from '@vue/composition-api'
import useSidebarMenu from '@/composables/use-sidebar-menu'

export default defineComponent({
  name: 'AbbdSidebarMenu',
  setup (_props, ctx) {
    const { isAdminPage, sidebarMenu, toggleMenuGroup, isChildrenActive } = useSidebarMenu({ ctx })
    const projects = computed(() => ctx.root.$accessor.projects.projects)
    const selectedProject = computed(() => ctx.root.$accessor.projects.selectedProject)
    const user = computed(() => ctx.root.$accessor.user.user)
    const showUpdateBtn = ref(false)
    const fetchHTML = async (url: any) => {
      const response = await fetch(url)
      if (!response || !response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`)
      }
      return await response.text()
    }

    const calculateHash = async (data: any) => {
      const buffer = new TextEncoder().encode(data)
      const hashBuffer = await crypto.subtle.digest('SHA-256', buffer)
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('')
      return hashHex
    }

    const compareHTML = async () => {
      const htmlContent1 = await fetchHTML('/index.html')
      const hash1 = await calculateHash(htmlContent1)
      const hash2 = window.localStorage.getItem('indexHash')
      if (hash1 === hash2) {
        // Les fichiers HTML ont le même hash. Ils sont considérés comme identiques => même version
      } else {
        // Les fichiers HTML ont des hash différents. Ils sont considérés comme différents => nouvelle version
        window.localStorage.setItem('indexHash', hash1)
        showUpdateBtn.value = true
      }
    }
    const updateVersion = () => {
      window.location.reload()
      showUpdateBtn.value = false
    }
    return {
      projects,
      selectedProject,
      user,
      isAdminPage,
      sidebarMenu,
      toggleMenuGroup,
      isChildrenActive,
      compareHTML,
      showUpdateBtn,
      updateVersion,
    }
  },
})
