import { RequestInterceptor } from '../types/'

/**
 * Success interceptor: Prevent IE to cache get queries
 * @param config
 */
export const ieFixIntercpetor: RequestInterceptor = () => (config) => {
  config.params = config.params || {}
  config.params.ts = new Date().getTime()
  return config
}
