import { time } from 'vue-analytics'
import { ResponseInterceptor } from '../types/'

/**
 * Success interceptor: Get response time and send it to GA
 * @param result
 */
export const analyticsInterceptor: ResponseInterceptor = () => (result) => {
  if (result && result.config && result.config.params && result.config.params.ts) {
    const requestStarts = result.config.params.ts
    const requestEnd = new Date().getTime()

    time({
      timingCategory: 'api',
      timingVar: result.config.url,
      timingValue: requestEnd - requestStarts,
    })
  }

  return result
}
