import Vue from 'vue'
import { actionTree } from 'typed-vuex'
import state from './state'
import mutations from './mutations'
import getters from './getters'

const actions = actionTree({ state, mutations, getters }, {
  getProjects ({ commit }): Promise<any> {
    return Vue.$abbd.projects.all()
      .then((result) => {
        commit('UPDATE_PROJECTS', result.data)
        return result
      })
  },
  selectProject ({ commit }, projectId): Promise<any> {
    return Vue.$abbd.projects.byId({ uriParams: { projectId } })
      .then((result) => {
        if (result.data?.optionalFeatures) {
          commit('UPDATE_SELECTED_PROJECT', { projectId, optionalFeatures: result.data?.optionalFeatures })
        }
      })
      .catch((error) => {
        return error
      })
  },
})

export default actions
