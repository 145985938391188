import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'

export interface UriParams {}

type Args = [
  EndpointsOptions<UriParams>?
]

export default (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>('auth/refresh')

  return {
    ...endpoint,
    get: (...args: Args) => endpoint.get(...args),
  }
}
