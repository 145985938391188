import { EndpointClass, EndpointsOptions } from 'axios-endpoints/dist/es/types'

export interface SynthesisData {
  values: { [key: string]: any }[]
}

export interface UriParams {
  projectId: string
  synthesisName: string
}

type Args = [
  EndpointsOptions<UriParams>?
]

export default (Endpoint: typeof EndpointClass) => {
  const endpoint = new Endpoint<UriParams>(({ projectId, synthesisName }) => `v1/wexaus/projects/${projectId}/dashboard/synthesis/data/${synthesisName}`)

  return (...args: Args) => endpoint.get<SynthesisData>(...args)
}
