import Vue from 'vue'
import { Context, NuxtAppOptions } from '@nuxt/types'
import VueCompositionApi from '@vue/composition-api'

Vue.use(VueCompositionApi)

let nuxtApp: NuxtAppOptions

export default ({ app }: Context) => {
  nuxtApp = app
}

export const useNuxtApp = () => nuxtApp
