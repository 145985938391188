const req = require.context('../', true, /filestore\.json$/)

export default req.keys()
  .map((key) => {
    const projectId = key.replace(/^\.\//, '').split('/')[0]

    const res = req(key)
    res.projectId = projectId

    return res
  })
