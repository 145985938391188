




















import { Vue, Component } from 'nuxt-property-decorator'
import { ICellEditorParams } from '@ag-grid-enterprise/all-modules'
import Cleave from 'vue-cleave-component'

// NOTE: Component Decorator
@Component({
  name: 'CellEditorMask',
  components: {
    Cleave,
  },
})

// NOTE: Component Class
export default class CellEditorMask extends Vue {
  params!: ICellEditorParams
  originValue: any
  value: number | string | null = null

  $refs!: {
    cleaveInput: any
  }

  isPopup () {
    return false
  }

  isCancelAfterEnd () {
    return this.originValue === this.value
  }

  getValue () {
    if (this.params.colDef.type === 'PERCENT') {
      return this.value ? Number(this.value) / 100 : null
    } else {
      return this.value ? Number(this.value) : null
    }
  }

  mounted () {
    if (this.params.colDef.type === 'PERCENT') {
      this.originValue = this.params.value * 100
      this.value = this.params.value * 100
    } else {
      this.originValue = this.params.value
      this.value = this.params.value
    }

    Vue.nextTick(() => {
      this.$refs.cleaveInput.$el.focus()
    })
  }
}
