import Vue, { VueConstructor } from 'vue'
import VueFormulate from '@braid/vue-formulate'
import FormulateDynSelect from '@/components/common/vue-formulate/FormulateDynSelect.vue'
import FormulateMask from '@/components/common/vue-formulate/FormulateMask.vue'
import FormulateMaskMultipleEdit from '@/components/common/vue-formulate/FormulateMaskMultipleEdit.vue'
import FormulateDate from '@/components/common/vue-formulate/FormulateDate.vue'
import FormulateFile from '@/components/common/vue-formulate/FormulateFile.vue'

Vue.component('FormulateDynSelect', FormulateDynSelect as VueConstructor<Vue>)
Vue.component('FormulateMask', FormulateMask as VueConstructor<Vue>)
Vue.component('FormulateMaskMultipleEdit', FormulateMaskMultipleEdit as VueConstructor<Vue>)
Vue.component('FormulateDate', FormulateDate as VueConstructor<Vue>)
Vue.component('FormulateFile', FormulateFile as VueConstructor<Vue>)

Vue.use(VueFormulate, {
  library: {
    dynSelect: {
      classification: 'select',
      component: 'FormulateDynSelect',
    },
    mask: {
      classification: 'text',
      component: 'FormulateMask',
    },
    maskMultipleEdit: {
      classification: 'text',
      component: 'FormulateMaskMultipleEdit',
    },
    date: {
      classification: 'date',
      component: 'FormulateDate',
    },
    fileDashboard: {
      classification: 'file',
      component: 'FormulateFile',
    },
  },
})
