





































































































import { defineComponent, computed, ref } from '@vue/composition-api'
import hotkeys from 'hotkeys-js'
import { Project } from '@/plugins/abbd-api/sdk/endpoints/v1/wexaus/projects'
import { User } from '@/plugins/abbd-api/sdk/types/api/User'
import useAuth from '@/composables/use-auth'

import ProjectSelectionModal from '@/components/common/ProjectSelectionModal.vue'

export default defineComponent({
  name: 'AbbdSidebarHeader',
  components: {
    ProjectSelectionModal,
  },
  setup (props, ctx) {
    const { signout } = useAuth({ route: ctx.root.$route })

    const user = computed<User>(() => ctx.root.$store.getters['user/user'])
    const isAdmin = computed<boolean>(() => ctx.root.$store.getters['user/isAdmin'])
    const userInitials = computed<string>(() => ctx.root.$store.getters['user/userInitials'])
    const projects = computed<Project[]>(() => ctx.root.$store.getters['projects/projects'])
    const isAdminPage = computed<boolean>(() => ctx.root.$route.path.includes('/admin'))

    const langFlags = ref({
      fr: { flag: 'fr', label: 'Français', lang: 'fr' },
      en: { flag: 'us', label: 'English', lang: 'en' },
    })

    const switchLang = (lang: string) => {
      ctx.root.$router.push(ctx.root.switchLocalePath(lang))
    }
    const deconnexion = computed(() => {
      const date = new Date(user.value.lastLogin)
      return ctx.root.$t('common.sidebar.lastLogin', { date: date.toLocaleDateString(), time: date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0') })
    })

    hotkeys('ctrl+shift+p', (e: Event) => {
      e.preventDefault()
      ctx.root.$bvModal.show('modal-project-selection')
    })

    return {
      isAdmin,
      isAdminPage,
      projects,
      signout,
      user,
      userInitials,
      langFlags,
      switchLang,
      deconnexion,
    }
  },
})
