import { Context, Middleware } from '@nuxt/types'

const requireDashboard: Middleware = ({ store, redirect, app }: Context) => {
  const selectedProject = store.getters['projects/selectedProject']

  if (!selectedProject || !selectedProject.optionalFeatures.includes('dashboard')) {
    return redirect(app.localePath({ name: 'transfert-envoyer-des-fichiers' }))
  }
}

export default requireDashboard
